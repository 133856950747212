import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Button, Col, Divider, Input, message, Modal, Row, Select } from 'antd';
import Filters from '../Filters/Filters';
import { useDispatch, useSelector } from 'react-redux';
import { createAlert, deleteAlert, updateAlert } from '../../features/segments/segmentsSlice';

export default function AlertModal({ visible, onCancel, alert }) {
  const dispatch = useDispatch();
  const [alertName, setAlertName] = useState();
  const [alertField, setAlertField] = useState();
  const [localFilters, setLocalFilters] = useState([]);
  const fieldNames = useSelector(state => state.companies.schema);

  useEffect(() => {
    if (alert.filters) {
      setLocalFilters(alert.filters);
    }
    setAlertField(alert.alertField);
    setAlertName(alert.name);
  }, [alert]);

  const onSaveAlert = () => {
    const newAlert = {
      ...alert,
      name: alertName,
      alertField,
      filters: localFilters
    };

    if (alert.id) {
      if (!_.isEqual(alert, newAlert)) {
        dispatch(updateAlert(newAlert));
      }
    } else {
      dispatch(createAlert(newAlert));
    }

    message.success(`Alert has been saved succesfully!`);

    onCancel();
  };

  const onDeleteAlert = () => {
    dispatch(deleteAlert(alert));
    message.info(`Alert has been deleted succesfully!`);
    onCancel();
  };

  return (
    <Modal
      visible={visible}
      title={`${alert.id ? 'Update' : 'Create'}  Alert`}
      okText="Save"
      onOk={() => onSaveAlert()}
      onCancel={() => onCancel()}
      width={900}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Select
            placeholder="Select Field Name to Alert On"
            style={{ width: '100%' }}
            value={fieldNames.find(fieldName => fieldName.id === alertField)?.name}
            onSelect={val => setAlertField(fieldNames.find(fieldName => fieldName.name === val).id)}
          >
            {fieldNames.map(fieldName => (
              <Select.Option key={fieldName.id} value={fieldName.name}>
                {fieldName.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={12}>
          <Input
            value={alertName}
            placeholder="Type Alert Name..."
            onChange={e => {
              setAlertName(e.target.value);
            }}
          />
        </Col>
      </Row>

      <Divider orientation="left" plain>
        Filters
      </Divider>
      <Filters filters={localFilters} setFilters={setLocalFilters} />
      {alert.id && (
        <>
          <Divider />
          <Row align="middle" justify="center">
            <Button danger style={{ alignItems: 'center' }} onClick={() => onDeleteAlert()}>
              Delete
            </Button>
          </Row>
        </>
      )}
    </Modal>
  );
}
