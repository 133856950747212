import React from 'react';
import AppSiteWrapper from '../components/SiteBasics/AppSiteWrapper';
import { Card, Row, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { updateConnectorAPI } from '../features/connectors/connectorsSlice';

import WebhookCard from '../components/Connectors/Webhook/WebhookCard';

export default function WebhookConnector() {
  const {
    webhook: { accountEnabled, contactEnabled, alertsEnabled, accountWebhookUrl, contactWebhookUrl, alertsWebhookUrl }
  } = useSelector(state => state.connectors);
  const dispatch = useDispatch();

  const onEnableChange = (change, type) => {
    dispatch(updateConnectorAPI('webhook', { [`${type}Enabled`]: change, enabled: true }));
    if (change) {
      message.success('Hurray! Webhook connector has been enabled!');
    }
  };

  const saveWebhookURL = (url, type) => {
    if (isValidUrl(url)) {
      dispatch(updateConnectorAPI('webhook', { [`${type}WebhookUrl`]: url }));
      message.success('The URL has been saved!');
    } else {
      message.error('It looks like the url provided is invalid.');
    }
  };

  const isValidUrl = url => {
    const urlRegex = /https?:\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/i;
    return urlRegex.test(url);
  };

  return (
    <AppSiteWrapper>
      <Card title="Webhooks Settings">
        <Row gutter={[32, 32]}>
          <WebhookCard
            type="account"
            url={accountWebhookUrl}
            enabled={accountEnabled}
            setEnabled={onEnableChange}
            saveWebhook={saveWebhookURL}
          />
          <WebhookCard
            type="contact"
            url={contactWebhookUrl}
            enabled={contactEnabled}
            setEnabled={onEnableChange}
            saveWebhook={saveWebhookURL}
          />
          <WebhookCard
            type="alerts"
            url={alertsWebhookUrl}
            enabled={alertsEnabled}
            setEnabled={onEnableChange}
            saveWebhook={saveWebhookURL}
          />
          {/* <Divider />
          <Typography.Title level={4}>Test the webhook</Typography.Title>
          <Col span={24}>
            <Row justify="center">
              <AceEditor
                value={JSON.stringify(
                  {
                    type: "alertt",
                    source: "goodfit",
                    body: {
                      name: "Test Alert",
                      fieldName: "traffic",
                      changeType: "increased",
                      change: 0.5,
                      oldValue: 100000,
                      newValue: 150000,
                    },
                  },
                  null,
                  "\t"
                )}
                theme="kuroir"
                width="75%"
              />
              <Col span={24}>
                <Row justify="center" style={{ marginTop: 16 }}>
                  <Button type="primary" onClick={sendTestWebhook}>
                    Send test Payload
                  </Button>
                </Row>
              </Col>
            </Row>
          </Col> */}
        </Row>
      </Card>
    </AppSiteWrapper>
  );
}
