export const DEFAULT_CONTACTS_FIELDS = [
  {
    externalName: 'first_name',
    internalName: 'first_name',
    dataBlockField: {
      type: 'String',
      salesforceType: 'string',
      hubspotType: 'string'
    },
    presentInCCM: true
  },
  {
    externalName: 'last_name',
    internalName: 'last_name',
    dataBlockField: {
      type: 'String',
      salesforceType: 'string',
      hubspotType: 'string'
    },
    presentInCCM: true
  },
  {
    externalName: 'email',
    internalName: 'email',
    dataBlockField: {
      type: 'String',
      salesforceType: 'string',
      hubspotType: 'string'
    },
    presentInCCM: true
  },
  {
    externalName: 'linkedin',
    internalName: 'linkedin',
    dataBlockField: {
      type: 'String',
      salesforceType: 'string',
      hubspotType: 'string'
    },
    presentInCCM: true
  },
  {
    externalName: 'country',
    internalName: 'country',
    dataBlockField: {
      type: 'Picklist',
      salesforceType: 'picklist',
      hubspotType: 'picklist'
    },
    presentInCCM: true
  },
  {
    externalName: 'title',
    internalName: 'title',
    dataBlockField: {
      type: 'String',
      salesforceType: 'string',
      hubspotType: 'string'
    },
    presentInCCM: true
  },
  {
    externalName: 'is_former_employee',
    internalName: 'is_former_employee',
    dataBlockField: {
      type: 'Boolean',
      salesforceType: 'boolean',
      hubspotType: 'boolean'
    },
    presentInCCM: true
  },
  {
    externalName: 'email_status',
    internalName: 'email_status',
    dataBlockField: {
      type: 'Picklist',
      salesforceType: 'picklist',
      hubspotType: 'picklist'
    },
    presentInCCM: true
  },
  {
    externalName: 'tenure',
    internalName: 'tenure',
    dataBlockField: {
      type: 'Number',
      salesforceType: 'integer',
      hubspotType: 'number'
    },
    presentInCCM: true
  },
  {
    externalName: 'location',
    internalName: 'location',
    dataBlockField: {
      type: 'String',
      salesforceType: 'string',
      hubspotType: 'string'
    },
    presentInCCM: true
  }
];
