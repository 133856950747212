import React from 'react';
import { Select, Col } from 'antd';

export default function OperatorSelector({ value, setValue, options }) {
  return (
    <Col>
      <Select
        showSearch
        value={value}
        placeholder="Select Operator"
        style={{ minWidth: '150px' }}
        onSelect={val => setValue(val)}
        size="small"
      >
        {options?.map(option => (
          <Select.Option value={option.name}>{option.name}</Select.Option>
        ))}
      </Select>
    </Col>
  );
};
