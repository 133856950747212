import React, { Fragment, useEffect } from "react";
import { Layout } from "antd";
import AppNavBar from "../NavBar/AppNavBar";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanies, fetchSchema } from "../../features/companies/companiesSlice";
import { fetchConnectors } from "../../features/connectors/connectorsSlice";
import { fetchAlerts, fetchSegments } from "../../features/segments/segmentsSlice";
import { loadUsers } from "../../features/users/usersSlice";

export default function AppSiteWrapper({ title, children }) {
  const {
    companies: { isFetched: companiesIsFetched },
    connectors: { isFetched: connectorsIsFetched },
    segments: { isFetched: segmentsIsFetched },
    users: { isFetched: usersIsFetched }
  } = useSelector(state => state);
  const dispatch = useDispatch();

  useEffect(() => {
    window.document.title = `Goodfit: ${title}`
  }, [title]);

  useEffect(() => {
    if (!companiesIsFetched) {
      dispatch(fetchCompanies());
      dispatch(fetchSchema());
    }
  }, [companiesIsFetched, dispatch]);

  useEffect(() => {
    if (!connectorsIsFetched) {
      dispatch(fetchConnectors());
    }
  }, [connectorsIsFetched, dispatch]);

  useEffect(() => {
    if (!segmentsIsFetched) {
      dispatch(fetchSegments());
      dispatch(fetchAlerts());
    }
  }, [segmentsIsFetched, dispatch]);

  useEffect(() => {
    if (!usersIsFetched) {
      dispatch(loadUsers());
    }
  }, [usersIsFetched, dispatch]);

  return (
    <Fragment>
      <AppNavBar title={title} />
      <Layout.Content
        style={{
          maxWidth: "1200px",
          paddingLeft: "0.75rem",
          paddingRight: "0.75rem",
          marginLeft: "auto",
          marginRight: "auto",
          width: "100%",
          marginTop: 32
        }}
      >
        {children}
      </Layout.Content>
    </Fragment>
  );
}
