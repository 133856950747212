import { JoiPasswordComplexity } from 'joi-password';

export const validateNewPassword = (rule, value) => {
    const passwordComplexity = JoiPasswordComplexity.string()
      .min(8)
      .minOfNumeric(1)
      .minOfSpecialCharacters(1)
      .minOfUppercase(1)
      .minOfLowercase(1)
      .required();
    const { error } = passwordComplexity.validate(value);

    return error ? Promise.reject(new Error(error.details[0].message)) : Promise.resolve();
  };