import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Col, Button, Select, Input, DatePicker } from 'antd';
import { DotChartOutlined } from '@ant-design/icons';

import { FieldMultiSelect } from './FieldMultiSelect';
import { RULE_OPERATORS, RULE_ALLOWED_TYPES, getOperatorsForType } from './operators';

export function ScoringRulePredicate({ rule, mutateRules, onFieldStatsClicked }: any) {
  const schema = useSelector(state => (state as any).companies.schema);

  const fieldGroups: Record<string, any[]> = {
    boolean: [],
    number: [],
    string: [],
    date: [],
    array: []
  };
  for (const field of schema) {
    if (field.id === 'goodfit_score') continue;
    if (RULE_ALLOWED_TYPES.includes(field.type)) {
      let fieldType = field.type;
      // These are special list types we cant filter here, as we can only use list of strings
      if (field.id === 'contacts') continue;
      // We group these under 'number fields'
      if (fieldType === 'percentage' || fieldType === 'currency') fieldType = 'number';
      fieldGroups[fieldType].push(field);
    }
  }

  const currentField = schema.find((f: any) => f.id === rule.fieldName);
  const operators = getOperatorsForType(currentField?.type);
  const currentOperator = RULE_OPERATORS.find((op: any) => op.id === rule.operator);

  const onFieldChange = (fieldName: string) => {
    const newField = schema.find((f: any) => f.id === fieldName);
    const operators = getOperatorsForType(newField?.type);
    mutateRules(rule.id, {
      ...rule,
      fieldName,
      fieldType: newField?.type,
      operator: operators[0].id,
      value: null,
      value2: null
    });
  };

  const onOperatorChange = (operatorId: string) => {
    const newOperator = RULE_OPERATORS.find((op: any) => op.id === operatorId);
    let newValue = newOperator?.hasValue ? rule.value : null;
    if (newOperator?.hasArrayValue && !_.isArray(newValue)) {
      newValue = null;
    }
    if (!newOperator?.hasArrayValue && _.isArray(newValue)) {
      newValue = null;
    }

    mutateRules(rule.id, {
      ...rule,
      operator: operatorId,
      value: newValue,
      value2: null
    });
  };

  const onDateValueChange = (crap: any, newDate: string) => {
    mutateRules(rule.id, {
      ...rule,
      value: `${newDate}`
    });
  };

  const onValueChange = (value: any) => {
    mutateRules(rule.id, {
      ...rule,
      value
    });
  };

  const onValue2Change = (event: any) => {
    mutateRules(rule.id, {
      ...rule,
      value2: event.target.value
    });
  };

  const showOptions = !!rule.fieldName && operators.length > 0;
  const showValue = showOptions && currentOperator?.hasValue;

  let inputType = 'text';
  const currentFieldType = currentField?.type;
  if (currentField?.type === 'number') inputType = 'number';
  if (currentField?.type === 'currency') inputType = 'number';
  if (currentField?.type === 'percentage') inputType = 'number';
  if (currentOperator?.id === 'listLengthGte') inputType = 'number';
  if (currentField?.type === 'listLengthLt') inputType = 'number';
  if (currentField?.type === 'date') inputType = 'date';
  if (currentOperator?.id === 'stringEquals') inputType = 'picker';
  if (currentOperator?.id === 'stringNotEquals') inputType = 'picker';
  if (currentOperator?.id === 'listContains') inputType = 'picker';
  if (currentOperator?.id === 'listNotContains') inputType = 'picker';

  return (
    <>
      <Col style={{ marginRight: 4 }}>
        <Select
          placeholder="Select field"
          showSearch={true}
          style={{ minWidth: 180 }}
          onChange={onFieldChange}
          value={rule.fieldName}
          listHeight={300}
          dropdownMatchSelectWidth={false}
          dropdownStyle={{ minWidth: 300 }}
        >
          {fieldGroups['string'].length && (
            <Select.OptGroup key="string" label="Text fields">
              {fieldGroups['string'].map(field => (
                <Select.Option key={field.id}>{field.name}</Select.Option>
              ))}
            </Select.OptGroup>
          )}
          {fieldGroups['array'].length && (
            <Select.OptGroup key="array" label="List fields">
              {fieldGroups['array'].map(field => (
                <Select.Option key={field.id}>{field.name}</Select.Option>
              ))}
            </Select.OptGroup>
          )}
          {fieldGroups['number'].length && (
            <Select.OptGroup key="number" label="Number fields">
              {fieldGroups['number'].map(field => (
                <Select.Option key={field.id}>{field.name}</Select.Option>
              ))}
            </Select.OptGroup>
          )}
          {fieldGroups['boolean'].length && (
            <Select.OptGroup key="boolean" label="Yes/No fields">
              {fieldGroups['boolean'].map(field => (
                <Select.Option key={field.id}>{field.name}</Select.Option>
              ))}
            </Select.OptGroup>
          )}
          {fieldGroups['date'].length && (
            <Select.OptGroup key="date" label="Date/Time fields">
              {fieldGroups['date'].map(field => (
                <Select.Option key={field.id}>{field.name}</Select.Option>
              ))}
            </Select.OptGroup>
          )}
        </Select>
      </Col>
      <Col style={{ marginRight: 4 }}>
        <Button
          disabled={!currentField}
          onClick={() => onFieldStatsClicked(currentField.id)}
          style={{ paddingLeft: 8, paddingRight: 8 }}
        >
          <DotChartOutlined />
        </Button>
      </Col>

      <Col style={{ marginRight: 4 }}>
        {showOptions && (
          <Select
            placeholder="Select operator"
            showSearch={false}
            style={{ minWidth: 150 }}
            value={rule.operator}
            onChange={onOperatorChange}
          >
            {operators.map((op: any) => (
              <Select.Option key={op.id}>{op.label}</Select.Option>
            ))}
          </Select>
        )}
      </Col>

      <Col flex="auto" style={{ width: '8%' }}>
        {showOptions && showValue && currentFieldType !== 'date' && inputType !== 'picker' && (
          <Input
            placeholder="Value"
            type={inputType}
            onChange={ev => onValueChange(ev.target.value)}
            value={rule?.value}
          />
        )}

        {showOptions && showValue && currentFieldType === 'date' && (
          <DatePicker onChange={onDateValueChange} value={rule?.value ? dayjs(rule?.value) : null} />
        )}

        {showOptions && showValue && inputType === 'picker' && (
          <FieldMultiSelect
            style={{ width: '100%' }}
            onChange={onValueChange}
            value={rule?.value}
            fieldName={rule.fieldName}
          />
        )}
      </Col>
      {showOptions && showValue && currentOperator.id === 'numberBetween' && (
        <>
          <Col flex="auto" style={{ width: '1%', margin: 4, textAlign: 'center' }}>
            and
          </Col>
          <Col flex="auto" style={{ width: '8%', marginLeft: 4 }}>
            <Input placeholder="Value To" type={inputType} onChange={onValue2Change} value={rule?.value2} />
          </Col>
        </>
      )}
    </>
  );
}
