import React, { useState, useEffect, useMemo } from 'react';
import { Table, Tag, Avatar, Typography, Button, Tooltip, Badge } from 'antd';
import { useDispatch } from 'react-redux';
import { TeamOutlined } from '@ant-design/icons';
import ContactsModal from './ContactsModal';
import { updateSorting, fetchCompanies } from '../../features/companies/companiesSlice';
import { API } from 'aws-amplify';
import ArrayCell from './ArrayCell';
import _ from 'lodash';


function renderStringValue(value) {
  const str= value.substring(0, 50);
  if (value.startsWith('https://')||value.startsWith('http://')) {
    return (<a href={value} target="_blank" rel="noreferrer" className="clickable-table-link">{str}</a>);
  }
  return str;
}

export default function TableWrapper(
  {schema, entities, loading=false}
) {
  const dispatch = useDispatch();

  /**
   * Hack! When using antd table and scroll:x and sticky, you occastionally get an error "ResizeObserver loop limit exceeded"
   * Open issue suggests ignoring it like below https://github.com/ant-design/ant-design/issues/23246
   * This only affects the dev env, as its just hiding the webpack warning
   */
  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message.includes('ResizeObserver') || e.message === 'Script error.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        )
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        )
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    })
  }, []);
  ///

  const [showContactModal, setShowContactModal] = useState(false);
  const [currentContacts, setCurrentContacts] = useState([]);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [isInnerLoading, setIsInnerLoading] = useState(true); // This is used to prevent a flash of 'no companies' before display
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [timeline, setTimeline] = useState(null);

  useEffect(() => {
    if (schema && !loading) {
      const columns = parseSchemaToColumns(schema);
      setColumns(columns);
      setData(entities.map(company => {
        return { ...company, key: company.domain, timeline: [] };
      }));
    }
    setIsInnerLoading(loading);
  }, [schema, entities, loading]);

  const tableChangeHandler=(pagination, filters, sorter) => {
    const columnSchema = schema.find(s=>s.id===sorter.field);
    if (columnSchema && sorter.order) {
      // If its a text type, we want to use the 'keyword' field for sorting to avoid error
      const isTextType = ['string', 'array'].includes(columnSchema.type);
      const sortBy = isTextType ? sorter.field+'.keyword': sorter.field;
      dispatch(updateSorting({
        sortBy,
        sortDirection: sorter.order==='ascend' ? 'asc' : 'desc',
      }));  
    } else {
      // Will revert to QualityScore
      dispatch(updateSorting({
        sortBy: null,
        sortDirection: null,
      }));
  
    }
    dispatch(fetchCompanies());
  }

  const getRecordTimelines = async record => {
    const { records } = await API.get('companies', `/changes/${record.domain}`);
    setTimeline(records);
  };

  const moveColumnToEnd = (columns, colName) => {
    const col = columns.find(c=>c.id===colName);
    if (col) {
      columns = [...columns.filter(c=>c.id!==colName), col];
    }
    return columns;
  }

  const parseSchemaToColumns = schema => {
    const columns = [];

    // Ensure contacts at the end
    const filteredSchema = moveColumnToEnd(schema.filter(field => field.selected), 'contacts');

    for (const property of filteredSchema) {
      const columnSettings = {
        title: property.name,
        dataIndex: property.id,
        key: property.id,
        align: 'center',
        className: 'fixed-size-column',
        ellipsis: true,
        render: value => {
          if (value) {
            return (
              <Tooltip title={value}>
                <Typography.Text ellipsis>{renderStringValue(value)}</Typography.Text>
              </Tooltip>
            );
          } else {
            return '–';
          }
        }
      };

      if (property.type === 'array') {
        columnSettings.render = values => {
          if (values) {
            return <ArrayCell values={values} />;
          } else {
            return '–';
          }
        };
      }

      if (property.type === 'number') {
        columnSettings.render = value => {
          return !_.isNil(value) ? value.toLocaleString('en-US') : '-';
        };
      }

      if (property.type === 'date') {
        columnSettings.render = value => {
          if (value) {
            return new Date(value).toLocaleString([], {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            });
          } else {
            return '–';
          }
        };
      }

      if (property.type === 'boolean') {
        columnSettings.render = value => {
          if (!_.isNil(value)) {
            if (value) {
              return <Tag color="#87d068">True</Tag>;
            } else {
              return <Tag color="#f50">False</Tag>;
            }
          } else {
            return '-';
          }
        };
      }

      if (property.id === 'domain') {
        columnSettings.render = (text, record) => {
          const href = `http://${text}/`; // Hopefully http redirects to https if they have it
          return (
            <>
              <a href={href} target="_blank" rel="noreferrer" className="clickable-table-link">
                <Avatar src={`https://logo.clearbit.com/${text}`} />
                <Typography.Text style={{ marginLeft: 8 }}>
                  {text}
                </Typography.Text>
              </a>
            </>
          );
        };
        columnSettings.fixed = true;
        columnSettings.align = 'left';
      }

      if (property.id === 'contacts') {
        columnSettings.className = null;
        columnSettings.title = '';
        columnSettings.fixed = 'right';
        columnSettings.render = (contacts, record) => {
          const numContacts = contacts?.length||0;
          return (
            <>
            <Button
              type="secondary"
              disabled={numContacts===0}
              ghost={numContacts===0}
              style={{color: numContacts===0 ? '#cccccc' : '', padding: '2px 3px'}}
              icon={<TeamOutlined />}
              onClick={() => {
                setCurrentContacts(contacts);
                setShowContactModal(true);
              }}
            > 
              <Tag style={{margin:0}}>{numContacts}</Tag>
            </Button>
            </>
          );
        };
      }

      if (property.type === 'currency') {
        columnSettings.render = value => {
          return !_.isNil(value) ? value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-';
        };
      }

      if (property.type === 'percentage') {
        columnSettings.render = value => {
          return !_.isNil(value) ? value.toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 2 }) : '-';
        };
      }

      columnSettings.sorter = true;
      columns.push(columnSettings);
    }
    return columns;
  };

  const memoTable = useMemo(
    () => (
      <Table
        size="small"
        columns={columns}
        sortDirections={['descend','ascend']}
        /* Timeline temporarily disabled
          expandable={{
          expandedRowRender: record => {
            return <TimelinePanel events={timeline}></TimelinePanel>;
          },
          onExpand: (expanded, record) => {
            if (expanded) {
              setExpandedRowKeys([record.key]);
              setTimeline(null);
              getRecordTimelines(record);
            } else {
              setExpandedRowKeys([]);
            }
          },
          expandedRowKeys
        }}*/
        dataSource={data}
        bordered
        sticky={true}
        locale={{emptyText: isInnerLoading ? ' ' : 'No companies'}}
        loading={isInnerLoading}
        pagination={false}
        scroll={{ x: true }}
        rowClassName="table-row-size"
        onChange={tableChangeHandler}
      />
    ),
    [data, isInnerLoading, /*expandedRowKeys, timeline*/]
  );

  return (
    <>
      {memoTable}
      <ContactsModal
        contacts={currentContacts}
        show={showContactModal}
        onCancel={() => setShowContactModal(false)}
        schema={schema}
      />
    </>
  );
}
