import { BankOutlined, UserOutlined } from '@ant-design/icons';
import { Row, Table, Tag, Typography } from 'antd';
import dayjs from 'dayjs';

import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import SettingsWrapper from '../components/SettingsWrapper';

export default function ConnectorLogs() {
  const [connectorSyncLogs, setConnectorSyncLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getConnectorSyncLogs();
  }, [setConnectorSyncLogs]);

  const getConnectorSyncLogs = async (currentPage = 1, limit = 10) => {
    setIsLoading(true);
    setConnectorSyncLogs(await API.get('connectors', `/sync-logs?page=${currentPage}&limit=${limit}`));
    setIsLoading(false);
  };

  const handleOnTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'paginate') getConnectorSyncLogs(pagination.current, pagination.pageSize);
  };

  return (
    <SettingsWrapper title="connector logs">
      <Table
        size="small"
        onChange={handleOnTableChange}
        pagination={{ total: 200 }}
        dataSource={connectorSyncLogs}
        loading={isLoading}
        columns={[
          {
            title: 'Date',
            dataIndex: 'createdAt',
            render: text => (
              <>
                <Row>
                  <Typography.Text style={{ fontSize: 12 }}> {dayjs(text).format('YYYY-MM-DD')} </Typography.Text>
                </Row>
                <Row>
                  <Typography.Text style={{ fontSize: 12 }}>{dayjs(text).format('HH:mm:ss')}</Typography.Text>
                </Row>
              </>
            )
          },
          {
            title: 'Type',
            dataIndex: 'type',
            render: text => (
              <>
                {text === 'contact' ? (
                  <UserOutlined style={{ fontSize: 16 }} />
                ) : (
                  <BankOutlined style={{ fontSize: 16 }} />
                )}
              </>
            )
          },
          { title: 'Record', dataIndex: 'companyId' },
          {
            title: 'Connector',
            dataIndex: 'connectorName',
            render: text => <Tag key={text}>{text}</Tag>
          },
          {
            title: 'Error',
            dataIndex: 'error',
            width: '50%'
          }
        ]}
      />
    </SettingsWrapper>
  );
}
