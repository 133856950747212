import { Button, Card, Col, Input, Row, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import WebhookTestModal from './WebhookTestModal';

export default function WebhookCard({ type, url, enabled, setEnabled, saveWebhook }) {
  const [localUrl, setLocalUrl] = useState(url);
  const [localEnabled, setLocalEnabled] = useState(enabled);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setLocalUrl(url);
    setLocalEnabled(enabled);
  }, [url, enabled]);

  return (
    <Col span={8}>
      <Card
        title={`${_.startCase(type)} Webhook`}
        extra={
          <Switch
            checkedChildren="Enabled"
            unCheckedChildren="Disabled"
            checked={localEnabled}
            onChange={change => setEnabled(change, type)}
            style={localEnabled ? { backgroundColor: '#87d068' } : {}}
          />
        }
      >
        <Input value={localUrl} onChange={e => setLocalUrl(e.target.value)}></Input>
        <Row style={{ marginTop: 16 }}>
          <Col flex="1">
            <Button type="secondary" onClick={() => setShowModal(true)}>
              Example Payload
            </Button>
          </Col>
          <Col flex="0 1">
            <Button type="primary" justify="center" onClick={() => saveWebhook(localUrl, type)}>
              Save
            </Button>
          </Col>
        </Row>
      </Card>
      <WebhookTestModal visible={showModal} onCancel={() => setShowModal(false)} type={type} url={url} />
    </Col>
  );
};
