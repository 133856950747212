import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Table, Tooltip, Button, Tag, Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import { API } from 'aws-amplify';
import { FieldDrawer } from './FieldDrawer';

const TAG_COLOURS: Record<string, string> = {
  string: 'blue',
  number: 'green',
  picklist: 'cyan',
  multipicklist: 'orange',
  percentage: 'pink',
  currency: 'magenta',
  array: 'skyblue',
  boolean: 'purple'
};

function isConnectorConsideredActive(connectorName: string, connector: any) {
  // Salesforce uses a different field apparently.
  if (connectorName === 'salesforce') return connector.connected;
  return connector.enabled;
}

// Look up CRM field 'nice name' from the result of connector/describe-fields
function findCRMFieldName(crmInternalName: string, fieldNames: any) {
  const accountFields = fieldNames?.accountFields || [];
  const field = accountFields.find((f: any) => f.internalName === crmInternalName);
  if (field)
    return (
      <Tooltip title={`CRM id: ${crmInternalName}`} overlayInnerStyle={{ fontSize: 10 }}>
        {field.label}
      </Tooltip>
    );
  return crmInternalName;
}

function checkForConnectorMapping(connectorName: string, externalName: string, connector: any, fieldNames: any) {
  if (!connector) return null;
  if (!isConnectorConsideredActive(connectorName, connector)) return null;
  const mapping = connector?.mapping || [];
  const mappedField = mapping.find((m: any) => m.value === externalName);
  if (!mappedField) return null;
  if (!fieldNames) return <Skeleton active paragraph={false} style={{ position: 'relative', top: 6 }}></Skeleton>;
  return findCRMFieldName(mappedField.target, fieldNames);
}

function checkForConnectorMappings(
  externalName: string,
  connectors: Record<string, any>,
  connectorFieldNames: Record<string, any>
): any {
  const foundMappings = [];
  const salesforceMapping = checkForConnectorMapping(
    'salesforce',
    externalName,
    connectors?.salesforce,
    connectorFieldNames?.salesforce
  );
  const hubspotMapping = checkForConnectorMapping(
    'hubspot',
    externalName,
    connectors?.hubspot,
    connectorFieldNames?.hubspot
  );
  const pipedriveMapping = checkForConnectorMapping(
    'pipedrive',
    externalName,
    connectors?.pipedrive,
    connectorFieldNames?.pipedrive
  );

  if (salesforceMapping)
    foundMappings.push(
      <div key="salesforce" style={{ display: 'flex', alignItems: 'center' }}>
        <Tag>Salesforce</Tag>
        {salesforceMapping}
      </div>
    );
  if (hubspotMapping)
    foundMappings.push(
      <div key="hubspot" style={{ display: 'flex', alignItems: 'center' }}>
        <Tag>Hubspot</Tag>
        {hubspotMapping}
      </div>
    );
  if (pipedriveMapping)
    foundMappings.push(
      <div key="pipedrive" style={{ display: 'flex', alignItems: 'center' }}>
        <Tag>Pipedrive</Tag>
        {pipedriveMapping}
      </div>
    );

  return foundMappings.length ? foundMappings : '-';
}

// Load field names from connector/describe-fields
async function loadConnectorFieldNames(connectorName: string, setConnectorFieldNames: Function) {
  try {
    const result = await API.get('connectors', '/describe-fields', {
      queryStringParameters: {
        connectorName
      }
    });
    setConnectorFieldNames((prevState: any) => ({
      ...prevState,
      [connectorName]: result
    }));
  } catch (err) {
    console.log('Error loading field names', err);
  }
}

type FieldTableProps = {
  config: any;
  isConfigLoading: boolean;
};

export function FieldTable({ config, isConfigLoading }: FieldTableProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [fieldInfo, setFieldInfo] = useState<any>(null);
  const [connectorFieldNames, setConnectorFieldNames] = useState<any>({
    hubspot: null,
    salesforce: null,
    pipedrive: null
  });

  const connectorSettings = useSelector((state: any) => state?.connectors);

  useEffect(() => {
    if (isConnectorConsideredActive('hubspot', connectorSettings.hubspot) && !connectorFieldNames.hubspot) {
      loadConnectorFieldNames('hubspot', setConnectorFieldNames);
    }
    if (isConnectorConsideredActive('pipedrive', connectorSettings.pipedrive) && !connectorFieldNames.pipedrive) {
      loadConnectorFieldNames('pipedrive', setConnectorFieldNames);
    }
    if (isConnectorConsideredActive('salesforce', connectorSettings.salesforce) && !connectorFieldNames.salesforce) {
      loadConnectorFieldNames('salesforce', setConnectorFieldNames);
    }
  }, [connectorSettings, connectorFieldNames]);

  async function viewFieldInfo(row: any) {
    setIsDrawerOpen(true);
    setFieldInfo(row);
  }

  const columns = [
    {
      dataIndex: 'title',
      title: 'Goodfit Name',
      sorter: (a: any, b: any) => a.title.localeCompare(b.title),
      width: '40%'
    },
    {
      dataIndex: 'type',
      title: 'Data Type',
      align: 'center' as any,
      render: (type: string) => <Tag color={TAG_COLOURS[type.toLowerCase()]}>{type}</Tag>,
      width: '10%'
    },
    {
      key: 'mappedTo',
      title: 'Mapped to CRM Field',
      render: (row: any) => {
        return checkForConnectorMappings(row.title, connectorSettings, connectorFieldNames);
      },
      width: '40%'
    },
    {
      key: 'actions',
      align: 'center' as any,
      render: (row: any) => {
        return (
          <>
            <Button onClick={() => viewFieldInfo(row)} size="small">
              Field Information
            </Button>
          </>
        );
      }
    }
  ];

  const unmappedFields = config?.fieldDefinitions || [];
  const mappedFields = unmappedFields.map((f: any) => {
    return {
      key: f.externalName || f.dataBlockField.publicName,
      title: f.externalName || f.dataBlockField.publicName,
      type: f.dataBlockField.type,
      description: f.publicDescriptionOverride || f.dataBlockField.publicDescription || 'No description available',
      record: f
    };
  });
  const isLoading = connectorSettings.isFetching || isConfigLoading;

  return (
    <>
      <Table columns={columns} dataSource={mappedFields} loading={isLoading} pagination={false} bordered={true}></Table>
      <FieldDrawer fieldInfo={fieldInfo} open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />
    </>
  );
}
