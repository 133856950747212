export type DataDirectoryDataBlockFieldEntry = {
  dataBlock: string;
  internalName: string;
  publicName: string;
  type: string;
  salesforceType: string;
  hubspotType: string;
  publicRefreshFrequencyNotes: string;
  publicDescription: string;
  internalNotes: string;
  isDepreciated: string;
};

export type DataDirectoryDataBlockEntry = {
  blockName: string;
  publicDescription: string;
  internalNotes: string;
  isParameterisedWithConfigId: boolean;
  isParameterisedWithDynamicConfig: boolean;
  isDepreciated: boolean;
};

export enum IntegrationFieldUpdateStrategy {
  OVERWRITE_ALWAYS = 'OVERWRITE_ALWAYS',
  OVERWRITE_ONLY_IF_EMPTY = 'OVERWRITE_ONLY_IF_EMPTY'
}

export type IntegrationExternalFieldDescription = {
  id: string;
  label: string;
  type: string;
};

export type Integration = {
  id: string;
  integrationType: 'SALESFORCE' | 'HUBSPOT' | 'PIPEDRIVE' | 'WEBHOOK';
  mappingSettings: {
    companies: IntegrationFieldMapping[];
    contacts: IntegrationFieldMapping[];
  };
  enableAutoSync: boolean;
  hasCredentials: boolean;
  // not null only for webhooks && pipedrive
  credentials: {
    accountUrl: string;
    contactsUrl: string;
    apiKey: string;
  }
};

export type IntegrationFieldMapping = {
  id: string; // Random uuid
  integrationId: string;
  internalName: string; // dataset name (not CCM name)
  externalName: string; // CRM field name or ID
  updateStrategy?: IntegrationFieldUpdateStrategy;
  isSaving?: boolean;
};

// A version of above but allows externalName to be unset
export type IntegrationFieldMappingInput = Omit<IntegrationFieldMapping, 'externalName'> & {
  externalName: string | null;
};

export type DataDirectoryEntry = {
  datasetName: string; // Unique, the name in the dataset
  ccmName: string; // CCM internal name
  dataBlockField?: DataDirectoryDataBlockFieldEntry;
  dataBlock?: DataDirectoryDataBlockEntry;
  isSystem?: boolean;
  dynamicConfig?: any;
  presentInCCM: boolean;
  mappings: IntegrationFieldMappingInput[];
};
