import { Card, Col, Row, Spin } from 'antd';

import { useSelector } from 'react-redux';
import ConnectorCard from '../components/Connectors/ConnectorCard';
import SettingsWrapper from '../components/SettingsWrapper';

export default function Connectors() {
  const { hubspot, salesforce, isFetching, webhook, pipedrive } = useSelector(state => state.connectors);

  return (
    <SettingsWrapper title="connectors">
      <Card bordered={false}>
        <Row gutter={[32, 32]}>
          {isFetching ? (
            <Spin />
          ) : (
            <>
              <Col span={12}>
                <ConnectorCard type="salesforce" enabled={salesforce.connected} />
              </Col>
              <Col span={12}>
                <ConnectorCard type="hubspot" enabled={hubspot.enabled} />
              </Col>
              <Col span={12}>
                <ConnectorCard type="webhook" enabled={webhook.enabled} />
              </Col>
              <Col span={12}>
                <ConnectorCard type="pipedrive" enabled={pipedrive.enabled} />
              </Col>
            </>
          )}
        </Row>
      </Card>
    </SettingsWrapper>
  );
}
