import _ from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';
import { Select, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { API } from 'aws-amplify';

let loadOptionsTimeout: any;

type FieldMultiSelectProps = {
  fieldName: string;
  value: string[];
  onChange: (value: string[]) => void;
  style?: any;
  placeholder?: string;
  mode?: 'multiple' | 'tags';
  searchMode?: 'prefix' | 'contains';
};

export function FieldMultiSelect({
  fieldName,
  value,
  onChange,
  style,
  placeholder,
  mode,
  searchMode
}: FieldMultiSelectProps) {
  const [previousSearches, setPreviousSearches] = useState<string[]>([]);
  const [options, setOptions] = useState<string[]>([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState<boolean>(false);

  let theValue = _.isArray(value) || !value ? value : [value];
  if (!value) theValue = [];

  useEffect(() => {
    setOptions([]);
  }, [fieldName]);

  async function setSearch(search: string, immediate: boolean) {
    const loadSearchSuggestions = async () => {
      // We implement caching to avoid loading suggestions for same search many times
      if (previousSearches.includes(search)) {
        return;
      }
      setIsLoadingOptions(true);
      setPreviousSearches([...previousSearches, search]);

      const result = await API.post('data', '/field-stats', {
        body: {
          fieldName: fieldName,
          fieldType: 'String',
          limit: 500,
          search
        }
      });
      const newOptions = options;
      for (const term of result.terms) {
        newOptions[term.term] = term.term;
      }
      setOptions(newOptions);
      setIsLoadingOptions(false);
    };

    // Debounce loading
    if (loadOptionsTimeout) clearTimeout(loadOptionsTimeout);
    if (immediate) {
      loadOptionsTimeout = setTimeout(loadSearchSuggestions, 1);
    } else {
      loadOptionsTimeout = setTimeout(loadSearchSuggestions, 750);
    }
  }

  return (
    <Select
      style={style}
      defaultValue={theValue}
      placeholder={placeholder}
      mode={mode || 'multiple'}
      loading={isLoadingOptions}
      tokenSeparators={[' ', ',']}
      notFoundContent={
        <>
          <div style={{ padding: '5px 10px', fontSize: 14, color: '#999' }}>
            {isLoadingOptions && (
              <>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />} /> &nbsp;Loading...
              </>
            )}
            {!isLoadingOptions && <>No options found</>}
          </div>
        </>
      }
      filterOption={(inputValue: string, option: any): boolean => {
        const title = (option.title || '').toLowerCase();
        const fnName = searchMode === 'prefix' ? 'startsWith' : 'includes';
        return title[fnName](inputValue.toLowerCase());
      }}
      onFocus={() => {
        setSearch('', true);
      }}
      onSearch={(str: string) => {
        setSearch(str, false);
      }}
      onSelect={() => {
        setSearch('', false);
      }}
      onChange={onChange}
      allowClear={true}
      options={Object.keys(options).map((t: string) => ({ title: t, value: t }))}
    ></Select>
  );
}
