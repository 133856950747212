import React, { useContext } from "react";
import AppSiteWrapper from "./SiteBasics/AppSiteWrapper";
import { Card, Col, Menu, message, Row } from "antd";
import { LogoutOutlined, ProfileOutlined, SyncOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { AppContext } from "../libs/contextLib";
import { Auth } from "aws-amplify";
import { FeatureFlag, hasFeatureFlag } from "../featureFlags";

export default function SettingsWrapper(props) {
  const appContext = useContext(AppContext);

  const handleLogOut = async appContext => {
    await Auth.signOut();
    appContext.setIsAuthenticated(false);
    message.success(`Logged Out`);
  };
  const shouldShowObservability = hasFeatureFlag(FeatureFlag.v2CrmSync);
  return (
    <AppSiteWrapper title="Settings">
      <Card bodyStyle={{ padding: 0 }}>
        <Row>
          <Col span={6}>
            <Menu mode="inline" selectedKeys={[props.title.toLowerCase()]} style={{ width: 256, height: "100%" }}>
              <Menu.Item key="connectors" icon={<SyncOutlined />}>
                <Link to="/settings/connectors">Connectors</Link>
              </Menu.Item>
              <Menu.Item key="connector-logs" icon={<ProfileOutlined />}>
                <Link to="/settings/connector-logs">Connector Logs</Link>
              </Menu.Item>
              <Menu.Item key="users" icon={<UserOutlined />}>
                <Link to="/settings/users">Users</Link>
              </Menu.Item>
              <Menu.Item key="logout" onClick={() => handleLogOut(appContext)} icon={<LogoutOutlined />}>
                Log Out
              </Menu.Item>
            </Menu>
          </Col>
          <Col span={18} style={{ padding: 20, paddingLeft: 0, paddingRight: 30 }}>
            {props.children}
          </Col>
        </Row>
      </Card>
    </AppSiteWrapper>
  );
}
