import { Card, message, Space, Switch } from 'antd';
import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ExportSettings from '../components/Connectors/ExportSettings';
import ImportSettings from '../components/Connectors/ImportSettings';
import AppSiteWrapper from '../components/SiteBasics/AppSiteWrapper';
import { updateConnectorAPI } from '../features/connectors/connectorsSlice';

export default function HubSpotConnector() {
  const { enabled, mapping, contactMapping, alertsEnabled } = useSelector(state => state.connectors.hubspot);
  const [accountFields, setAccountFields] = useState([]);
  const [contactFields, setContactFields] = useState([]);
  const [oppStages, setOppStages] = useState([]);
  const dispatch = useDispatch();
  const code = useQuery().get('code');

  useEffect(() => {
    describeFields();
    const sendAuthCode = async code => {
      await API.post('connectors', `/authenticate`, { body: { connectorName: 'hubspot', code } });
    };

    if (code) {
      sendAuthCode(code);
    }
  }, [dispatch, code]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const describeFields = async () => {
    const { opportunityStages, accountFields, contactFields } = await API.get(
      'connectors',
      '/describe-fields?connectorName=hubspot'
    );
    setOppStages(opportunityStages);
    setAccountFields(accountFields);
    setContactFields(contactFields);
  };

  const onSwitchChange = change => {
    dispatch(updateConnectorAPI('hubspot', { enabled: change }));
    if (change) {
      message.success('Hurray! Hubspot connector has been enabled!');
    }
  };

  const onAlertsEnabledChange = change => {
    dispatch(updateConnectorAPI('hubspot', { alertsEnabled: change }));
    if (change) {
      message.success('Hurray! Hubspot alerts have been enabled!');
    }
  };

  return (
    <AppSiteWrapper>
      <Card
        title="HubSpot Connector"
        extra={
          <Space>
            <Switch
              checkedChildren="Alerts Enabled"
              unCheckedChildren="Alerts Disabled"
              checked={alertsEnabled}
              onChange={onAlertsEnabledChange}
              style={alertsEnabled ? { backgroundColor: '#87d068' } : {}}
            />
            <Switch
              checkedChildren="Enabled"
              unCheckedChildren="Disabled"
              checked={enabled}
              onChange={onSwitchChange}
              style={enabled ? { backgroundColor: '#87d068' } : {}}
            />
          </Space>
        }
      >
        <ExportSettings
          connectorName="hubspot"
          mapping={mapping}
          contactMapping={contactMapping}
          accountFields={accountFields}
          contactFields={contactFields}
        />
        <ImportSettings connectorName="hubspot" oppStages={oppStages} accountFields={accountFields} />
      </Card>
    </AppSiteWrapper>
  );
}
