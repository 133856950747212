import { API } from 'aws-amplify';
import { createSlice } from '@reduxjs/toolkit';

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    orgUsers: [],
    isFetching: false,
    isFetched: false
  },
  reducers: {
    requestStart: (users, action) => {
      users.isFetching = true;
    },

    requestFailed: (users, action) => {
      users.isFetching = false;
    },

    receiveUsers: (users, action) => {
      users.orgUsers = action.payload;
      users.isFetching = false;
      users.isFetched = true;
    }
  }
});

export const loadUsers = () => async (dispatch, getState) => {
  try {
    dispatch(requestStart());
    const users = (await API.get('users', '/get', {})) || [];
    dispatch(receiveUsers(users));
  } catch (e) {
    console.log(e);
  }
};

export const addUser = email => async dispatch => {
  try {
    dispatch(requestStart());
    await API.post('new-user', '/add', { body: { email } });
    dispatch(loadUsers());
  } catch (e) {
    dispatch(requestFailed());
    throw new Error(e);
  }
};

export const removeUser = email => async dispatch => {
  try {
    dispatch(requestStart());
    await API.post('users', '/remove', { body: { email,  } });
    dispatch(loadUsers());
  } catch (e) {
    dispatch(requestFailed());
    throw new Error(e);
  }
};

const { requestStart, receiveUsers, requestFailed } = usersSlice.actions;

export default usersSlice.reducer;
