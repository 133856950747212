import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message, Card, Space, Button, Switch, Row, Col, Select, Input, Checkbox } from 'antd';
import { updateConnectorAPI } from '../../features/connectors/connectorsSlice';
import _ from 'lodash';
import { API } from 'aws-amplify';

export default function ImportSettings({ connectorName, oppStages, accountFields }) {
  const { importSettings, importEnabled } = useSelector(state => state.connectors[connectorName]);
  const dispatch = useDispatch();
  const saveOpportunityStagesMapping = (values, stage) => {
    dispatch(
      updateConnectorAPI(connectorName, {
        importSettings: { ...importSettings, [`${stage}Stages`]: values }
      })
    );
  };

  const savePreOpportunityFieldsMapping = (values, fieldName) => {
    dispatch(
      updateConnectorAPI(connectorName, {
        importSettings: { ...importSettings, [fieldName]: values }
      })
    );
  };

  const onImportEnabledChange = change => {
    if (
      _.isEmpty(importSettings.wonStages) ||
      _.isEmpty(importSettings.opportunityStages) ||
      _.isEmpty(importSettings.wonStages)
    ) {
      message.warn('Please map all stages in order to enable the Performance Tracking.');
    } else {
      dispatch(updateConnectorAPI(connectorName, { importEnabled: change }));

      if (change) {
        message.success(`Hurray! ${_.startCase(connectorName)} performance trackng has been enabled!`);
      }
    }
  };

  const runImport = () => {
    API.post('connectors', '/trigger-import', {
      body: { strategy: connectorName }
    });
  };
  return (
    <Row style={{ marginTop: 32 }}>
      <Card
        title="Performance Tracking Settings"
        size="small"
        style={{ width: '100%' }}
        extra={
          <Space>
            {importEnabled && (
              <Button type="primary" size="small" onClick={() => runImport()}>
                Run Import
              </Button>
            )}
            <Switch
              checkedChildren="Enabled"
              unCheckedChildren="Disabled"
              defaultChecked={importEnabled}
              onChange={onImportEnabledChange}
              style={importEnabled ? { backgroundColor: '#87d068' } : {}}
            />
          </Space>
        }
      >
        <Row gutter={16}>
          <Col span={8}>
            Active Opportunity Stages:
            <StageSelect
              value={importSettings?.opportunityStages}
              options={oppStages}
              onChange={val => saveOpportunityStagesMapping(val, 'opportunity')}
            />
          </Col>
          <Col span={8}>
            Won Opportunity Stages:
            <StageSelect
              value={importSettings?.wonStages}
              options={oppStages}
              onChange={val => saveOpportunityStagesMapping(val, 'won')}
            />
          </Col>
          <Col span={8}>
            Lost Opportunity Stages:
            <StageSelect
              value={importSettings?.lostStages}
              options={oppStages}
              onChange={val => saveOpportunityStagesMapping(val, 'lost')}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 32 }} gutter={16}>
          <PreOpportunityFieldSelect
            stage="responded"
            options={accountFields}
            mapping={importSettings?.responded}
            onChange={val => savePreOpportunityFieldsMapping(val, 'responded')}
          />
          <PreOpportunityFieldSelect
            stage="contacted"
            options={accountFields}
            mapping={importSettings?.contacted}
            onChange={val => savePreOpportunityFieldsMapping(val, 'contacted')}
          />
          <PreOpportunityFieldSelect
            stage="disqualified"
            options={accountFields}
            mapping={importSettings?.disqualified}
            onChange={val => savePreOpportunityFieldsMapping(val, 'disqualified')}
          />
          <PreOpportunityFieldSelect
            stage="badFit"
            options={accountFields}
            mapping={importSettings?.badFit}
            onChange={val => savePreOpportunityFieldsMapping(val, 'badFit')}
          />
        </Row>
      </Card>
    </Row>
  );
}

const StageSelect = ({ value, options = [], onChange }) => {
  return (
    <Select
      mode="multiple"
      placeholder="Select Stages"
      value={value}
      onChange={onChange}
      style={{ width: '100%' }}
      options={options.map(option => ({ value: option }))}
    />
  );
};

const PreOpportunityFieldSelect = ({
  stage,
  mapping = { fieldName: undefined, value: undefined, anyValue: false },
  options = [],
  onChange
}) => {
  return (
    <Col span={6}>
      {`${_.startCase(stage)} Field Mapping`}
      <Select
        placeholder="Select Field Name"
        style={{ width: '100%' }}
        value={mapping?.fieldName}
        onChange={fieldName => onChange({ ...mapping, fieldName })}
        options={options.map(option => ({ value: option }))}
        showSearch
      />
      <Row style={{ marginTop: 8, alignItems: 'center' }} gutter={8}>
        <Col span={14}>
          <Input
            placeholder="Field Value"
            value={mapping?.value}
            disabled={mapping?.anyValue}
            onChange={e => onChange({ ...mapping, value: e.target.value })}
          />
        </Col>
        <Col span={10}>
          <Checkbox
            checked={mapping?.anyValue}
            onChange={({ target: { checked } }) => {
              onChange({
                ...mapping,
                anyValue: checked,
                value: undefined
              });
            }}
          >
            Any Value
          </Checkbox>
        </Col>
      </Row>
    </Col>
  );
};
