import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import _ from 'lodash';

const connectorsSlice = createSlice({
  name: 'connectors',
  initialState: {
    salesforce: {
      enabled: false
    },
    hubspot: {
      enabled: false,
      mapping: []
    },
    pipedrive: {
      enabled: false,
      mapping: []
    },
    segment: {
      enabled: false,
      mapping: []
    },
    slack: {
      enabled: false
    },
    webhook: {
      enabled: false
    },
    exportCsv: {
      enabled: false
    },
    isFetching: false,
    isFetched: false
  },
  reducers: {
    requestConnectors(state, action) {
      state.isFetching = true;
    },
    receiveConnectors(state, action) {
      _.map(action.payload, (val, key) => (state[key] = val));
      state.isFetching = false;
      state.isFetched = true;
    },
    updateConnector(state, action) {
      const { connector, payload } = action.payload;
      state[connector] = { ...state[connector], ...payload };
    },
    updateConnectorStatus(state, action) {
      const { connector, status } = action.payload;
      state[connector] = { ...state[connector], enabled: status };
    }
  }
});

export const { requestConnectors, receiveConnectors, updateConnector, updateConnectorStatus } = connectorsSlice.actions;

export const fetchConnectors = () => async dispatch => {
  dispatch(requestConnectors());
  const connectors = await API.get('connectors', '/list');
  dispatch(receiveConnectors(connectors));
};

export const updateConnectorMappings =
  ({ mapping, connector }) =>
  async dispatch => {
    await API.post(connector, '/connector', {
      body: { mapping }
    });
    dispatch(updateConnector({ mapping, connector }));
  };

export const changeConnectorStatus =
  ({ connector, status }) =>
  async (dispatch, useState) => {
    dispatch(updateConnectorStatus({ connector, status }));
  };

export const updateConnectorAPI = (connector, payload) => async dispatch => {
  // @TODO: convert payload to date
  dispatch(updateConnector({ connector, payload }));

  await API.post('connectors', '/update', {
    body: { connector, data: payload }
  });
};

export const runConnectorExport =
  ({ connectorName }) =>
  async (dispatch, useState) => {
    const {
      companies: { filters }
    } = useState();
    const { jobId } = await API.post('connectors', '/create-sync', {
      body: { filters, connectorName }
    });

    return jobId;
  };

export const exportCsv = () => async (dispatch, useState) => {
  const {
    companies: { filters, schema },
  } = useState();

  const columns = schema.filter(f=>f.selected).map(f=>f.id);

  const body = { filters, columns };
  return await API.post('connectors', '/csv/export', { body });
};

export default connectorsSlice.reducer;
