import React, { useState, useEffect, useRef } from 'react';
import { Alert, Row, Card, Button, Col, message, Spin } from 'antd';
import { API } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router-dom';
import AppSiteWrapper from '../../components/SiteBasics/AppSiteWrapper';
import { ScoringRules } from './ScoringRules';
import { ScoringPreviewTable } from './ScoringPreviewTable';
import { ScoringRule } from './types';
import { useSelector } from 'react-redux';
import { SortOrder } from 'antd/lib/table/interface';
import { fetchCompanies, fetchSchema } from '../../features/companies/companiesSlice';
import { FieldDrawer } from '../DataDirectory/FieldDrawer';

function getInitialSearchDomainsFromLocalStorage() {
  if (localStorage.getItem('sortingPreviewDomains')) {
    return JSON.parse(localStorage.getItem('sortingPreviewDomains') || '[]');
  }
  return [];
}

type PreviewOptions = {
  sortDirection: SortOrder;
  searchDomains: string[];
};

let loadPreviewTimeout: any = null;

export function ScoringPage() {
  const [rules, setRules] = useState<ScoringRule[]>([]);
  const rulesRef = useRef<ScoringRule[]>([]);

  const [previewOptions, setPreviewOptions] = useState<PreviewOptions>({
    sortDirection: 'descend',
    searchDomains: getInitialSearchDomainsFromLocalStorage()
  });

  const [isScoringSettingsLoading, setIsScoringSettingsLoading] = useState(true);
  const [settingsChanged, setSettingsChanged] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [previewCompanies, setPreviewCompanies] = useState([]);
  const [previewError, setPreviewError] = useState<string | null>(null);

  // For openable field drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [fieldDrawerField, setFieldDrawerField] = useState<any>(null);
  const [dataDirectoryConfig, setDataDirectoryConfig] = useState<any>(null);

  const schema = useSelector(state => (state as any).companies.schema);
  const dispatch = useDispatch();

  const loadDataDirectory = async () => {
    try {
      const result = await API.get('data', '/directory', {});
      setDataDirectoryConfig(result);
    } catch (err) {
      console.log('Error loading config', err);
    }
  };

  useEffect(() => {
    rulesRef.current = rules;
  }, [rules]);

  function updateRules(rules: ScoringRule[]) {
    setSettingsChanged(true);
    setRules(rules);
    scheduleLoadPreview();
  }

  async function loadScoringSettings() {
    try {
      const config = await API.get('scoring', '/config', {});
      setRules(config.rules || []);
    } catch (err) {
      console.log('Error loading rules', err);
    }
    setIsScoringSettingsLoading(false);
  }

  async function saveScoringSettings() {
    setIsSaving(true);

    try {
      await API.post('scoring', '/config', {
        body: {
          rules
        }
      });
    } catch (err) {
      console.log('Error saving rules', err);
    }

    setSettingsChanged(false);
    setIsSaving(false);
    dispatch(fetchSchema());
    dispatch(fetchCompanies());
    message.success('Your scoring has been saved & recalculated successfully');
  }

  function scheduleLoadPreview(options?: PreviewOptions) {
    // Schedule the preview load with 'debounce'
    // eg if it gets called again before 1000ms it will cancel and wait another 1000ms
    // Prevents reloading lots of times
    if (loadPreviewTimeout) {
      clearTimeout(loadPreviewTimeout);
    }
    if (isPreviewLoading) return;
    loadPreviewTimeout = setTimeout(() => loadPreview(options), 1000);
  }

  async function loadPreview(options?: PreviewOptions) {
    // Actually load the preview right now
    if (isPreviewLoading) {
      // If its already loading, we just schedule it again and return
      scheduleLoadPreview();
      return;
    }
    const optionsToUse = options || previewOptions;
    setIsPreviewLoading(true);
    try {
      const result = await API.post('scoring', '/preview', {
        body: {
          rules: rulesRef.current,
          sortDirection: optionsToUse.sortDirection === 'ascend' ? 'asc' : 'desc',
          domains: optionsToUse.searchDomains
        }
      });
      setPreviewCompanies(result.records);
      setIsPreviewLoading(false);
      setPreviewError(null);
    } catch (err) {
      setPreviewError('Could not generate preview');
      setIsPreviewLoading(false);
    }
  }

  function updateSortOrder(order: SortOrder) {
    const newPreviewOptions = {
      ...previewOptions,
      sortDirection: order || 'descend'
    };
    setPreviewOptions(newPreviewOptions);
    loadPreview(newPreviewOptions);
  }

  function setSearchDomains(value: string[]) {
    // Cache the value in localStorage
    localStorage.setItem('sortingPreviewDomains', JSON.stringify(value));
    const newPreviewOptions = {
      ...previewOptions,
      searchDomains: value
    };
    scheduleLoadPreview(newPreviewOptions);
  }

  async function openFieldStatsDrawer(fieldId: any) {
    if (dataDirectoryConfig === null) {
      // Not loaded yet. Should not occur in practice
      return;
    }
    // Lookup the field based on fieldId in dataDirectoryConfig
    const ddFieldInfo = dataDirectoryConfig.fieldDefinitions.find((field: any) => {
      const thisName = field.externalName || field.dataBlockField.publicName;
      return thisName === fieldId;
    });
    if (ddFieldInfo) {
      // Need to convert to the type that field drawer expects
      const ddFieldInfoMapped = {
        key: ddFieldInfo.externalName || ddFieldInfo.dataBlockField.publicName,
        title: ddFieldInfo.externalName || ddFieldInfo.dataBlockField.publicName,
        type: ddFieldInfo.dataBlockField.type,
        description:
          ddFieldInfo.publicDescriptionOverride ||
          ddFieldInfo.dataBlockField.publicDescription ||
          'No description available',
        record: ddFieldInfo
      };
      setFieldDrawerField(ddFieldInfoMapped);
      setIsDrawerOpen(true);
    }
  }

  // Load scoring settings upon first render
  useEffect(() => {
    loadScoringSettings();
  }, []);

  // Load data directory upon first render
  useEffect(() => {
    loadDataDirectory();
  }, []);

  const schemaIsLoaded = schema && schema.length;
  const isReady = schemaIsLoaded && !isScoringSettingsLoading;

  useEffect(() => {
    if (isReady) {
      loadPreview();
    }
    // I did'nt use useCallback as had some issues, but there would be a warning here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  if (!isReady) {
    return (
      <AppSiteWrapper title="Scoring">
        <Spin tip="Loading" size="large" style={{ marginTop: 200 }}>
          <div className="content" />
        </Spin>
      </AppSiteWrapper>
    );
  }

  const shouldShowSaveButton = rules.length > 0 || settingsChanged;

  return (
    <AppSiteWrapper title="Scoring">
      <Prompt when={settingsChanged} message="You have unsaved changes, are you sure you want to leave?" />
      <Row gutter={16}>
        <Card style={{ width: '100%' }} title="Scoring Rules">
          <ScoringRules rules={rules} setRules={updateRules} onFieldStatsClicked={openFieldStatsDrawer} />
          {shouldShowSaveButton && (
            <Row style={{ marginTop: 12 }}>
              <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={saveScoringSettings} type="primary" loading={isSaving} disabled={!settingsChanged}>
                  Save scoring configuration
                </Button>
              </Col>
            </Row>
          )}
        </Card>
      </Row>
      <Row gutter={16} style={{ marginTop: 12 }}>
        {previewError && <Alert message={previewError} type="error" />}
        {!previewError && (
          <ScoringPreviewTable
            sortDirection={previewOptions.sortDirection}
            setSortDirection={updateSortOrder}
            rules={rules}
            loading={isPreviewLoading}
            companies={previewCompanies}
            searchDomains={previewOptions.searchDomains}
            setSearchDomains={setSearchDomains}
          />
        )}
      </Row>
      <FieldDrawer fieldInfo={fieldDrawerField} open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />
    </AppSiteWrapper>
  );
}
