import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import _ from 'lodash';

const companiesSlice = createSlice({
  name: 'companies',
  initialState: {
    schema: [],
    entities: [],
    filters: [],
    limit: 25,
    offset: 0,
    sorting: {
      sortBy: null,
      sortDirection: null
    },
    query: {
      propertyQuery: {},
      changeQuery: {},
      limit: 10,
      lastKey: null
    },
    count: null,
    isFetching: true,
    isFetched: false,
    isFetchingErrored: false,
  },
  reducers: {
    requestCompanies(state, action) {
      state.isFetchingErrored = false;
      state.isFetching = true;
    },
    loadingCompaniesFailed(state, action) {
      state.isFetchingErrored = true;
      state.isFetching = false;
    },
    receiveCompanies(state, action) {
      state.entities = action.payload.records;
      state.count = action.payload.count;
      state.isFetching = false;
      state.isFetched = true;
    },
    receiveMoreCompanies(state, action) {
      state.entities = [...state.entities, ...action.payload.records];
      state.isFetching = false;
    },
    setLastKey(state, action) {
      state.query.lastKey = action.payload.lastKey;
    },
    // setQuery(state, action) {
    //   state.query = { ...state.query, ...action.payload };
    // },
    updateSorting(state, action) {
      state.sorting = action.payload;
    },
    updateFilters(state, action) {
      state.filters = action.payload;
    },
    receiveSchema(state, action) {
      state.schema = action.payload;
    },
    setSchemaFieldVisibility(state, action) {
      const { selected, id } = action.payload;
      state.schema = state.schema.map(field => (field.id === id ? { ...field, selected } : field));
    }
  }
});

export const fetchCompanies = () => async (dispatch, getState) => {
  dispatch(requestCompanies());
  const {
    companies: { filters, limit, offset, sorting }
  } = getState();
  try{
    const payload = await API.post('companies', '/get', {
      body: { filters, limit, offset, ...sorting }
    });
    dispatch(receiveCompanies(payload));
  } catch (err) {
    console.error('Loading companies failed', err);
    dispatch(loadingCompaniesFailed());
  }
};

export const fetchMoreCompanies = () => async (dispatch, getState) => {
  dispatch(requestCompanies());
  const {
    companies: { entities, filters, limit, sorting }
  } = getState();

  try{
    const payload = await API.post('companies', '/get', {
      body: { filters, limit, offset: entities.length, ...sorting }
    });
    dispatch(receiveMoreCompanies(payload));
  } catch (err) {
    console.error('Loading companies failed', err);
    dispatch(loadingCompaniesFailed());
  }
};

export const fetchSchema = () => async dispatch => {
  const schema = await API.get('companies', '/schema');
  const parsedSchema = parseCompanySchema(schema)
  dispatch(receiveSchema(parsedSchema));
};

export const parseCompanySchema = (schema)=>{
  return _.map(schema, (value, key) => ({
    id: key,
    type: _.isObject(value) ? value.type : value,
    name: _.startCase(key),
    selected: true, // _.includes(["domain", "name"], key)
    objectSchema: value?.schema?.[0]?.schema
  }));
}

// This is to be executed when
export const convertFiltersToQuery = filters => {
  const propertyQuery = {};
  const changeQuery = {};

  if (!_.isEmpty(filters.propertyFilters)) {
    propertyQuery.predicate = 'and';
    propertyQuery.statements = filters.propertyFilters.map(filter => {
      return {
        fieldName: filter.fieldName,
        operator: filter.operator.value,
        value: filter.value
      };
    });
  }

  if (!_.isEmpty(filters.changeFilters)) {
    changeQuery.predicate = 'and';
    changeQuery.statements = filters.changeFilters.map(filter => {
      if (filter.operator.type === 'change') {
        return {
          predicate: 'and',
          statements: [
            {
              fieldName: 'fieldName',
              operator: 'eq',
              value: filter.fieldName
            },
            {
              fieldName: 'changeType',
              operator: 'eq',
              value: filter.operator.value
            },
            {
              fieldName: 'change',
              operator: filter.subOperator.value,
              value: filter.value
            }
          ]
        };
      } else {
        return {
          predicate: 'and',
          statements: [
            {
              fieldName: 'fieldName',
              operator: 'eq',
              value: filter.fieldName
            },
            {
              fieldName: filter.operator.value,
              operator: 'eq',
              value: filter.value
            }
          ]
        };
      }
    });
  }

  return { propertyQuery, changeQuery };
};

export const {
  requestCompanies,
  receiveCompanies,
  receiveMoreCompanies,
  setLastKey,
  updateFilters,
  receiveSchema,
  setSchemaFieldVisibility,
  updateSorting,
  loadingCompaniesFailed
} = companiesSlice.actions;

export default companiesSlice.reducer;
