import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import TableWrapper from '../components/CompaniesTable/TableWrapper';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMoreCompanies, setSchemaFieldVisibility } from '../features/companies/companiesSlice';
import { Card, Tag, Space, Button, Typography, Row, Col, Dropdown, Menu, Modal, Checkbox, List, message } from 'antd';
import AppSiteWrapper from '../components/SiteBasics/AppSiteWrapper';
import { runConnectorExport, exportCsv } from '../features/connectors/connectorsSlice';
import FiltersPanel from '../components/Filters/FiltersPanel';
import _ from 'lodash';
import { FeatureFlag, hasFeatureFlag } from '../featureFlags';
import { stringTitleCase} from '../libs/string';

const SelectColumnsModal = props => {
  const { schema } = useSelector(state => state.companies);
  const dispatch = useDispatch();

  function setAll(state) {
    for(const item of schema) {
      if (item.id === 'domain') continue;
      dispatch(
        setSchemaFieldVisibility({
          id: item.id,
          selected: state
        })
      )
    }
  }

  return (
    <Modal 
      {...props}  title="Select Columns"
      footer={(_, { OkBtn, CancelBtn }) => (
      <>
        <Row>
          <Col>
            <Button onClick={() => setAll(true)}>Select all</Button>
            <Button onClick={() => setAll(false)}>Select none</Button>
          </Col>
          <Col flex="auto"></Col>
          <Col>
            <CancelBtn />
            <OkBtn />
          </Col>
        </Row>
      </>
      )}
    >
      <List
        style={{ maxHeight: 500, overflow: 'auto' }}
        size="small"
        dataSource={schema}
        renderItem={item => (
          <List.Item>
            <Checkbox
              label={item.name}
              value={item.id}
              checked={item.selected || item.id === 'domain'}
              disabled={item.id==='domain'}
              onChange={event =>
                dispatch(
                  setSchemaFieldVisibility({
                    id: item.id,
                    selected: event.target.checked
                  })
                )
              }
            >
              {item.name}
            </Checkbox>
          </List.Item>
        )}
      />
    </Modal>
  );
};

const CompaniesOptions = () => {
  const [showSelectColumnsModal, setShowSelectColumnsModal] = useState(false);
  const [isSyncButtonDisabled, setIsSyncButtonDisabled] = useState(false);
  const connectors = useSelector(state => state.connectors);
  const enabledConnectors = _.keys(_.pickBy(connectors, connector => connector.enabled));

  const triggerConnectorExport = async connectorName => {
    disableSyncButton();
    return await dispatch(runConnectorExport({ connectorName }));
  };

  const disableSyncButton = () => {
    setIsSyncButtonDisabled(true);

    setTimeout(() => {
      setIsSyncButtonDisabled(false);
    }, 120000);
  };

  const dispatch = useDispatch();
  return (
    <Space>
      <Button size="small" onClick={() => setShowSelectColumnsModal(true)}>
        Select Columns
      </Button>
      <Dropdown
        disabled={isSyncButtonDisabled}
        placement="bottomCenter"
        overlay={
          <Menu
            onClick={async ({ key }) => {
              if (key === 'exportCsv') {
                message.success(`The export has been started. You will receive an email shortly!`);
                await dispatch(exportCsv());
              } else {
                const jobId = await triggerConnectorExport(key);
                message.success(`The ${key} sync has been successfully scheduled. JobId: ${jobId}`);
                setIsSyncButtonDisabled(false);
              }
            }}
          >
            {enabledConnectors?.map(connector => (
              <Menu.Item key={connector}>{_.startCase(connector)}</Menu.Item>
            ))}
            <Menu.Item key="exportCsv">Export CSV</Menu.Item>
          </Menu>
        }
      >
        <Button size="small" type="primary">
          Sync
        </Button>
      </Dropdown>
      <SelectColumnsModal
        visible={showSelectColumnsModal}
        onOk={() => setShowSelectColumnsModal(false)}
        onCancel={() => setShowSelectColumnsModal(false)}
      />
    </Space>
  );
};

const CompaniesOptionsNewCRMSync = () => {
  const [showSelectColumnsModal, setShowSelectColumnsModal] = useState(false);
  const [isSyncButtonDisabled, setIsSyncButtonDisabled] = useState(false);
  const [integrations, setIntegrations] = useState([]);
  const filters = useSelector(state => state.companies).filters || [];

  async function triggerIntegrationJob(integrationId) {
    try{
      await API.post('integrations', '/jobs/start', {
        body: {
          integrationId,
          filters
        }
      });
      message.success(`The sync has been successfully scheduled. You can view progress in the settings "Connector Logs" page.`);
    } catch (err){
      console.error('Error triggering job', err);
      message.error(`There was an error scheduling the job.`);
    }
  }

  async function checkAndTriggerIntegrationJob(integrationId) {
    const checkResult = await API.post('integrations', '/jobs/check-start', {
      body: {
        integrationId,
      }
    });
    if (!checkResult.canStartJob) {
      if (checkResult.cantStartJobReason==='INTEGRATION_JOB_ALREADY_RUNNING') {
        Modal.confirm({
          title: 'Cancel Running Job?',
          content: 'This connector already has a running sync job. Only one job can be running at once, so do you wish to cancel the running job and schedule the new one?',
          onOk: () => triggerIntegrationJob(integrationId),
          cancelText: 'No',
          okText: 'Yes'
        });
      } else {
        message.error(`This Integration is incorrectly configured. Please check its configuration and try again.`);
      }
    } else {
      triggerIntegrationJob(integrationId);
    }
  }

  async function loadIntegrations() {
    setIntegrations((await API.get('integrations', '/', {})).integrations);
  }

  useEffect(()=>{
    loadIntegrations();
  }, []);

  const disableSyncButton = () => {
    setIsSyncButtonDisabled(true);

    setTimeout(() => {
      setIsSyncButtonDisabled(false);
    }, 120000);
  };

  const dispatch = useDispatch();
  return (
    <Space>
      <Button size="small" onClick={() => setShowSelectColumnsModal(true)}>
        Select Columns
      </Button>
      <Dropdown
        disabled={isSyncButtonDisabled}
        placement="bottomCenter"
        overlay={
          <Menu
            onClick={async ({ key }) => {
              if (key === 'exportCsv') {
                message.success(`The export has been started. You will receive an email shortly!`);
                await dispatch(exportCsv());
              } else {
                await checkAndTriggerIntegrationJob(key);
              }
            }}
          >
            {integrations?.map(integration => (
              <Menu.Item key={integration.id}>{stringTitleCase(integration.integrationType)}</Menu.Item>
            ))}
            <Menu.Item key="exportCsv">Export CSV</Menu.Item>
          </Menu>
        }
      >
        <Button size="small" type="primary">
          Sync
        </Button>
      </Dropdown>
      <SelectColumnsModal
        visible={showSelectColumnsModal}
        onOk={() => setShowSelectColumnsModal(false)}
        onCancel={() => setShowSelectColumnsModal(false)}
      />
    </Space>
  );
};

export default function Home() {
  const entities = useSelector(state => state.companies.entities);
  const isFetching = useSelector(state => state.companies.isFetching);
  const count = useSelector(state => state.companies.count);
  const schema = useSelector(state => state.companies.schema);
  const isFetchingErrored = useSelector(state => state.companies.isFetchingErrored);


  //   const { entities, query, isFetching, count } = createSelector(
  //     (state) => state.companies
  //   );

  // const { entities, query, isFetching, count } = useSelector(
  //   (state) => state.companies
  // );

  //   const { entities, query, isFetching, count } = {
  //     schema: [],
  //     segments: [],
  //     currentSegment: null,
  //     entities: [],
  //     query: {
  //       propertyQuery: {},
  //       changeQuery: {},
  //       limit: 20,
  //       lastKey: null,
  //     },
  //     filters: {
  //       propertyFilters: [],
  //       changeFilters: [],
  //     },
  //     count: null,
  //     isFetching: false,
  //   };

  const dispatch = useDispatch();

  const getMoreCompanies = () => {
    dispatch(fetchMoreCompanies());
  };

  useEffect(() => {
    if (isFetchingErrored) {
      message.error('Something went wrong! Please check if your filters are set up correctly');

    }
  }, [isFetchingErrored]);

  const useV2CrmUI = hasFeatureFlag(FeatureFlag.v2CrmSync);
  return (
    <AppSiteWrapper title="Companies">
      {/* <SegmentsPanel /> */}
      <FiltersPanel />
      <Card
        title={
          <Space align="bottom">
            <Typography.Text>Companies</Typography.Text>
            <Tag>{count}</Tag>
          </Space>
        }
        extra={useV2CrmUI ? <CompaniesOptionsNewCRMSync /> : <CompaniesOptions />}
        bodyStyle={{ padding: 0 }}
        style={{ marginTop: 16 }}
      >
      <TableWrapper schema={schema} entities={entities} loading={isFetching} />
      </Card>
      <Row justify="center" style={{ padding: 16 }}>
        <Button
          loading={isFetching}
          type="primary"
          disabled={count === entities.length}
          onClick={() => getMoreCompanies()}
        >
          Load More
        </Button>
      </Row>
    </AppSiteWrapper>
  );
}
