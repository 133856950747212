import { getDefaultRegistry } from "@rjsf/core";
import React from "react";

const FieldValueWidget = function(props) {
  const { TextWidget, CheckboxWidget, DateWidget } = getDefaultRegistry().widgets;

  switch (props.schema.type) {
    case "string":
      if (props.schema.format === "date")
        return <DateWidget {...props} />;
      return <TextWidget {...props} />;
    case "boolean":
      return <CheckboxWidget {...props} label={""} />;
    case "array":
      return <ArrayWidget {...props} />;
    case "number":
      return <TextWidget {...props} />;
  }
};

class ArrayWidget extends React.Component {
  constructor(props) {
    super(props);
    if (typeof props.value === "array") {
      this.state = {
        value: props.value ? props.value?.join(", ") : ""
      };
    } else {
      this.state = {
        value: props.value ? props.value : ""
      };
    }
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const { onChange } = this.props;
    let newVal
    if (this.props.schema.items.type === "number") {
      newVal = e.target.value.split(",").map(v => v.trim()).map(v => Number(v));
    } else {
      newVal = e.target.value.split(",").map(v => v.trim());
    }
    this.setState({ value: newVal });
    onChange(newVal);
  }

  render() {
    const { id, required, disabled, readonly, autofocus } = this.props;
    return (
      <input
        id={id}
        type="text"
        required={required}
        disabled={disabled || readonly}
        autoFocus={autofocus}
        value={this.state.value}
        onChange={this.onChange}
        className="form-control" // Add the class to match default styling
      />
    );
  }
}


export default FieldValueWidget;