import { CheckCircleFilled, LoadingOutlined, WarningFilled, PlusOutlined } from '@ant-design/icons';
import { Select, Dropdown, Button, Flex, Tooltip, Spin, Divider } from 'antd';
import { FieldCreationModal } from './FieldCreationModal';
import { Integration, IntegrationFieldMappingInput } from '../types';
import { checkMapping, useDataDirectoryStore } from '../state';
import { stringTitleCase } from '../../../libs/string';
import { useMemo, useState } from 'react';
import { getFieldType } from '../createFieldUtils';
import { useAppContext } from '../../../libs/contextLib';

const fieldUpdateStrategyOptions = [
  { value: 'OVERWRITE_ALWAYS', label: 'Overwrite' },
  { value: 'OVERWRITE_ONLY_IF_EMPTY', label: 'Update if empty' }
];

type FieldMappingEditorRowProps = {
  mapping: IntegrationFieldMappingInput;
  onChange: any;
  onRemove: any;
  recordType: string;
};
function FieldMappingEditorRow({ mapping, onChange, onRemove, recordType }: FieldMappingEditorRowProps) {
  const store = useDataDirectoryStore();
  const { isGoodFit } = useAppContext() as any;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const integration = store.integrations.find(i => i.id === mapping.integrationId) as Integration;
  const externalFieldType = useMemo(
    () => getFieldType(recordType, mapping.internalName, integration, store),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [recordType, mapping, integration]
  );
  if (!integration) return <></>;

  const integrationProperties =
    (recordType === 'contact'
      ? store.integrationContactFieldDescriptions[mapping.integrationId]
      : store.integrationCompanyFieldDescriptions[mapping.integrationId]) || [];

  const errorMessage = checkMapping(mapping);
  const isOk = !errorMessage;

  /* We don't support field creation for Pipedrive */
  /* Currently hidden unless goodfit staff */
  const allowFieldCreation = integration.integrationType !== 'PIPEDRIVE' && externalFieldType && isGoodFit;

  return (
    <>
      <Flex style={{ width: '99%', margin: '4px -8px' }} justify="space-between" gap="small">
        <Button size="small" disabled={true} style={{ width: 110 }}>
          {stringTitleCase(integration.integrationType)}
        </Button>
        {mapping.isSaving ? (
          <Spin size="small" style={{ marginTop: 4 }} indicator={<LoadingOutlined spin />} />
        ) : isOk ? (
          <Tooltip title="Mapping is configured and saved correctly">
            <CheckCircleFilled style={{ color: 'green' }} />
          </Tooltip>
        ) : (
          <Tooltip title={`Mapping is not valid: ${errorMessage}`}>
            <WarningFilled style={{ color: 'red' }} />
          </Tooltip>
        )}
        <Select
          style={{ minWidth: 210, maxWidth: 210, width: 210 }}
          size="small"
          placeholder="Select CRM field"
          value={mapping.externalName}
          showSearch={true}
          dropdownMatchSelectWidth={false}
          dropdownStyle={{ minWidth: 300, maxWidth: 500 }}
          onChange={value => onChange({ externalName: value })}
          dropdownRender={menu => (
            <>
              {allowFieldCreation && (
                <>
                  <Button
                    icon={<PlusOutlined />}
                    block
                    size="small"
                    style={{ border: '1px dashed #8c6cf5' }}
                    onClick={() => setIsModalVisible(true)}
                  >
                    Create New Field
                  </Button>
                  <Divider style={{ margin: '8px 0' }} />
                </>
              )}
              {menu}
            </>
          )}
          options={integrationProperties.map((prop: any) => ({
            label: `${prop.label} (${stringTitleCase(prop.type)})`,
            value: prop.id
          }))}
        />
        <Select
          value={mapping.updateStrategy}
          style={{ minWidth: 150, width: '30%' }}
          size="small"
          options={fieldUpdateStrategyOptions}
          onChange={value => onChange({ updateStrategy: value })}
        />

        <Button size="small" onClick={onRemove}>
          ✕
        </Button>
      </Flex>
      <FieldCreationModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        mapping={mapping}
        recordType={recordType}
      />
    </>
  );
}

type FieldMappingEditorProps = {
  fieldName: string;
  mappings: IntegrationFieldMappingInput[];
  recordType: string;
};
export function FieldMappingEditor({ fieldName, mappings, recordType }: FieldMappingEditorProps) {
  const store = useDataDirectoryStore();
  const integrations = store.integrations;

  function addMapping(integrationId: string) {
    store.addFieldMapping(integrationId, recordType, fieldName);
  }

  function removeMapping(integrationId: string, id: string) {
    store.removeFieldMapping(integrationId, recordType, id);
  }

  function updateMapping(integrationId: string, id: string, updates: any) {
    store.modifyFieldMapping(integrationId, recordType, id, updates);
  }

  if (mappings.length === 0) {
    // If no integrations we hide the add mapping button
    if (integrations.length === 0) {
      return <>-</>;
    }
    return (
      <div style={{ margin: '0px' }}>
        <Dropdown
          menu={{
            onClick: ev => addMapping(ev.key),
            items: integrations.map(integration => ({
              label: `Add ${stringTitleCase(integration.integrationType)} field mapping`,
              key: integration.id
            }))
          }}
          trigger={['click']}
        >
          <Button size="small">+ Add mapping</Button>
        </Dropdown>
      </div>
    );
  }

  return (
    <div>
      <Flex style={{ width: '100%' }} justify="space-between" gap="small">
        <div>
          {mappings.map(mapping => (
            <FieldMappingEditorRow
              //integrations={integrations}
              recordType={recordType}
              key={mapping.id}
              mapping={mapping}
              onChange={(updates: any) => updateMapping(mapping.integrationId, mapping.id, updates)}
              onRemove={() => removeMapping(mapping.integrationId, mapping.id)}
            />
          ))}
        </div>
        <div style={{ marginTop: 4 }}>
          <Dropdown
            menu={{
              onClick: ev => addMapping(ev.key),
              items: integrations.map(integration => ({
                label: `Add ${stringTitleCase(integration.integrationType)} field mapping`,
                key: integration.id
              }))
            }}
            trigger={['click']}
          >
            <Button size="small">+</Button>
          </Dropdown>
        </div>
      </Flex>
    </div>
  );
}
