import React from 'react';
import { BarsOutlined, ReloadOutlined, PartitionOutlined } from '@ant-design/icons';

export const filterTypeOptions = [
  {
    name: 'property',
    color: 'blue',
    icon: <BarsOutlined />
  },
  {
    name: 'group',
    color: 'blue',
    icon: <PartitionOutlined />
  },
  /* this was hidden for all by default as its unreliable, but can be added by feature flag in Filters.js
  {
    name: 'change',
    color: 'blue',
    icon: <ReloadOutlined />
  }*/
  //   {
  //     name: "contact",
  //     color: "blue",
  //     icon: <TeamOutlined />,
  //   },
];

export const predicateOptions = [
  {
    name: 'AND',
    color: '#87d068'
  },
  {
    name: 'OR',
    color: '#2db7f5'
  }
];

const contains = {
  name: 'contains',
  value: 'contains'
};

const equals = {
  name: 'equals',
  value: 'eq'
};

const notContains = {
  name: 'not contains',
  value: 'not_contains'
};

const notEquals = {
  name: 'not equals',
  value: 'ne'
};

const exists = {
  name: 'exists',
  value: 'not_null'
};

const notExists = {
  name: 'not exists',
  value: 'null'
};

const greaterThan = {
  name: 'greater than',
  value: 'gt'
};

const lowerThan = {
  name: 'lower than',
  value: 'lt'
};

const hasMoreValues = {
  name: 'has more values than',
  value: 'hasMoreValues'
};

const hasLessValues = {
  name: 'has less values than',
  value: 'hasLessValues'
};

// const isTrue = {
//   name: "true",
//   value: "eq",
// };

// const isFalse = {
//   name: "false",
//   value: "eq",
// };

const between = {
  name: 'between',
  value: 'between'
};

const oldValue = {
  name: 'old value',
  value: 'oldValue',
  operatorType: 'change',
  subOperators: [equals, notEquals, contains, notContains]
};

const newValue = {
  name: 'new value',
  value: 'newValue',
  operatorType: 'change',
  subOperators: [equals, notEquals, contains, notContains]
};

const added = {
  name: 'added',
  value: 'added',
  type: 'change',
  subOperators: [equals, notEquals, contains, notContains]
};
const removed = {
  name: 'removed',
  value: 'removed',
  type: 'change',
  subOperators: [equals, notEquals, contains, notContains]
};

const increased = {
  name: 'increased by %',
  value: 'increased',
  type: 'change',
  subOperators: [equals, notEquals, greaterThan, lowerThan]
};

const decreased = {
  name: 'decreased by %',
  value: 'decreased',
  type: 'change',
  subOperators: [equals, notEquals, greaterThan, lowerThan]
};

export const operatorOptions = {
  property: {
    string: [contains, notContains, equals, notEquals, exists, notExists],
    number: [equals, notEquals, greaterThan, lowerThan, exists, notExists],
    currency: [equals, notEquals, greaterThan, lowerThan, exists, notExists],
    percentage: [equals, notEquals, greaterThan, lowerThan, exists, notExists],
    array: [contains, notContains, hasMoreValues, hasLessValues, exists, notExists],
    boolean: [equals, exists, notExists],
    date: [between, exists, notExists]
  },
  change: {
    string: [oldValue, newValue, exists, notExists],
    number: [increased, decreased, oldValue, newValue, exists, notExists],
    currency: [increased, decreased, oldValue, newValue, exists, notExists],
    percentage: [increased, decreased, oldValue, newValue, exists, notExists],
    array: [added, removed, exists, notExists],
    boolean: [oldValue, newValue, exists, notExists]
  }
};

export const subOperatorOptions = {
  'old value': [equals, notEquals, contains, notContains],
  'new value': [equals, notEquals, contains, notContains],
  added: [equals, notEquals, contains, notContains],
  removed: [equals, notEquals, contains, notContains],
  'increased by %': [equals, notEquals, greaterThan, lowerThan],
  'decreased by %': [equals, notEquals, greaterThan, lowerThan]
};
