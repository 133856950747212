import React from 'react';
import { Card, Button, Tag, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import slackLogo from '../../images/slack-logo.png';
import webhooksLogo from '../../images/webhooks_logo.svg';
import salesforceLogo from '../../images/salesforce_logo.svg';
import hubspotLogo from '../../images/hubspot_logo.svg';
import pipedriveLogo from '../../images/pipedrive_logo.svg';

export default function ConnectorCard({ type, enabled, coming }) {
  const connectorSettings = {
    hubspot: {
      authUrl: '',
      imgUrl: hubspotLogo,
      connectAction: () =>
        (window.location.href =
          `https://app.hubspot.com/oauth/authorize?client_id=6d170897-421e-436e-8e7b-18d333d35a6b&redirect_uri=${window.location.origin}/connectors/hubspot&scope=oauth%20integration-sync%20crm.objects.contacts.read%20crm.import%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.schemas.contacts.read%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.deals.write%20crm.schemas.companies.read%20crm.schemas.companies.write%20crm.schemas.contacts.write%20crm.schemas.deals.read%20crm.schemas.deals.write`)
    },
    segment: {
      authUrl: '',
      imgUrl: 'https://i1.wp.com/logosandtypes.com/wp-content/uploads/2019/12/Segment.png',
      connectAction: () => (window.location.href = '/connectors/segment')
    },
    webhook: {
      authUrl: '',
      imgUrl: webhooksLogo,
      connectAction: () => (window.location.href = '/connectors/webhook')
    },
    salesforce: {
      imgUrl: salesforceLogo,
      connectAction: () => (window.location.href = '/connectors/salesforce')
    },
    slack: {
      authUrl: '',
      imgUrl: slackLogo,
      connectAction: () =>
        (window.location.href = `https://slack.com/oauth/v2/authorize?client_id=502256382785.1246355957863&redirect_uri=${window.location.origin}/connectors/slack&scope=channels:join,chat:write.customize,chat:write,chat:write.public`)
    },
    pipedrive: {
      authUrl: '',
      imgUrl: pipedriveLogo,
      connectAction: () => (window.location.href = '/connectors/pipedrive')
    }
  };

  return (
    <Card style={{ width: 300 }}>
      <Row justify="center" align="middle">
        <Col span={12}>
          <img src={connectorSettings[type].imgUrl} height={100} alt={type} />
        </Col>
        <Col span={12}>
          {coming ? (
            <Tag color="#CDCDCD" style={{ margin: 0 }}>
              Coming Soon
            </Tag>
          ) : (
            <>
              <Row justify="center" align="middle" style={{ marginTop: 8 }}>
                {enabled ? (
                  <Tag color="green" style={{ margin: 0 }}>
                    Connected
                  </Tag>
                ) : (
                  <Tag color="blue" style={{ margin: 0 }}>
                    Not Connected
                  </Tag>
                )}
              </Row>
              <Row justify="center" align="middle" style={{ marginTop: 8 }}>
                {enabled ? (
                  <Link to={`/connectors/${type}`}>
                    <Button>Settings</Button>
                  </Link>
                ) : (
                  <Button onClick={() => connectorSettings[type].connectAction()}>Connect</Button>
                )}
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Card>
  );
}
