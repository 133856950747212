import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import { API } from 'aws-amplify';
import AppSiteWrapper from '../../components/SiteBasics/AppSiteWrapper';
import { SourcingCriteriaDisplay } from './SourcingCriteriaDisplay';
import { FieldTable } from './FieldTable';

export function DataDirectory() {
  const [isConfigLoading, setIsConfigLoading] = useState(true);
  const [config, setConfig] = useState<any>(null);

  const loadDataDirectory = async () => {
    try {
      const result = await API.get('data', '/directory', {});
      setConfig(result);
    } catch (err) {
      console.log('Error loading config', err);
    }
    setIsConfigLoading(false);
  };

  useEffect(() => {
    loadDataDirectory();
  }, []);

  return (
    <AppSiteWrapper title="Data Directory">
      <Card title="Company Criteria" style={{ marginBottom: 16 }} loading={isConfigLoading}>
        <SourcingCriteriaDisplay
          sourcingCriteria={config?.sourcingCriteria}
          fieldDefinitions={config?.fieldDefinitions}
        ></SourcingCriteriaDisplay>
      </Card>
      <Card bodyStyle={{ padding: 0 }} title="Field Definitions">
        <FieldTable config={config} isConfigLoading={isConfigLoading} />
      </Card>
    </AppSiteWrapper>
  );
}
