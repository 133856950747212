const configStages = {
  prod: {
    apiGateway: {
      REGION: 'us-east-1',
      URL: 'https://mnbg9jhqya.execute-api.us-east-1.amazonaws.com/prod'
    },
    cognito: {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_8t1PlFPGj',
      APP_CLIENT_ID: '4qol72f0bsuui8m3ceat9ubd3v',
      IDENTITY_POOL_ID: 'us-east-1:a7e8ec47-2194-47e7-9f4d-6453f7a33b1d'
    },
    salesforce: {
      AUTH_URL:
        'https://login.salesforce.com/services/oauth2/authorize?client_id=3MVG9sh10GGnD4DsgEDcn.aJonbfwqNl_A7l51m5tNjaaAic53_LCr0r40aNlDihExvWTvS33Ldy9lbZADfg6&redirect_uri=https://app.goodfit.io/connectors/salesforce&response_type=code'
    },
    ccm:{
      SCHEMA:'https://2d2kiz2bln7n36liza57mfw2la0kchms.lambda-url.us-east-1.on.aws/',
      PREVIEW:'https://ccarfbalkyhlobse57zymbaiua0lwhzg.lambda-url.us-east-1.on.aws/'
    },
    integrations:'https://5d8f5o48ml.execute-api.us-east-1.amazonaws.com/prod/integrations',
  },
  dev: {
    apiGateway: {
      REGION: 'us-east-1',
      URL: 'https://xnt05c9im8.execute-api.us-east-1.amazonaws.com/dev'
    },
    cognito: {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_8t1PlFPGj',
      APP_CLIENT_ID: '4qol72f0bsuui8m3ceat9ubd3v',
      IDENTITY_POOL_ID: 'us-east-1:a7e8ec47-2194-47e7-9f4d-6453f7a33b1d'
    },
    salesforce: {
      AUTH_URL:
        'https://login.salesforce.com/services/oauth2/authorize?client_id=3MVG9sh10GGnD4DsgEDcn.aJonbfwqNl_A7l51m5tNjaaAic53_LCr0r40aNlDihExvWTvS33Ldy9lbZADfg6&redirect_uri=https://dev-app.goodfit.io/connectors/salesforce&response_type=code'
    },
    ccm:{
      SCHEMA:'https://ib52xxp7txyaenrmqv4g6k7z5y0ypxyj.lambda-url.us-east-1.on.aws/',
      PREVIEW:'https://v4c5musikygnkcaqnzubcsyhye0oquzn.lambda-url.us-east-1.on.aws/'
    },
    integrations: 'https://teqmhjbd5m.execute-api.us-east-1.amazonaws.com/dev/integrations',
  },
  local: {
    apiGateway: {
      REGION: 'us-east-1',
      URL: `http://localhost:4000/${process.env.REACT_APP_STAGE}`
    },
    cognito: {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_8t1PlFPGj',
      APP_CLIENT_ID: '4qol72f0bsuui8m3ceat9ubd3v',
      IDENTITY_POOL_ID: 'us-east-1:a7e8ec47-2194-47e7-9f4d-6453f7a33b1d'
    },
    salesforce: {
      AUTH_URL:
        'https://login.salesforce.com/services/oauth2/authorize?client_id=3MVG9sh10GGnD4DsgEDcn.aJonbfwqNl_A7l51m5tNjaaAic53_LCr0r40aNlDihExvWTvS33Ldy9lbZADfg6&redirect_uri=http://localhost:3000/connectors/salesforce&response_type=code'
    },
    ccm:{
      SCHEMA:'https://ib52xxp7txyaenrmqv4g6k7z5y0ypxyj.lambda-url.us-east-1.on.aws/',
      PREVIEW:'https://v4c5musikygnkcaqnzubcsyhye0oquzn.lambda-url.us-east-1.on.aws/'
    },
    integrations:' http://localhost:3000/local/integrations',
  }
};

const config = configStages[process.env.REACT_APP_STAGE];

export default config;
