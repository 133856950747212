import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";

const AuthenticatedRoute = ({ path, onlyForGoodFit = false, component: Component, render, ...rest }) => {
  const { isAuthenticated, isGoodFit } = useAppContext();
  return (
    <Route
      {...rest}
      render={props => {
        if (!isAuthenticated) {
          return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
        } else {
          if (!onlyForGoodFit) {
            return Component ? <Component {...props} /> : render(props);
          } else if (isGoodFit) {
            return Component ? <Component {...props} /> : render(props);
          } else {
            return <Redirect to={{ pathname: "/not-allowed", state: { from: props.location } }} />;
          }
        }
      }}
    />
  );
};

export default AuthenticatedRoute;
