import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Select, Button, Input, message } from 'antd';
import { SwapOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import _ from 'lodash';

export default function MappingPanel({
  firstSelectorOptions = [],
  secondSelectorOptions = [],
  mapping,
  title,
  onSave
}) {
  const [localMapping, setLocalMapping] = useState([{ value: null, target: null }]);

  useEffect(() => {
    if (mapping) {
      setLocalMapping(mapping);
    }
  }, [mapping]);

  const updateMapping = (i, key, target) => {
    const newState = localMapping.map((val, index) => (index === i ? { ...val, [target]: key } : val));
    setLocalMapping(newState);
  };

  const onSaveWrapper = mapping => {
    if (isMappingValid(mapping)) {
      onSave(mapping);
    } else {
      message.error("There's somethign wrong with your mapping. Please review.");
    }
  };

  const isMappingValid = mapping => {
    return _.isEmpty(_.filter(mapping, item => item.value === null || item.target === null));
  };

  const renderMapping = () => {
    return localMapping.map((map, i) => {
      return (
        <Row type="flex" justify="end" align="middle" gutter={16} style={{ marginTop: 8 }}>
          <Col span={10}>
            <Select
              key={`value-${i}`}
              placeholder="Select Value"
              size="small"
              style={{ width: '100%' }}
              onSelect={key => updateMapping(i, key, 'value')}
              value={map.value}
              showSearch
            >
              {firstSelectorOptions.map(
                option =>
                  !localMapping.find(val => val.value === option.id) && (
                    <Select.Option key={option.id}>{option.name}</Select.Option>
                  )
              )}
            </Select>
          </Col>
          <Col
            span={2}
            style={{
              display: 'inline-flex',
              justifyContent: 'center'
            }}
          >
            <SwapOutlined />
          </Col>
          <Col span={10}>
            {!_.isEmpty(secondSelectorOptions) ? (
              <Select
                size="small"
                key={`target-${i}`}
                placeholder="Select Value"
                style={{ width: '100%' }}
                onSelect={key => updateMapping(i, key, 'target')}
                value={secondSelectorOptions.find(option => option.internalName === map.target)?.label}
                optionFilterProp="children"
                showSearch
              >
                {_.map(secondSelectorOptions, option => ({
                  id: option.internalName,
                  name: option.label
                })).map(
                  option =>
                    !localMapping.find(val => val.target === option.id) && (
                      <Select.Option key={option.id} value={option.id}>
                        {option.name}
                      </Select.Option>
                    )
                )}
              </Select>
            ) : (
              <Input
                size="small"
                key={`target-${i}`}
                onChange={e => updateMapping(i, e.target.value, 'target')}
                value={map.target}
              ></Input>
            )}
          </Col>
          <Col
            span={2}
            style={{
              display: 'inline-flex',
              justifyContent: 'center'
            }}
          >
            <Button
              icon={<MinusOutlined />}
              size="small"
              onClick={() => setLocalMapping(localMapping.filter((val, index) => i !== index))}
            ></Button>
          </Col>
        </Row>
      );
    });
  };

  return (
    <Card
      title={title}
      size="small"
      style={{ height: '100%' }}
      bodyStyle={{
        overflow: 'auto',
        maxHeight: 300
      }}
    >
      {renderMapping()}
      <Row style={{ marginTop: 12 }}>
        <Button
          icon={<PlusOutlined />}
          size="small"
          style={{ width: '100%' }}
          type="dashed"
          onClick={() => setLocalMapping([...localMapping, { value: null, target: null }])}
        >
          Add Mapping
        </Button>
      </Row>
      <Row
        style={{
          marginTop: '8px'
        }}
        justify="end"
      >
        <Button size="small" type="primary" onClick={() => onSaveWrapper(localMapping)}>
          Save
        </Button>
      </Row>
    </Card>
  );
}
