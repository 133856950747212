import React, { useEffect, useState } from "react";
import "tabler-react/dist/Tabler.css";
import { message, ConfigProvider, Button } from "antd";
import Routes from "./Routes";
import { AppContext } from "./libs/contextLib";
import { Auth } from "aws-amplify";
import { Provider } from "react-redux";
import { GoodfitUserAccountBar } from './components/GoodfitUserAccountBar'
import "./App.css";
import store from "./store";

const ANTD_THEME = {
  "token": {
    "colorPrimary": "#8c6cf5",
    "colorInfo": "#8c6cf5",
    "borderRadius": 3
  }
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isGoodFit, setIsGoodFit] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [userEmail, setUserEmail] = useState(null);

  useEffect(() => {
    onLoad();
  }, []);

  // @TODO: Fix messaging properly
  message.config({
    top: 53
  });

  async function onLoad() {
    try {
      const session = await Auth.currentSession();
      try {
        if (window.posthog) {
          const idToken = session.getIdToken();
          const email = idToken.payload.email;
          const accountName = idToken.payload["custom:account_name"];
          const userId = idToken.payload["cognito:username"];
          window.goodfitAccountName = accountName;
          if (email?.includes("@goodfit.io")) {
            setIsGoodFit(true);
          } else {
            setIsGoodFit(false);
          }

          window.posthog.identify(
            userId,
            {
              email,
              accountName
            }
          );
        }
      } catch (e) {
        console.error("Could not init posthog from existing session", e);
      }
      setIsAuthenticated(true);
      const idToken = session.getIdToken();
      const email = idToken.payload.email;
      setUserEmail(email);

    } catch (e) {
      if (e !== "No current user") {
        alert(e.message);
      }
    }

    setIsAuthenticating(false);
  }

  return (
    <ConfigProvider theme={ANTD_THEME}>
    {!isAuthenticating && (
      <AppContext.Provider value={{
        isAuthenticated, setIsAuthenticated,
        isGoodFit, setIsGoodFit
      }}>
        <Provider store={store}>
          <Routes />
        </Provider>
      </AppContext.Provider>
    )}
    <GoodfitUserAccountBar isGoodFit={isGoodFit} userEmail={userEmail} />
    </ConfigProvider>
  );
}

export default App;
