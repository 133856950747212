export enum FeatureFlag {
  showChangeFilter = 'showChangeFilter',
  showAlerts = 'showAlerts',
  v2CrmSync = 'v2CrmSync'
}

const clientFeatureFlags: Record<string, FeatureFlag[]> = {
  dbt: [FeatureFlag.v2CrmSync],
  stellate: [FeatureFlag.v2CrmSync],
  cobee: [FeatureFlag.v2CrmSync],
  graphcdn: [FeatureFlag.showChangeFilter],
  testgorilla: [FeatureFlag.showChangeFilter, FeatureFlag.v2CrmSync],
  'paddle-self-serve': [FeatureFlag.showChangeFilter],
  'storyblok-partnerships': [FeatureFlag.showChangeFilter],
  yotpo: [FeatureFlag.showChangeFilter],
  saastock: [FeatureFlag.showChangeFilter, FeatureFlag.v2CrmSync],
  gympass: [FeatureFlag.showChangeFilter, FeatureFlag.showAlerts],
  mirrorweb: [FeatureFlag.showChangeFilter],
  atolio: [FeatureFlag.showChangeFilter, FeatureFlag.showAlerts],
  playroll: [FeatureFlag.showChangeFilter, FeatureFlag.showAlerts],
  nomio: [FeatureFlag.showChangeFilter],
  mutiny: [FeatureFlag.showChangeFilter, FeatureFlag.showAlerts],
  returnista: [FeatureFlag.showChangeFilter],
  unbabel: [FeatureFlag.showChangeFilter, FeatureFlag.showAlerts],
  'dev-test': [FeatureFlag.showChangeFilter, FeatureFlag.showAlerts, FeatureFlag.v2CrmSync],
  test: [FeatureFlag.showChangeFilter, FeatureFlag.showAlerts, FeatureFlag.v2CrmSync],
  'test-integration': [FeatureFlag.showChangeFilter, FeatureFlag.v2CrmSync],
  'test-integrations': [FeatureFlag.showChangeFilter, FeatureFlag.v2CrmSync],
  'test-integrations-large': [FeatureFlag.showChangeFilter, FeatureFlag.v2CrmSync],
  gocardless: [FeatureFlag.showChangeFilter],
  storyblok: [FeatureFlag.showChangeFilter],
  clari: [FeatureFlag.showChangeFilter],
  blobr: [FeatureFlag.showChangeFilter, FeatureFlag.showAlerts],
  webstacks: [FeatureFlag.showChangeFilter],
  cerebra: [FeatureFlag.showChangeFilter],
  workvivo: [FeatureFlag.showChangeFilter],
  apollo: [FeatureFlag.showChangeFilter, FeatureFlag.v2CrmSync],
  'klima-metrix': [FeatureFlag.showChangeFilter],
  'durham-lane': [FeatureFlag.showChangeFilter],
  wingback: [FeatureFlag.showChangeFilter],
  meltwater: [FeatureFlag.showChangeFilter],
  refract: [FeatureFlag.showChangeFilter],
  strava: [FeatureFlag.showChangeFilter],
  brightpearl: [FeatureFlag.showChangeFilter],
  alloy: [FeatureFlag.showChangeFilter],
  occupop: [FeatureFlag.showChangeFilter],
  qawolf: [FeatureFlag.showChangeFilter, FeatureFlag.showAlerts],
  moss: [FeatureFlag.showChangeFilter],
  howfm: [FeatureFlag.showChangeFilter],
  paragon: [FeatureFlag.showChangeFilter],
  primer: [FeatureFlag.showChangeFilter, FeatureFlag.showAlerts],
  brightnetwork: [FeatureFlag.showChangeFilter],
  figures: [FeatureFlag.showChangeFilter, FeatureFlag.showAlerts, FeatureFlag.v2CrmSync],
  hook: [FeatureFlag.showChangeFilter],
  hull: [FeatureFlag.showChangeFilter],
  cledara: [FeatureFlag.showChangeFilter, FeatureFlag.showAlerts],
  'dev-easol': [FeatureFlag.showChangeFilter],
  'glofox-demo': [FeatureFlag.showChangeFilter, FeatureFlag.showAlerts],
  intruder: [FeatureFlag.showChangeFilter, FeatureFlag.v2CrmSync],
  'dev-paddle': [FeatureFlag.showChangeFilter],
  paddle: [FeatureFlag.showChangeFilter],
  pierecruitment: [FeatureFlag.showChangeFilter],
  speedscale: [FeatureFlag.showChangeFilter],
  'dwh-screenloop': [FeatureFlag.showChangeFilter],
  whereby: [FeatureFlag.showChangeFilter],
  hokodo: [FeatureFlag.showChangeFilter],
  easol: [FeatureFlag.showChangeFilter],
  adway: [FeatureFlag.showChangeFilter],
  nektar: [FeatureFlag.showChangeFilter],
  ebsta: [FeatureFlag.showChangeFilter],
  fonoa: [FeatureFlag.showChangeFilter, FeatureFlag.v2CrmSync],
  cfi: [FeatureFlag.showChangeFilter],
  m3ter: [FeatureFlag.showChangeFilter],
  dixa: [FeatureFlag.showChangeFilter, FeatureFlag.showAlerts, FeatureFlag.v2CrmSync],
  'dev-qawolf': [FeatureFlag.showChangeFilter],
  storekit: [FeatureFlag.showChangeFilter],
  podfather: [FeatureFlag.showChangeFilter],
  'polar-analytics': [FeatureFlag.showChangeFilter],
  blueprint: [FeatureFlag.showChangeFilter, FeatureFlag.showAlerts],
  screenloop: [FeatureFlag.showChangeFilter],
  merge: [FeatureFlag.showChangeFilter, FeatureFlag.v2CrmSync],
  'dev-m3ter': [FeatureFlag.showChangeFilter],
  omnipresent: [FeatureFlag.showChangeFilter, FeatureFlag.v2CrmSync],
  'instant-commerce': [FeatureFlag.showChangeFilter],
  'dev-omnipresent': [FeatureFlag.showChangeFilter],
  chattermill: [FeatureFlag.showAlerts],
  outreach: [FeatureFlag.showAlerts],
  marketerhire: [FeatureFlag.showAlerts],
  bound: [],
  deepgram: [FeatureFlag.v2CrmSync],
  paytrix: [FeatureFlag.v2CrmSync],
  chilipiper: []
};

// The intention is, even if we change to a new feature flag system we can leave this function in place and
// alter its working
export function hasFeatureFlag(flag: FeatureFlag): boolean {
  // In the future when we redo auth we can make a better way to get the current account name
  const accountName = (window as any)?.goodfitAccountName as string;
  return !!(clientFeatureFlags[accountName] && clientFeatureFlags[accountName].includes(flag));
}
