import { Button, Progress, Table, Tag, Tooltip, Typography } from 'antd';
import _ from 'lodash';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { API } from 'aws-amplify';
import { stringTitleCase } from '../../libs/string';

type JobsTableProps = {
  selectedIntegration: string;
};

type IntegrationJobs = {
  jobsCnt: number;
  jobs: Array<{
    id: string;
    integrationType: string;
    started: string;
    status: string;
    progress: number;
    records: {
      numAccountsCreated: number;
      numAccountsUpdated: number;
      numAccountsFailed: number;
      numContactsCreated: number;
      numContactsUpdated: number;
      numContactsFailed: number;
    };
  }>;
};

export function JobsTable({ selectedIntegration }: JobsTableProps) {
  const [integrationJobs, setIntegrationJobs] = useState({} as IntegrationJobs);
  const [isLoading, setIsLoading] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [pageFilters, setPageFilters] = useState({
    currentPage: 1,
    limit: 10,
    selectedIntegration: selectedIntegration
  });
  const pageFiltersRef = useRef(pageFilters);

  useEffect(() => {
    pageFiltersRef.current = pageFilters;
    pageFiltersRef.current.selectedIntegration = selectedIntegration;
  }, [pageFilters, selectedIntegration]);

  const getIntegrationJobs = async (skipTableLoadIcon = false, the = false) => {
    skipTableLoadIcon || setIsLoading(true);
    try {
      const offset = (pageFiltersRef.current.currentPage - 1) * pageFiltersRef.current.limit;
      var integrationType = null;
      if (pageFiltersRef.current.selectedIntegration !== 'ALL') {
        integrationType = pageFiltersRef.current.selectedIntegration;
      }
      // @ts-ignore
      const jobs = await API.get(
        'integrations',
        `/jobs?offset=${offset}&limit=${pageFiltersRef.current.limit}${
          integrationType ? `&integrationType=${integrationType}` : ''
        }`
      );
      setIntegrationJobs(jobs);
    } finally {
      skipTableLoadIcon || setIsLoading(false);
    }
  };

  const cancelJob = async (jobId: string) => {
    setIsCancelling(true);
    try {
      // @ts-ignore
      await API.post('integrations', `/jobs/stop`, {
        body: { jobId }
      });
      await getIntegrationJobs();
    } finally {
      setIsCancelling(false);
    }
  };

  // @ts-ignore
  const handleOnTableChange = async (pagination, filters, sorter, extra) => {
    setPageFilters({
      currentPage: pagination.current,
      limit: pagination.pageSize,
      selectedIntegration: selectedIntegration
    });
  };

  useEffect(() => {
    getIntegrationJobs();
  }, [selectedIntegration, pageFilters]);

  // refresh jobs data every 10 seconds
  useEffect(() => {
    const interval = setInterval(async () => {
      await getIntegrationJobs(true);
    }, 10_000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Table
      size="small"
      onChange={handleOnTableChange}
      pagination={{ defaultPageSize: 10, total: integrationJobs?.jobsCnt }}
      dataSource={integrationJobs.jobs}
      rowKey="id"
      loading={isLoading}
      columns={[
        {
          title: 'Integration',
          dataIndex: 'integrationType',
          align: 'center',
          render: text => <Typography key={text}>{stringTitleCase(text)}</Typography>
        },
        {
          title: 'Autosync',
          dataIndex: 'autosync',
          align: 'center',
          render: value => {
            if (!_.isNil(value)) {
              if (value) {
                return <Typography>True</Typography>;
              } else {
                return <Typography>False</Typography>;
              }
            } else {
              return '-';
            }
          }
        },
        {
          title: 'Started',
          dataIndex: 'started',
          align: 'center',
          render: text => (
            <Typography.Text>
              {' '}
              {dayjs(text).format('YYYY-MM-DD')} {dayjs(text).format('HH:mm:ss')}
            </Typography.Text>
          )
        },
        {
          title: 'Status',
          dataIndex: 'status',
          align: 'center',
          render: (value, record) => {
            if (!_.isNil(value)) {
              if (value === 'COMPLETE') {
                return <Tag color="#87d068">{stringTitleCase(value)}</Tag>;
              } else if (value === 'FAILED' || value === 'CANCELLED') {
                return <Tag color="#f50">{stringTitleCase(value)}</Tag>;
              } else if (value === 'IN_PROGRESS') {
                return (
                  <>
                    <Tag color="cyan-inverse">In Progress</Tag>
                    <Button disabled={isCancelling} size={'small'} onClick={() => cancelJob(record.id)}>
                      Cancel
                    </Button>
                  </>
                );
              } else {
                return <Tag>{stringTitleCase(value)}</Tag>;
              }
            } else {
              return '-';
            }
          }
        },
        {
          title: 'Progress',
          dataIndex: 'progress',
          align: 'center',
          render: (value, record) => (
            <Tooltip
              color="white"
              // @ts-ignore
              title={
                value?.numAccountsToProcess ? (
                  <ul style={{ padding: 8, marginBottom: 0 }}>
                    <li>
                      <Typography>
                        Total Companies to process <b>{value?.numAccountsToProcess || 0}</b>
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        Companies created <b>{value?.numAccountsCreated || 0}</b>
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        Companies updated <b>{value?.numAccountsUpdated || 0}</b>
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        Companies failed <b>{value?.numAccountsFailed || 0}</b>
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        Contacts created <b>{value?.numContactsCreated || 0}</b>
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        Contacts updated <b>{value?.numContactsUpdated || 0}</b>
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        Contacts failed <b>{value?.numContactsFailed || 0}</b>
                      </Typography>
                    </li>
                  </ul>
                ) : (
                  <Typography>Preparing export...</Typography>
                )
              }
            >
              <Progress
                style={{ width: '80%' }}
                status={
                  record.status === 'COMPLETE'
                    ? 'success'
                    : record.status === 'CANCELLED' || record.status === 'FAILED'
                      ? 'exception'
                      : 'normal'
                }
                percent={Math.round(value?.progress * 100)}
              />
            </Tooltip>
          )
        }
      ]}
    ></Table>
  );
}
