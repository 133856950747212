import { CheckCircleFilled, WarningFilled } from '@ant-design/icons';
import { Button, Flex, Tooltip, Skeleton } from 'antd';
import { IntegrationFieldMappingInput } from '../types';
import { checkMapping, useDataDirectoryStore } from '../state';
import { stringTitleCase } from '../../../libs/string';

const updateStrategyLabels = {
  OVERWRITE_ALWAYS: 'Overwrite',
  OVERWRITE_ONLY_IF_EMPTY: 'Update if empty'
};

type FieldMappinDisplayRowProps = {
  mapping: IntegrationFieldMappingInput;
  recordType: string;
};
function FieldMappinDisplayRow({ mapping, recordType }: FieldMappinDisplayRowProps) {
  const updateStrategyLabel = (updateStrategyLabels as any)[mapping.updateStrategy || ''] || mapping.updateStrategy;

  const store = useDataDirectoryStore();
  const integration = store.integrations.find(i => i.id === mapping.integrationId);
  if (!mapping.externalName) return <></>;
  if (!integration) return <></>;

  const integrationProperties =
    (recordType === 'contact'
      ? store.integrationContactFieldDescriptions[mapping.integrationId]
      : store.integrationCompanyFieldDescriptions[mapping.integrationId]) || [];

  const isIntegrationPropertiesLoaded = mapping.integrationId in store.integrationCompanyFieldDescriptions;
  const integrationProperty = integrationProperties.find(prop => prop.id === mapping.externalName);

  const errorMessage = checkMapping(mapping);
  const isOk = !errorMessage;

  if (!isIntegrationPropertiesLoaded) {
    return (
      <Flex style={{ width: '100%' }} justify="initial" gap="small">
        <Button size="small" disabled={true} style={{ width: 110 }}>
          {stringTitleCase(integration.integrationType)}
        </Button>
        <Skeleton active paragraph={false} style={{ position: 'relative', top: 4, width: '100%' }}></Skeleton>
      </Flex>
    );
  }

  return (
    <Flex style={{ width: '100%', margin: '4px 0' }} justify="initial" gap="small">
      <Button size="small" disabled={true} style={{ width: 110 }}>
        {stringTitleCase(integration.integrationType)}
      </Button>
      {isOk ? (
        <Tooltip title="Mapping is configured correctly">
          <CheckCircleFilled style={{ color: 'green' }} />
        </Tooltip>
      ) : (
        <Tooltip title={`Mapping is not valid: ${errorMessage}`}>
          <WarningFilled style={{ color: 'red' }} />
        </Tooltip>
      )}
      <div style={{ width: '100%', height: '1.2em' }}>
        {integrationProperty?.label || mapping.externalName} ({updateStrategyLabel})
      </div>
    </Flex>
  );
}

type FieldMappingDisplayProps = {
  fieldName: string;
  mappings: IntegrationFieldMappingInput[];
  recordType: string;
};
export function FieldMappingDisplay({ fieldName, mappings, recordType }: FieldMappingDisplayProps) {
  return (
    <>
      <div>
        {mappings.map(mapping => (
          <FieldMappinDisplayRow recordType={recordType} key={mapping.id} mapping={mapping} />
        ))}
        {mappings.length === 0 && 'Not mapped'}
      </div>
    </>
  );
}
