import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Divider, Modal, Input, Button, Row, message } from 'antd';
import Filters from '../Filters/Filters';
import { useDispatch } from 'react-redux';
import { createSegment, deleteSegment, updateSegment } from '../../features/segments/segmentsSlice';

export default function SegmentModal({ visible, onCancel, segment }) {
  const dispatch = useDispatch();
  const [segmentName, setSegmentName] = useState();
  const [localFilters, setLocalFilters] = useState([]);

  useEffect(() => {
    if (segment.filters) {
      setLocalFilters(segment.filters);
    }
    setSegmentName(segment.name);
  }, [segment]);

  const onSaveSegment = () => {
    const newSegment = { ...segment, name: segmentName, filters: localFilters };

    if (segment.id) {
      if (!_.isEqual(segment, newSegment)) {
        dispatch(updateSegment(newSegment));
      }
    } else {
      dispatch(createSegment(newSegment));
    }

    message.success(`Talk track has been saved succesfully!`);

    onCancel();
  };

  const onDeleteSegment = () => {
    dispatch(deleteSegment(segment));
    message.info(`Talk track has been deleted succesfully!`);
    onCancel();
  };

  return (
    <Modal
      visible={visible}
      title={`${segment.id ? 'Update' : 'Create'}  Talk Track`}
      okText="Save"
      onOk={() => onSaveSegment(segmentName)}
      onCancel={() => onCancel()}
      width={900}
    >
      <Input
        value={segmentName}
        placeholder="Type Talk Track Name..."
        onChange={e => {
          setSegmentName(e.target.value);
        }}
      />
      <Divider />
      <Filters filters={localFilters} setFilters={setLocalFilters} />
      {segment.id && (
        <>
          <Divider />
          <Row align="middle" justify="center">
            <Button danger style={{ alignItems: 'center' }} onClick={() => onDeleteSegment()}>
              Delete
            </Button>
          </Row>
        </>
      )}
    </Modal>
  );
}
