import { Col, Row } from "antd";
import styled from "styled-components";
import logo from "../../images/goodfit-logo.svg";
import { Link } from "react-router-dom";

const Header = styled.header`
  height: 64px;
  max-width: 1200px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  justify-content: center;
`;

export default function GenericNavBar({ children, settings }) {
  return (
    <Header>
      <Row gutter={16} align="middle" style={{ height: "100%" }}>
        <Col span={2}>
          <Link to="/">
            <img src={logo} height={30} alt="GoodFit"></img>
          </Link>
        </Col>
        <Col span={20}>
          <Row justify="center" flex="auto">
            {children}
          </Row>
        </Col>
        <Col span={2}>
          <Row justify="end">
            {settings}
          </Row>
        </Col>
      </Row>
    </Header>
  );
}
