export const RULE_ALLOWED_TYPES = ['string', 'number', 'boolean', 'date', 'percentage', 'currency', 'array'];

export const RULE_OPERATORS = [
  {
    id: 'stringEquals',
    forTypes: ['string'],
    label: 'Equal to',
    hasValue: true,
    hasArrayValue: true
  },
  {
    id: 'stringNotEquals',
    forTypes: ['string'],
    label: 'Not equal to',
    hasValue: true,
    hasArrayValue: true
  },
  {
    id: 'stringContainsSubstring',
    forTypes: ['string'],
    label: 'Contains text',
    hasValue: true
  },
  {
    id: 'stringNotContainsSubstring',
    forTypes: ['string'],
    label: 'Does not contain text',
    hasValue: true
  },
  {
    id: 'numberGte',
    forTypes: ['number', 'percentage', 'currency'],
    label: '>=',
    hasValue: true
  },
  {
    id: 'numberLte',
    forTypes: ['number', 'percentage', 'currency'],
    label: '<=',
    hasValue: true
  },
  {
    id: 'numberGt',
    forTypes: ['number', 'percentage', 'currency'],
    label: '>',
    hasValue: true
  },
  {
    id: 'numberLt',
    forTypes: ['number', 'percentage', 'currency'],
    label: '<',
    hasValue: true
  },
  {
    id: 'numberBetween',
    forTypes: ['number', 'percentage', 'currency'],
    label: 'Between',
    hasValue: true
  },
  {
    id: 'numberEquals',
    forTypes: ['number', 'percentage', 'currency'],
    label: '=',
    hasValue: true
  },
  {
    id: 'numberNotEquals',
    forTypes: ['number', 'percentage', 'currency'],
    label: '!=',
    hasValue: true
  },

  {
    id: 'booleanTrue',
    forTypes: ['boolean'],
    label: 'Yes',
    hasValue: false
  },
  {
    id: 'booleanFalse',
    forTypes: ['boolean'],
    label: 'No',
    hasValue: false
  },
  {
    id: 'exists',
    forTypes: ['string', 'number', 'boolean', 'date', 'percentage', 'currency', 'array'],
    label: 'Is set',
    hasValue: false
  },
  {
    id: 'notExists',
    forTypes: ['string', 'number', 'boolean', 'date', 'percentage', 'currency', 'array'],
    label: 'Is not set',
    hasValue: false
  },
  {
    id: 'dateLt',
    forTypes: ['date'],
    label: 'Before',
    hasValue: true
  },
  {
    id: 'dateGt',
    forTypes: ['date'],
    label: 'After',
    hasValue: true
  },
  {
    id: 'listContains',
    forTypes: ['array'],
    label: 'Contains item',
    hasValue: true,
    hasArrayValue: true
  },
  {
    id: 'listNotContains',
    forTypes: ['array'],
    label: 'Does not contain item',
    hasValue: true,
    hasArrayValue: true
  },
  {
    id: 'listLengthGte',
    forTypes: ['array'],
    label: 'Has at least N items',
    hasValue: true
  },
  {
    id: 'listLengthLt',
    forTypes: ['array'],
    label: 'Has less than N items',
    hasValue: true
  }
];

export function getOperatorsForType(fieldType: string) {
  if (!fieldType) return [];
  return RULE_OPERATORS.filter((op: any) => op.forTypes.includes(fieldType));
}
