import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Row, Button, Empty } from 'antd';

import { ScoringRule } from './types';
import { ScoringRuleItem } from './rules/ScoringRuleItem';

type ScoringRulesProperties = {
  rules: ScoringRule[];
  setRules: Function;
  onFieldStatsClicked: Function;
};
export function ScoringRules({ rules, setRules, onFieldStatsClicked }: ScoringRulesProperties) {
  const mutateRules = (id: any, newRule: ScoringRule) => {
    const newRules = rules.map(rule => {
      return rule.id === id ? newRule : rule;
    });
    setRules(newRules);
  };
  const removeRule = (id: string) => {
    const newRules = rules.filter(rule => rule.id !== id);
    setRules(newRules);
  };
  const addRule = () => {
    setRules(
      rules.concat([
        {
          id: uuidv4(),
          score: 5
        }
      ])
    );
  };

  if (rules.length === 0) {
    return (
      <Empty description="No scoring rules defined">
        <p>Find out about how scoring works:
          <a
            type="link"
            target="_blank"
            href="https://docs.goodfit.io/product-docs/guides/prioritisation/how-to-build-your-first-score"
            style={{ padding: "0 5px" }}
          >
            Scoring documentation
          </a>
        </p>
        <Button onClick={addRule} type="primary">
          Create your first rule
        </Button>
      </Empty>
    );
  }

  return (
    <>
      <ScoringRulesList
        rules={rules}
        mutateRules={mutateRules}
        removeRule={removeRule}
        onFieldStatsClicked={onFieldStatsClicked}
      />
      <Row>
        <Button style={{ width: '100%' }} size="small" type="dashed" onClick={addRule}>
          + Add rule
        </Button>
      </Row>
    </>
  );
}

function ScoringRulesList({ rules, mutateRules, removeRule, onFieldStatsClicked }: any) {
  if (!rules.length) {
    return <div>No rules defined</div>;
  }
  return rules.map((rule: ScoringRule) => (
    <ScoringRuleItem
      key={rule.id}
      rule={rule}
      mutateRules={mutateRules}
      removeRule={removeRule}
      onFieldStatsClicked={onFieldStatsClicked}
    />
  ));
}
