import { Slider } from 'antd';

type Colour = {
  r: number;
  b: number;
  g: number;
};

function interpolateColours(a: Colour, b: Colour, c: Colour, valueRaw: number): Colour {
  let value = valueRaw;
  if (value < -1) value = -1;
  if (value > 1) value = 1;
  let c1 = a;
  let c2 = c;
  value = value / 2 + 0.5;
  return {
    r: Math.round(c1.r + (c2.r - c1.r) * value),
    b: Math.round(c1.b + (c2.b - c1.b) * value),
    g: Math.round(c1.g + (c2.g - c1.g) * value)
  };
}

function colourToCss(c: Colour): string {
  return `rgb(${c.r},${c.g},${c.b})`;
}

const COLOUR_NEGATIVE = { r: 255, g: 77, b: 79 };
const COLOUR_NEUTRAL = { r: 200, g: 200, b: 200 };
const COLOUR_POSITIVE = { r: 82, g: 220, b: 26 };

const tooltipFormatter = (v: any) => {
  if (v === 0) {
    return `${v} Neutral`;
  }
  if (v <= -8) {
    return `${v} Very negative impact`;
  }
  if (v <= -4) {
    return `${v} Negative impact`;
  }
  if (v <= -1) {
    return `${v} Somewhat negative impact`;
  }
  if (v >= 8) {
    return `${v} Very positive impact`;
  }
  if (v >= 5) {
    return `${v} Positive impact`;
  }
  if (v >= 1) {
    return `${v} Somewhat positive impact`;
  }
};

export function ScoringRuleSlider({ value, maxRange, updateScore, onValueDrag }: any) {
  const colour = interpolateColours(COLOUR_NEGATIVE, COLOUR_NEUTRAL, COLOUR_POSITIVE, value / 10);

  // Custom logic around center the slider background and change colour
  const ww = (Math.abs(value) / maxRange) * 50;
  const cssProps = {
    width: `${ww}%`,
    left: value >= 0 ? '50%' : `${50 - ww}%`,
    backgroundColor: colourToCss(colour),
    //backgroundColor: value >= 0 ? '#00cc00' : '#ff8800',
    [value < 0 ? 'borderRight' : 'borderLeft']: '1px solid #888',
    borderRadius: 0
  };

  return (
    <Slider
      onAfterChange={updateScore}
      onChange={onValueDrag}
      value={value}
      min={-maxRange}
      max={maxRange}
      trackStyle={cssProps}
      tooltip={{ formatter: tooltipFormatter, placement: 'top' }}
    />
  );
}
