import Select from "react-select";
import React from "react";


const SearchableSelectWidget = function(props) {
  return (
    <Select
      className={"searchable-select"}
      options={props.options.enumOptions}
      classNamePrefix={"searchable-select"}
      onChange={(e) => props.onChange(e.value)}
      defaultValue={props.options.enumOptions.find(option => option.value === props.value)}
    />
  );
};

export default SearchableSelectWidget;