import React, { useState, useEffect } from 'react';
import SettingsWrapper from '../components/SettingsWrapper';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-kuroir';
import { API } from 'aws-amplify';
import { onError } from '../libs/errorLib';
import { Button } from 'antd';

export default function SettingsSchema() {
  const [schema, setSchema] = useState('');

  useEffect(() => {
    async function onLoad() {
      try {
        const schema = await getSchema();
        setSchema(JSON.stringify(schema, null, '\t'));
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, []);

  function getSchema() {
    return API.get('companies', '/schema');
  }

  function updateSchema() {
    return API.put('companies', '/schema', {
      body: JSON.parse(schema)
    });
  }

  function onChange(newValue) {
    setSchema(newValue);
  }

  return (
    <SettingsWrapper title="Schema">
      <AceEditor value={schema} onChange={onChange} width="" theme="kuroir" />
      <Button onClick={updateSchema}>Save</Button>
    </SettingsWrapper>
  );
}
