import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router'

import { Auth } from 'aws-amplify';
import { Button, Form, Input, message, Col, Row } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import AuthContainer from '../components/Auth/AuthContainer';
import { validateNewPassword } from '../libs/auth';

const validateVerificationCode = (rule, value) => {
  const isValid = value.match(/[0-9]{6}/);

  return isValid ? Promise.resolve() : Promise.reject("Code must be a 6 digit number.");
};

export default function PasswordResetConfirm() {

  const routerHistory = useHistory();

  const userEmail = (new URL(document.location)).searchParams.get('email');
  // Redirect to start of password reset process if no ?code parameter
  useEffect(() => {
    if (!userEmail) {
      message.error('Please request a new password reset code');
      routerHistory.push('/password-reset');
    }
  }, [userEmail, routerHistory]);

  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValid, setIsValid] = useState(false);


  const handleFormChange = (ev) => {
    setIsValid(form.isFieldsTouched(true) && form.getFieldsError().filter(({ errors }) => errors.length).length === 0);
  };

  async function handleSubmit() {
    const newPassword = form.getFieldValue('newPasswordFormItem');
    const verificationCode = form.getFieldValue('verificationCodeFormItem');
    console.log('CHANGE' ,[userEmail, newPassword, verificationCode]);
    setIsSubmitting(true);
    try {
      await Auth.forgotPasswordSubmit(userEmail, verificationCode, newPassword);
      message.success('Password successfully changed. You can now login.');
      setIsSubmitting(false);
      routerHistory.push('/login');
    } catch (err) {
      console.error('ERROR', err);
      if (err.name==='ExpiredCodeException' || err.name==='LimitExceededException' || err.name==='CodeMismatchException') {
        message.error(err.message);
      } else {
        message.error('There was en error changing your password. Please contact support.');
      }
      setIsSubmitting(false);
    }
  }

  return (
    <AuthContainer>
      <p style={{textAlign:'center'}}>
        Please set a new password
      </p>
      <Form
        validateTrigger={['onBlur']}
        form={form}
        onFinish={handleSubmit}
        onFieldsChange={handleFormChange}
      >
        <Form.Item name="verificationCodeFormItem" rules={[{ validator: validateVerificationCode }]}>
        <Input
            name="verificationCode"
            size="large"
            placeholder="Verification code"
            autocomplete="never"
        />
        </Form.Item>
        <Form.Item name="newPasswordFormItem" rules={[{ validator: validateNewPassword }]}>
        <Input.Password
            name="newPassword"
            size="large"
            placeholder="New Password"
            autocomplete="new-password"
            prefix={<LockOutlined />}
        />
        </Form.Item>
        <Form.Item
        name="confirmNewPasswordFormItem"
        dependencies={['newPasswordFormItem']}
        rules={[
            ({ getFieldValue }) => ({
            validator(_, value) {
                if (!value || getFieldValue('newPasswordFormItem') === value) {
                return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
            }
            })
        ]}
        >
        <Input.Password
            name="confirmNewPassword"
            size="large"
            placeholder="Confirm New Password"
            autocomplete="new-password"
            prefix={<LockOutlined />}
        />
        </Form.Item>
        <Row align='middle' justify='space-between'>
          <Col>
            <Form.Item style={{ marginBottom: '0' }}>
              <Button
                disabled={!isValid}
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
              >
                Change password
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <a href="/password-reset">Cancel</a>
          </Col>
          </Row>
      </Form>
    </AuthContainer>
  );
}
