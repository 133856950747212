import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Alert, Card, Switch } from 'antd';
import { API } from 'aws-amplify';

import AppSiteWrapper from '../../components/SiteBasics/AppSiteWrapper';
import { SourcingCriteriaDisplay } from './SourcingCriteriaDisplay';
import { FieldTable } from './FieldTable/FieldTable';
import { useDataDirectoryStore, DataDirectoryStore } from './state';

function calculateNumberOfUnmappedFields(store: DataDirectoryStore): {
  numUnmappedContactFields: number;
  numUnmappedCompanyFields: number;
} {
  let numUnmappedContactFields = 0;
  let numUnmappedCompanyFields = 0;

  for (const entry of store.dataDirectoryCompanyFields) {
    if (entry.mappings.length === 0 && !entry.isSystem) {
      numUnmappedCompanyFields++;
    }
  }
  for (const entry of store.dataDirectoryContactFields) {
    if (entry.mappings.length === 0 && !entry.isSystem) {
      numUnmappedContactFields++;
    }
  }

  return { numUnmappedCompanyFields, numUnmappedContactFields };
}

function calculateUnmappedFieldsWarning({ numUnmappedCompanyFields, numUnmappedContactFields }: any) {
  if (numUnmappedCompanyFields === 0 && numUnmappedContactFields === 0) return null;
  const parts = [];
  if (numUnmappedCompanyFields > 0) parts.push(`${numUnmappedCompanyFields} company`);
  if (numUnmappedContactFields > 0) parts.push(`${numUnmappedContactFields} contact`);
  return `You have ${parts.join(
    ' and '
  )} fields currently unmapped and therefore not synced to your CRM. You can add mappings to connect this data to your CRM below.`;
}

export function DataDirectory() {
  const [isEditingCompanies, setIsEditingCompanies] = useState<boolean>(false);
  const [isEditingContacts, setIsEditingContacts] = useState<boolean>(false);
  const store = useDataDirectoryStore();

  const unmappedFieldsMessage = calculateUnmappedFieldsWarning(calculateNumberOfUnmappedFields(store));

  useEffect(() => {
    store.loadDataDirectory();
  }, []);

  return (
    <AppSiteWrapper title="Data Directory">
      <Card title="Company Criteria" style={{ marginBottom: 16 }} loading={store.isDataDirectoryLoading}>
        <SourcingCriteriaDisplay
          sourcingCriteria={store?.sourcingCriteria}
          fieldDefinitions={store?.dataDirectoryCompanyFields}
        ></SourcingCriteriaDisplay>
      </Card>
      {unmappedFieldsMessage && (
        <Alert style={{ margin: '0 0 15px 0' }} type="warning" message={unmappedFieldsMessage} />
      )}
      <Card
        bodyStyle={{ padding: 0 }}
        title="Company field Definitions"
        extra={
          <>
            Edit mappings <Switch checked={isEditingCompanies} onChange={value => setIsEditingCompanies(value)} />
          </>
        }
      >
        <FieldTable
          isEditing={isEditingCompanies}
          fieldDefinitions={store.dataDirectoryCompanyFields}
          recordType="company"
          isLoading={store.isDataDirectoryLoading}
        />
      </Card>
      <br />
      {store.isContactsEnabled && (
        <Card
          bodyStyle={{ padding: 0 }}
          title="Contact field Definitions"
          extra={
            <>
              Edit mappings <Switch checked={isEditingContacts} onChange={value => setIsEditingContacts(value)} />
            </>
          }
        >
          <FieldTable
            isEditing={isEditingContacts}
            fieldDefinitions={store.dataDirectoryContactFields}
            recordType="contact"
            isLoading={store.isDataDirectoryLoading}
          />
        </Card>
      )}
    </AppSiteWrapper>
  );
}
