import { Card } from 'antd';
import React, { useState } from 'react';
import AppSiteWrapper from '../components/SiteBasics/AppSiteWrapper';
import SegmentPage from '../components/Segments/SegmentPage';
import AlertsPage from '../components/Alerts/AlertsPage';
import { hasFeatureFlag, FeatureFlag } from '../featureFlags';

export default function SegmentsAlerts() {
  const [activeTab, setActiveTab] = useState('segments');
  const tabContent = {
    segments: <SegmentPage />,
    alerts: <AlertsPage />
  };

  const shouldShowAlerts = hasFeatureFlag(FeatureFlag.showAlerts);


  return (
    <AppSiteWrapper title="Talk tracks">
      <Card
        tabList={[
          { key: 'segments', tab: 'Talk Tracks' },
          shouldShowAlerts && { key: 'alerts', tab: 'Alerts' }
        ]}
        tabProps={{ centered: true }}
        activeTabKey={activeTab}
        onTabChange={key => setActiveTab(key)}
      >
        {tabContent[activeTab]}
      </Card>
    </AppSiteWrapper>
  );
}
