import React from 'react';
import { Card, Col, Row, Switch, Tag } from 'antd';
import { Integration } from '../DataDirectory2/types';
import { API } from 'aws-amplify';

type ConnectorCardProps = {
  integration: Integration;
  connectorSetting: {
    imgUrl: string;
    connectElement: (connected: boolean) => React.ReactNode;
  };
  refreshIntegrations: () => Promise<void>;
};

export default function ConnectorCard({ integration, connectorSetting, refreshIntegrations }: ConnectorCardProps) {
  const [isToggling, setIsToggling] = React.useState(false);

  const toggleAutoSync = async () => {
    setIsToggling(true);
    await API.post('integrations', `/${integration.id}/`, {
      body: {
        enableAutoSync: !integration.enableAutoSync
      }
    });
    await refreshIntegrations();
    setIsToggling(false);
  };

  return (
    <Card style={{ width: 300 }}>
      <Row justify="center" align="middle">
        <Col span={12}>
          <img src={connectorSetting.imgUrl} height={100} alt={integration.integrationType} />
        </Col>
        <Col span={12}>
          <>
            <Row justify="center" align="middle" style={{ marginBottom: 8 }}>
              {integration.id ? (
                <Tag color="green" style={{ marginBottom: 8 }}>
                  Connected
                </Tag>
              ) : (
                <Tag color="blue" style={{ marginBottom: 8 }}>
                  Not Connected
                </Tag>
              )}
              {integration.hasCredentials ? (
                <>
                  <Switch
                    checkedChildren="Auto Sync"
                    unCheckedChildren="Auto Sync"
                    checked={integration.enableAutoSync}
                    onChange={toggleAutoSync}
                    loading={isToggling}
                    style={
                      integration.enableAutoSync ? { backgroundColor: '#87d068', marginBottom: 8 } : { marginBottom: 8 }
                    }
                  />
                  {connectorSetting.connectElement(true)}
                </>
              ) : (
                <>{connectorSetting.connectElement(false)}</>
              )}
            </Row>
          </>
        </Col>
      </Row>
    </Card>
  );
}
