import { Modal, message, Input } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateConnectorAPI } from '../../../features/connectors/connectorsSlice';

export default function PipedriveAPIModal({ visible, onCancel }) {
  const [apiKey, setApiKey] = useState();
  const dispatch = useDispatch();

  const saveAPIKey = async () => {
    try {
      dispatch(updateConnectorAPI('pipedrive', { credentials: { apiKey }, enabled: true, connected: true }));
      message.success('API Key successfully saved. Refresh the page to start configuration.');
      onCancel();
    } catch (err) {
      message.error('Something went wrong');
    }
  };

  return (
    <Modal visible={visible} onCancel={onCancel} title="Add your API Key " okText="Save" onOk={saveAPIKey}>
      <Input.Password placeholder="Input Your API Key" onChange={e => setApiKey(e.target.value)} />
    </Modal>
  );
}
