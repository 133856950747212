import { Col, Select } from 'antd';
import React from 'react';

export default function FilterSelector({ value, setValue, options, placeholder, width }) {
  return (
    <Col>
      <Select
        showSearch
        value={value}
        placeholder="Select Field"
        style={{ minWidth: '150px' }}
        onSelect={val => setValue(val)}
        size="small"
        dropdownMatchSelectWidth={false}
        dropdownStyle={{ minWidth: width }}
      >
        {options?.map(option => (
          <Select.Option key={option} value={option}>
            {option}
          </Select.Option>
        ))}
      </Select>
    </Col>
  );
}
