import TableWrapper from "../../CompaniesTable/TableWrapper";
import React, { useEffect, useState } from "react";
import { Alert, Button, List, Space, Typography } from "antd";
import { useSelector } from "react-redux";
import MarketEstimationsForm from "./MarketEstimationsForm";
import { parseCompanySchema } from "../../../features/companies/companiesSlice";


const schema = parseCompanySchema({
  "domain": { "type": "string", "hashkey": true },
  "linked_in_url": "string",
  "country": "string",
  "employee_count": "number"
});

const MarketEstimations = () => {
  const { marketEstimations } = useSelector(state => state);
  const [sql, setSql] = useState("");

  useEffect(() => {
    if (marketEstimations.result.sqlFragment) {
      let sql = marketEstimations.result.sqlFragment.sql;
      let values = marketEstimations.result.sqlFragment.params;
      for (let i = 0; i < values.length; i++) {
        sql = sql.replace("?", typeof values[i] === "string" ? `'${values[i]}'` : values[i]);
      }
      setSql(sql);
    }
  }, [marketEstimations.result]);

  return (
    <Space direction={"vertical"}>
      <h3>Sourcing Criteria</h3>
      <MarketEstimationsForm />
      {marketEstimations.result && !marketEstimations.result.isValid &&
        <Alert
          type="error"
          message="The query you were running returned following errors. Please send a screenshot of this page to the engineering"
          description={
            <List
              bordered
              dataSource={marketEstimations.result?.errors}
              renderItem={(item) => (
                <List.Item>
                  path: "{item.instancePath}" "{item.message} "{JSON.stringify(item.data)}"
                </List.Item>
              )} />
          }
        >
        </Alert>
      }
      <Typography>Found {marketEstimations.result?.companyCount || "No"} companies</Typography>
      <div />
      <div style={{ width: "800px" }}>
        <TableWrapper
          schema={schema}
          entities={!marketEstimations.isFetching && marketEstimations.result?.sample || []}
          loading={marketEstimations.isFetching}
        />
      </div>
      <Button type="primary" disabled={marketEstimations.isFetching || !sql}
              onClick={() => {
                navigator.clipboard.writeText(sql);
              }}
      >Copy SQL</Button>
    </Space>
  );
};


export default MarketEstimations;