import { Button, Card, Col, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import _ from 'lodash';
import EnumTag from './EnumTag';
import { filterTypeOptions, predicateOptions } from './config';
import { CloseOutlined } from '@ant-design/icons';
import PropertyFilter from './PropertyFilter';
import GroupFilter from './GroupFilter';
import ChangeFilter from './ChangeFilter';
import { v4 as uuidv4 } from 'uuid';
import ContactFilter from './ContactFilter';
import { hasFeatureFlag, FeatureFlag } from '../../featureFlags';
import { ReloadOutlined } from '@ant-design/icons';

export default function Filters({ filters, setFilters }) {
  const updateFilter = (id, operation) => {
    const newFilters = filters.map(filter => {
      return filter.id === id ? { ...filter, ...operation(filter) } : filter;
    });

    setFilters(newFilters);
  };

  const removeFilter = id => {
    const newFilters = filters.filter(filter => filter.id !== id);
    setFilters(newFilters);
  };

  const addFilter = () => {
    setFilters(
      filters.concat([
        {
          id: uuidv4(),
          type: _.last(filters)?.type ?? 'property',
          statement: {},
          predicate: !_.isEmpty(filters) && 'AND'
        }
      ])
    );
  };

  return (
    <Card
      style={{ marginBottom: !_.isEmpty(filters) && 16 }}
      bodyStyle={{ padding: _.isEmpty(filters) ? 0 : 24 }}
      bordered={!_.isEmpty(filters)}
    >
      {filters.map(filter => {
        return (
          <React.Fragment key={filter.id}>
            <Filter filter={filter} updateFilter={updateFilter} removeFilter={removeFilter} />
          </React.Fragment>
        );
      })}

      <Button size="small" icon={<PlusOutlined />} style={{ width: '100%' }} type="dashed" onClick={() => addFilter()}>
        Add Filter
      </Button>
    </Card>
  );
}

const Filter = ({ filter, updateFilter, removeFilter }) => {
  const filterMapping = {
    property: <PropertyFilter filter={filter} updateFilter={updateFilter} />,
    change: <ChangeFilter filter={filter} updateFilter={updateFilter} />,
    group: <GroupFilter filter={filter} updateFilter={updateFilter} />,
    contact: <ContactFilter filter={filter} updateFilter={updateFilter} />
  };

  const actualFilterTypeOptions = [...filterTypeOptions];
  // Only show 'change' filters if enabled via feature flag
  // (reason being its unreliable and we want new clients to not see it)
  const shouldShowChangeFilter = hasFeatureFlag(FeatureFlag.showChangeFilter);
  if (shouldShowChangeFilter) {
    actualFilterTypeOptions.push({
      name: 'change',
      color: 'blue',
      icon: <ReloadOutlined />
    });
  }

  return (
    <Row gutter={8} style={{ marginBottom: 16 }}>
      {filter.predicate && (
        <EnumTag
          value={filter.predicate}
          setValue={val => updateFilter(filter.id, filter => _.set(_.cloneDeep(filter), 'predicate', val))}
          options={predicateOptions}
        />
      )}
      <EnumTag
        value={filter.type}
        setValue={val =>
          updateFilter(filter.id, filter => ({
            ..._.cloneDeep(filter),
            type: val,
            statement: {}
          }))
        }
        options={actualFilterTypeOptions}
      />
      {filterMapping[filter.type]}
      <Col>
        <CloseOutlined onClick={() => removeFilter(filter.id)} />
      </Col>
    </Row>
  );
};
