import { Col, Row, Card, Button, Typography, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AlertModal from './AlertModal';

export default function AlertsPage() {
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState({});
  const { alerts, isFetching } = useSelector(state => state.segments);

  return (
    <Row gutter={16}>
      {isFetching ? (
        <Spin
          style={{
            margin: 0,
            top: '70%',
            left: '50%',
            position: 'absolute'
          }}
        />
      ) : (
        <>
          {alerts.map((alert, index) => (
            <Col key={index} span={8} style={{ marginTop: 16 }}>
              <Card
                hoverable
                onClick={() => {
                  setSelectedAlert(alert);
                  setShowAlertModal(true);
                }}
                style={{
                  height: '10vh',
                  textAlign: 'center'
                }}
                bodyStyle={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Typography.Text strong>{alert.name}</Typography.Text>
              </Card>
            </Col>
          ))}
          <Col span={8} style={{ marginTop: 16 }}>
            <Button
              type="dashed"
              block
              style={{ height: '10vh' }}
              onClick={() => {
                setSelectedAlert({});
                setShowAlertModal(true);
              }}
              icon={<PlusOutlined />}
            >
              Create Alert
            </Button>
          </Col>
        </>
      )}
      <AlertModal visible={showAlertModal} alert={selectedAlert} onCancel={() => setShowAlertModal(false)} />
    </Row>
  );
}
