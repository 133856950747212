import { Drawer, Space, Table, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import dayjs from 'dayjs';
import { ErrorRecord } from './ConnectorLogsTNG';
import { mapError } from './errorMaps';
import { stringTitleCase } from '../../libs/string';

type FieldDrawerProps = {
  selectedErrorRecord: ErrorRecord;
  selectedIntegration: string | null;
  open: boolean;
  onClose: Function;
};

type ErrorDetails = {
  recordType: string;
  integrationId: string;
  integrationType: string;
  errorCode: string;
  originalMessage: string;
  field: string;
  value: string;
  record: string;
  lastSyncAttempt: Date;
};

export function ErrorDetailsDrawer({ selectedErrorRecord, selectedIntegration, open, onClose }: FieldDrawerProps) {
  const [errorDetails, setErrorDetails] = useState([] as ErrorDetails[]);
  const [isLoading, setIsLoading] = useState(false);

  const getIntegrationJobsErrors = async (currentPage = 1, limit = 10) => {
    setErrorDetails([]);
    if (!selectedErrorRecord?.errorCode) return;
    setIsLoading(true);
    const offset = (currentPage - 1) * limit;
    var integrationType = null;
    if (selectedErrorRecord.integrationType !== 'ALL') {
      integrationType = selectedErrorRecord.integrationType;
    }
    setErrorDetails(
      // @ts-ignore
      (
        await API.get(
          'integrations',
          `/errors/details?errorCode=${selectedErrorRecord?.errorCode}&recordType=${
            selectedErrorRecord.recordType
          }&field=${selectedErrorRecord.field}&offset=${offset}&limit=${limit}${
            integrationType ? `&integrationType=${integrationType}` : ''
          }`,
          {}
        )
      )?.errorDetails
    );
    setIsLoading(false);
  };

  // @ts-ignore
  const handleOnTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'paginate') getIntegrationJobsErrors(pagination.current, pagination.pageSize);
  };

  useEffect(() => {
    getIntegrationJobsErrors();
  }, [selectedIntegration, selectedErrorRecord]);

  return (
    <Drawer
      width={900}
      open={open}
      placement="right"
      title={`Error: ${
        isLoading ? '' : mapError(errorDetails?.[0]?.errorCode, errorDetails?.[0]?.originalMessage, errorDetails?.[0]?.field)?.shortDescription
      }`}
      onClose={() => onClose()}
    >
      <Space direction={'vertical'} size={'large'}>
        <Typography.Text>
          {isLoading
            ? ''
            : mapError(errorDetails?.[0]?.errorCode, errorDetails?.[0]?.originalMessage, errorDetails?.[0]?.field)
                ?.longDescription || ''}
        </Typography.Text>
        <Typography.Title level={5}>Instances of error</Typography.Title>
      </Space>
      <Table
        size="small"
        scroll={{ x: true }}
        onChange={handleOnTableChange}
        pagination={{ defaultPageSize: 10, total: selectedErrorRecord?.errorCount }}
        dataSource={errorDetails.map(error => ({
          ...error,
          key: `${error.integrationId}-${error.recordType}-${error.record}`
        }))}
        loading={isLoading}
        columns={[
          {
            title: 'Type',
            dataIndex: 'recordType',
            align: 'center',
            render: text => (
              <Typography
                style={{ maxWidth: 300, overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
              >
                {stringTitleCase(text)}
              </Typography>
            )
          },
          {
            title: 'Record',
            dataIndex: 'record',
            align: 'center',
            ellipsis: {
              showTitle: true
            },
            render: text => (
              <Tooltip
                placement="top"
                title={text}
                style={{ maxWidth: 300, overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
              >
                <Typography>{text || 'N/A'}</Typography>
              </Tooltip>
            )
          },
          {
            title: 'Field',
            dataIndex: 'field',
            align: 'center',
            ellipsis: {
              showTitle: true
            },
            render: text => (
              <Tooltip placement="top" title={text}>
                <Typography
                  style={{ maxWidth: 300, overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                >
                  {text || 'N/A'}
                </Typography>
              </Tooltip>
            )
          },
          {
            title: 'Value',
            dataIndex: 'value',
            align: 'center',
            ellipsis: {
              showTitle: true
            },
            render: text => (
              <Tooltip placement="top" title={text} style={{ maxWidth: 100, overflow: 'scroll' }}>
                <Typography
                  style={{ maxWidth: 300, overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                >
                  {text || 'N/A'}
                </Typography>
              </Tooltip>
            )
          },
          {
            title: 'Timestamp',
            dataIndex: 'lastSyncAttempt',
            align: 'center',
            render: text => (
              <Typography.Text
                style={{ maxWidth: 300, overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
              >
                {dayjs(text).format('YYYY-MM-DD')} {dayjs(text).format('HH:mm:ss')}
              </Typography.Text>
            )
          }
        ]}
      />
    </Drawer>
  );
}
