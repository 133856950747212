import { Col, Row, Card, Button, Typography, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import React, { useState } from 'react';
import SettingsWrapper from '../components/SettingsWrapper';
import SegmentModal from '../components/Segments/SegmentModal';
import { useSelector } from 'react-redux';

export default function SegmentsSettings() {
  const [showSegmentModal, setShowSegmentModal] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState({});
  const { segments, isFetching } = useSelector(state => state.segments);

  return (
    <SettingsWrapper>
      {/* <Typography.Title level={4}>Segments</Typography.Title> */}
      <Row gutter={16}>
        {isFetching ? (
          <Spin
            style={{
              margin: 0,
              top: '70%',
              left: '50%',
              position: 'absolute'
            }}
          />
        ) : (
          <>
            {segments.map(segment => (
              <Col span={8} style={{ marginTop: 16 }}>
                <Card
                  hoverable
                  onClick={() => {
                    setSelectedSegment(segment);
                    setShowSegmentModal(true);
                  }}
                  style={{
                    textAlign: 'center',
                    width: 'inherit'
                  }}
                  bodyStyle={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Typography.Paragraph strong>{segment.name}</Typography.Paragraph>
                </Card>
              </Col>
            ))}
            <Col span={8} style={{ marginTop: 16 }}>
              <Button
                type="dashed"
                block
                style={{ height: '10vh' }}
                onClick={() => {
                  setSelectedSegment({});
                  setShowSegmentModal(true);
                }}
                icon={<PlusOutlined />}
              >
                Create Segment
              </Button>
            </Col>
          </>
        )}
      </Row>
      <SegmentModal visible={showSegmentModal} segment={selectedSegment} onCancel={() => setShowSegmentModal(false)} />
    </SettingsWrapper>
  );
}
