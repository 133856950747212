import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Table, Card, Avatar, Typography, Tag } from 'antd';
import { ScoringRule } from './types';
import { SortOrder } from 'antd/lib/table/interface';
import ArrayCell from '../../components/CompaniesTable/ArrayCell';
import { FieldMultiSelect } from './rules/FieldMultiSelect';

/**
 * Hack! When using antd table and scroll:x and sticky, you occastionally get an error "ResizeObserver loop limit exceeded"
 * Open issue suggests ignoring it like below https://github.com/ant-design/ant-design/issues/23246
 * This only affects the dev env, as its just hiding the webpack warning
 * Flipping antd
 */
window.addEventListener('error', e => {
  if (e.message?.includes('ResizeObserver') || e.message === 'Script error.') {
    const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
    const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
    if (resizeObserverErr) {
      resizeObserverErr.setAttribute('style', 'display: none');
    }
    if (resizeObserverErrDiv) {
      resizeObserverErrDiv.setAttribute('style', 'display: none');
    }
  }
});

const CELL_RENDERERS: Record<any, Function> = {
  boolean: (value: boolean) => {
    if (!_.isNil(value)) {
      if (value) {
        return <Tag color="#87d068">True</Tag>;
      } else {
        return <Tag color="#f50">False</Tag>;
      }
    } else {
      return '-';
    }
  },
  date: (value: string) => {
    if (value) {
      return new Date(value).toLocaleString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      });
    } else {
      return '-';
    }
  },
  array: (values: any[]) => {
    if (values) {
      return <ArrayCell values={values} />;
    } else {
      return '-';
    }
  },
  number: (value: number) => {
    return !_.isNil(value) ? value.toLocaleString('en-US') : '-';
  },
  percentage: (value: number) => {
    return !_.isNil(value) ? value.toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 2 }) : '-';
  },
  currency: (value: number) => {
    return !_.isNil(value) ? value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-';
  }
};

type ScoringPreviewListProps = {
  loading: boolean;
  companies: any[];
  rules: ScoringRule[];
  sortDirection: SortOrder;
  setSortDirection: Function;
  searchDomains: string[];
  setSearchDomains: any;
};
export function ScoringPreviewTable({
  loading,
  companies,
  rules,
  sortDirection,
  setSortDirection,
  searchDomains,
  setSearchDomains
}: ScoringPreviewListProps) {
  const schema = useSelector(state => (state as any).companies.schema);

  // Dynamically add columns for each unique fieldName used in the rules
  const fieldsWithRules = _.uniq(_.compact([...rules.map(rule => rule.fieldName)]));
  const fieldsWithRulesNotDomainOrName = _.remove(
    fieldsWithRules,
    (fieldName: string) => !['domain', 'name'].includes(fieldName)
  );
  const columns = [
    {
      title: 'Score',
      dataIndex: 'goodfit_score_preview',
      key: 'goodfit_score_preview',
      ellipsis: false,
      sorter: true,
      fixed: true,
      sortOrder: sortDirection,
      width: '4%',
      className: 'scoring-preview-min-width-score',
      render: (score: number, record: any) => {
        return (
          <>
            <div style={{ textAlign: 'center' }}>{score}</div>
          </>
        );
      }
    },
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
      sorter: false,
      ellipsis: true,
      filtered: true,
      render: (text: string, record: any) => {
        return (
          <>
            <Avatar src={`https://logo.clearbit.com/${text}`} />
            <Typography.Text style={{ marginLeft: 8 }}>{text}</Typography.Text>
          </>
        );
      },
      fixed: true,
      width: '10%',
      className: 'scoring-preview-min-width-cell'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: false,
      align: 'center',
      width: '10%',
      className: 'scoring-preview-min-width-cell'
    },
    ...fieldsWithRulesNotDomainOrName.map(fieldName => {
      const fieldDef = schema.find((f: any) => f.id === fieldName);
      return {
        title: fieldDef?.name,
        dataIndex: fieldDef?.id,
        key: fieldDef?.id,
        sorter: false,
        ellipsis: true,
        align: 'center',
        render: CELL_RENDERERS[fieldDef?.type] || null,
        width: '10%',
        className: 'scoring-preview-min-width-cell'
      } as any;
    })
  ];

  return (
    <Card
      style={{ width: '100%' }}
      title="Scoring preview"
      tabList={[
        { key: 'descend', label: 'Best fit' },
        { key: 'ascend', label: 'Worst fit' }
      ]}
      activeTabKey={sortDirection || 'descend'}
      onTabChange={tabKey => {
        console.log(tabKey);
        setSortDirection(tabKey);
      }}
      tabBarExtraContent={
        <FieldMultiSelect
          placeholder="Search specific companies by domain(s)"
          style={{ width: 500 }}
          value={searchDomains}
          fieldName="domain"
          onChange={setSearchDomains}
          searchMode="prefix"
        />
      }
    >
      <Table
        bordered={true}
        loading={loading}
        onChange={(pagination: any, filters: any, sorter: any) => {
          setSortDirection(sorter.order);
        }}
        pagination={false}
        dataSource={companies}
        columns={columns}
        sticky={true}
        size="small"
        rowKey="domain"
        scroll={{ x: true }}
        rowClassName="table-row-size"
        sortDirections={['descend', 'ascend']}
      />
    </Card>
  );
}
