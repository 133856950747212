import React, { useState } from 'react';
import { useHistory } from 'react-router'

import { API } from 'aws-amplify';
import { Button, Form, Input, message, Col, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import AuthContainer from '../components/Auth/AuthContainer';

export default function PasswordResetRequest() {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const routerHistory = useHistory();

  const handleFormChange = (ev) => {
    setIsValid(form.isFieldsTouched(true) && form.getFieldsError().filter(({ errors }) => errors.length).length === 0);
  };

  async function handleSubmit() {
    setIsSubmitting(true);
    try {
      const email = form.getFieldValue('emailFormItem');

      await API.post('users', '/reset-password', { body: { email } });

      message.success('Please check your email for the verification code.');
      routerHistory.push(`/password-reset-confirm?email=${encodeURIComponent(email)}`);
    } catch (err) {
      console.error(err);
      if (err.name === 'LimitExceededException') {
        message.error(err.message);
      } else {
        message.error('There was en error sending password reset. Please contact support.');
      }
    }
    setIsSubmitting(false);
  }

  return (
    <AuthContainer>
      <p>
        Please input the email address associated with your user account.
        We will then email you a verification code to change your password.
      </p>
      <Form
        validateTrigger={['onBlur']}
        onFieldsChange={handleFormChange}
        form={form}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="emailFormItem"
          rules={[
            { type: 'email', message: 'The input is not valid E-mail!' },
            { required: true, message: 'Value must be valid email!' }
          ]}
        >
          <Input
            name="email"
            size="large"
            placeholder="Email"
            prefix={<UserOutlined />}
          />
        </Form.Item>
        <Row align='middle' justify='space-between'>
          <Col>
            <Form.Item style={{ marginBottom: '0' }}>
              <Button
                disabled={!isValid}
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
              >
                Send Password Reset Email
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <a href="/login">Cancel</a>
          </Col>
          </Row>
      </Form>
    </AuthContainer>
  );
}
