import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateConnectorAPI } from '../../features/connectors/connectorsSlice';
import _ from 'lodash';
import { message, Row, Col, Card } from 'antd';
import MappingPanel from './MappingPanel';

export default function ExportSettings({ connectorName, mapping, contactMapping, accountFields, contactFields }) {
  const { schema } = useSelector(state => state.companies);
  const dispatch = useDispatch();
  const getContactFields = () => {
    const contactSchema = _.find(schema, field => field.id === 'contacts');
    if (contactSchema) {
      return _.map(contactSchema.objectSchema, (val, key) => ({ id: key, name: _.startCase(key) }));
    }
    return [
      { id: 'firstName', name: 'First Name' },
      { id: 'lastName', name: 'Last Name' },
      { id: 'title', name: 'Title' },
      { id: 'email', name: 'Email' },
      { id: 'linkedin', name: 'LinkedIn URL' }
    ];
  };

  const saveAccountMapping = accountMapping => {
    dispatch(updateConnectorAPI(connectorName, { mapping: accountMapping }));
    message.success(`${_.capitalize(connectorName)} Account Mapping has been updated!`);
  };

  const saveContactMapping = contactMapping => {
    dispatch(updateConnectorAPI(connectorName, { contactMapping }));
    message.success(`${_.capitalize(connectorName)} Contact Mapping has been updated!`);
  };

  return (
    <Card title="Export Settings" size="small">
      {
        // TODO: Add exportEnabled to Export mapping options
      }
      <Row justify="center" gutter={32}>
        <Col span={12}>
          <MappingPanel
            key="company-mapping"
            title="Account Properties Mapping"
            mapping={mapping}
            firstSelectorOptions={schema}
            secondSelectorOptions={accountFields}
            onSave={mapping => saveAccountMapping(mapping)}
          />
        </Col>
        <Col span={12}>
          <MappingPanel
            key="contact-mapping"
            title="Contact Properties Mapping"
            mapping={contactMapping}
            firstSelectorOptions={getContactFields()}
            secondSelectorOptions={contactFields}
            onSave={contactMapping => saveContactMapping(contactMapping)}
          />
        </Col>
      </Row>
    </Card>
  );
}
