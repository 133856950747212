import MarketEstimations from "../components/AdminApp/MarketEstimations/MarketEstimations";
import React from "react";
import AdminSiteWrapper from "../components/SiteBasics/AdminSiteWrapper";

const MarketEstimationsPage = () => {
  return (
    <AdminSiteWrapper title={"market-estimations"}>
      <MarketEstimations />
    </AdminSiteWrapper>
  );
};


export default MarketEstimationsPage;