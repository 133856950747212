import { Col } from 'antd';
import React from 'react';
import Filters from './Filters';
import _ from 'lodash';

export default function GroupFilter({ filter, updateFilter }) {
  const { id, statement } = filter;
  const onSetFilters = newFilters => {
    updateFilter(id, i => _.set(i, 'statement', newFilters));
  };

  return (
    <Col flex="auto">
      <Filters filters={_.isEmpty(statement) ? [] : statement} setFilters={onSetFilters} />
    </Col>
  );
}
