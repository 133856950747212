import { Card, message, Space, Switch, Button } from 'antd';
import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExportSettings from '../components/Connectors/ExportSettings';
import AppSiteWrapper from '../components/SiteBasics/AppSiteWrapper';
import { updateConnectorAPI } from '../features/connectors/connectorsSlice';
import PipedriveAPIModal from '../components/Connectors/Pipedrive/PipedriveAPIModal';

export default function PipderiveConnector() {
  const { enabled, mapping, connected, contactMapping, alertsEnabled } = useSelector(
    state => state.connectors.pipedrive
  );
  const [accountFields, setAccountFields] = useState([]);
  const [contactFields, setContactFields] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    describeFields();
  }, [dispatch]);

  const describeFields = async () => {
    const { accountFields, contactFields } = await API.get('connectors', '/describe-fields?connectorName=pipedrive');
    setAccountFields(accountFields);
    setContactFields(contactFields);
  };

  const onSwitchChange = change => {
    dispatch(updateConnectorAPI('pipderive', { enabled: change }));
    if (change) {
      message.success('Hurray! Pipedrive connector has been enabled!');
    }
  };

  const onAlertsEnabledChange = change => {
    dispatch(updateConnectorAPI('pipedrive', { alertsEnabled: change }));
    if (change) {
      message.success('Hurray! Pipedrive alerts have been enabled!');
    }
  };

  return (
    <AppSiteWrapper>
      <Card
        title="Pipedrive Connector"
        extra={
          connected ? (
            <Space>
              <Switch
                checkedChildren="Alerts Enabled"
                unCheckedChildren="Alerts Disabled"
                checked={alertsEnabled}
                onChange={onAlertsEnabledChange}
                style={alertsEnabled ? { backgroundColor: '#87d068' } : {}}
              />
              <Switch
                checkedChildren="Enabled"
                unCheckedChildren="Disabled"
                checked={enabled}
                onChange={onSwitchChange}
                style={enabled ? { backgroundColor: '#87d068' } : {}}
              />
            </Space>
          ) : (
            <Button size="small" type="primary" onClick={() => setShowModal(true)}>
              Connect
            </Button>
          )
        }
      >
        <ExportSettings
          connectorName="pipedrive"
          mapping={mapping}
          contactMapping={contactMapping}
          accountFields={accountFields}
          contactFields={contactFields}
        />
      </Card>
      <PipedriveAPIModal visible={showModal} onCancel={() => setShowModal(false)} />
    </AppSiteWrapper>
  );
}
