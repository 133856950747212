import React, {useEffect} from 'react';

import { Card, Image } from 'antd';
import logoText from '../../images/goodfit-text-logo.svg';

export default function AuthContainer({ children }) {

  useEffect(() => {
    window.document.title = `Goodfit: Login`
  }, []);

  return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '0px auto',
          justifyContent: 'center',
          top: '20%',
          position: 'relative',
        }}
      >
        <Card style={{width:'400px',boxShadow: '8px 8px 40px rgba(0,0,0,0.1)',border:'1px solid rgba(0,0,0,0.1)'}}>
        <div style={{ textAlign: 'center'}}>
        <Image preview={false} src={logoText} style={{ marginBottom: '20px', marginTop: '8px', height: '24px', marginLeft: '-2px' }} />
        </div>
          { children }
        </Card>
      </div>
    );
}
