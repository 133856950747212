import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { CheckCircleFilled, WarningFilled, SyncOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

let extensionId;

function Progress({state, message}) {
  let antIcon = <SyncOutlined spin style={{fontSize: '64px'}} />;
  let color = '#aaa';

  if (state==='success') {
    antIcon = <CheckCircleFilled style={{fontSize: '64px'}} />;
    color = 'green';
  } else if (state==='error') {
    antIcon = <WarningFilled style={{fontSize: '64px'}} />;
    color = 'red';
  } 
  return <div class="extension-loading-progress"><div className={state}><Spin indicator={antIcon} style={{color}} /></div>{message}</div>
}

async function pingExtension(setPingResult) {
  console.log(window.chrome.runtime);
  try {
    window.chrome.runtime.sendMessage(extensionId, {action: 'ping'}, (response)=>{
        setPingResult(response);
      }
    );
  } catch(err) {
    console.log(err);
    setPingResult(false);
  }
}

async function pushToken(authToken, setPushAuthTokenResult) {
  console.log(window.chrome.runtime);
  try {
    window.chrome.runtime.sendMessage(extensionId, {action: 'setAuthToken', authToken}, (response)=>{
        console.log('RESPONSE', response);
        setPushAuthTokenResult(response);
      }
    );
  } catch(err) {
    console.log(err);
    setPushAuthTokenResult(false);
  }
}

async function createToken(setCreateTokenResult){
  const result = await API.post("extension", "/create-token");
  console.log("CREATE TOKEN RESULT", result);
  setCreateTokenResult(result);
}

const ExtensionLoginPage = () => {
  
  const [pingResult, setPingResult] = useState(null);
  const [createTokenResult, setCreateTokenResult] = useState(null);
  const [pushAuthTokenResult, setPushAuthTokenResult] = useState(null);

  const urlParams = new URLSearchParams(window.location.search);
  extensionId = urlParams.get('id');  

  useEffect(() => {
    if (pingResult==null) {
      console.log('PINGING EXTENSION');
      pingExtension(setPingResult);
    }
  }, [pingResult]);

  useEffect(() => {
    if (pingResult==='pong') {
      console.log('CREATING TOKEN');
      createToken(setCreateTokenResult);
    }
  }, [pingResult]);

  useEffect(() => {
    if (createTokenResult && createTokenResult?.token) {
      console.log('SENDING TOKEN');
      pushToken(createTokenResult.token, setPushAuthTokenResult);
    }
  }, [createTokenResult]);

  if (!extensionId) {
    return <Progress state="error" message="URL incorrect." />
  }
  if (pingResult===null) {
    return <Progress state="inprogress" message="Checking extension..." />
  }
  if (pingResult!=='pong') {
    return <Progress state="error" message="Error contacting extension. Is it installed?" />
  }
  if (createTokenResult===null) {
    return <Progress state="inprogress" message="Creating token..." />
  }
  if (!createTokenResult?.ok && createTokenResult?.error) {
    return <Progress state="error" message={ createTokenResult.error } />
  }
  if (!createTokenResult?.token) {
    return <Progress state="error" message="Could not create token." />
  }
  if (pushAuthTokenResult===null) {
    return <Progress state="inprogress" message="Sending token to extension..." />
  }
  if (pushAuthTokenResult!=='ok') {
    return <Progress state="error" message="Error sending token to extension..." />
  }

  return <Progress state="success" message="You are logged in. You can close this window and go back to linkedin." />
};


export default ExtensionLoginPage;