import React from "react";
import AppSiteWrapper from "../components/SiteBasics/AppSiteWrapper";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <AppSiteWrapper>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Link to="/">
            <Button type="primary">Back Home</Button>
          </Link>
        }
      />
    </AppSiteWrapper>
  );
}
