import { useState, useEffect } from 'react';
import { WarningFilled } from '@ant-design/icons';
import { Modal, Alert, Form, Input, Progress } from 'antd';
import { useDataDirectoryStore } from '../state';
import { getCreateFieldName } from '../createFieldUtils';
import { IntegrationFieldMappingInput } from '../types';
import { message } from 'antd';

type FieldCreationModalProps = {
  isModalVisible: boolean;
  setIsModalVisible: (visible: boolean) => void;
  mapping: IntegrationFieldMappingInput;
  recordType: string;
};

export function FieldCreationModal({
  isModalVisible,
  setIsModalVisible,
  mapping,
  recordType
}: FieldCreationModalProps) {
  const store = useDataDirectoryStore();
  const integration = store.integrations.find(i => i.id === mapping.integrationId);
  const [createFieldName, setCreateFieldName] = useState(getCreateFieldName(store, mapping));
  const [isCreatingField, setIsCreatingField] = useState(false);
  const [modalError, setModalError] = useState('');
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (isCreatingField) {
      const timer = setInterval(() => {
        setProgress(oldProgress => {
          if (oldProgress === 95) {
            clearInterval(timer);
          }
          return Math.min(oldProgress + 3, 99);
        });
      }, 500);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isCreatingField]);

  const handleModalOk = async () => {
    if (!integration) return;
    setIsCreatingField(true);
    const response = await store.createExternalField(integration, recordType, mapping, createFieldName);

    if (response?.success) {
      message.success(`Field ${createFieldName} has been created successfully`);
      setIsModalVisible(false);
    } else {
      setModalError(response?.error || 'Something went wrong');
    }
    setProgress(100);
    setIsCreatingField(false);
  };

  return (
    <Modal
      title="Create Field"
      open={isModalVisible}
      onOk={handleModalOk}
      onCancel={() => setIsModalVisible(false)}
      okText="Create"
      okButtonProps={{ disabled: isCreatingField }}
      cancelButtonProps={{ disabled: isCreatingField }}
    >
      {isCreatingField ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Progress percent={progress} />
        </div>
      ) : (
        <>
          {modalError && (
            <>
              <Alert type="error" message={modalError} icon={<WarningFilled />} />
              <br />
            </>
          )}
          <Form.Item label="Field name">
            <Input
              value={createFieldName}
              onChange={e => setCreateFieldName(e.target.value)}
              showCount={{
                formatter: ({ count }) => {
                  return <span style={{ color: count > 40 ? 'red' : 'black' }}>{`${count}/40`}</span>;
                }
              }}
            />
          </Form.Item>
        </>
      )}
    </Modal>
  );
}
