import React from "react";
import AppSiteWrapper from "../components/SiteBasics/AppSiteWrapper";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";

export default function NotAllowed() {
  return (
    <AppSiteWrapper>
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not allowed to visit the page"
        extra={
          <Link to="/">
            <Button type="primary">Back Home</Button>
          </Link>
        }
      />
    </AppSiteWrapper>
  );
}
