import _ from 'lodash';
import { Card, Tag, Spin } from 'antd';
import { Fragment } from 'react';

const divstyle = {
  border: '1px solid #eee',
  padding: '3px 1px 3px 8px',
  display: 'inline-block',
  marginInlineEnd: 8,
  verticalAlign: 'top',
  maxWidth: '95%',
  minWidth: '75%',
  margin: '2px'
};

const OPERATOR_NAMES = {
  notIn: 'not in',
  '~*': 'matches regex',
  '~': 'matches regex',
  stringBeginsWith: 'begins with',
  stringEndsWith: 'ends with',
  stringContains: 'contains string',
  listContains: 'contains item',
  null: 'is empty',
  notNull: 'is not empty'
} as any;

// Split on the common term divisor patterns
function cleanUpRegex(pattern: string) {
  const stripped = pattern.replaceAll('(;|$)', '').replaceAll('\\b', '').replaceAll('(;|^)', '');
  return stripped.split('|');
}

type SourcingCriteriaDisplayProps = {
  sourcingCriteria: any;
  fieldDefinitions: any[];
};

function RuleGroup({ ruleGroup, fieldDefinitions, isTopLevel }: any) {
  const combinator = ruleGroup.combinator || 'and';
  const parts = [];

  for (let index = 0; index < ruleGroup.rules.length; index++) {
    const rule = ruleGroup.rules[index];
    const hasMoreElements = index < ruleGroup.rules.length - 1;
    let part = null;

    if (rule.rules) {
      part = <RuleGroup ruleGroup={rule} fieldDefinitions={fieldDefinitions}></RuleGroup>;
    } else if (rule.field) {
      part = <Rule field={rule} fieldDefinitions={fieldDefinitions}></Rule>;
    }

    if (part) {
      parts.push(
        <Fragment key={`c:${index}`}>
          {part}
          <br />
          {hasMoreElements && (
            <Tag color="blue" style={{ marginTop: 8 }}>
              {combinator.toUpperCase()}
            </Tag>
          )}
        </Fragment>
      );
    }
  }

  if (isTopLevel) {
    return <>{parts}</>;
  } else {
    return <div style={divstyle}>{parts}</div>;
  }
}

function Rule({ field, fieldDefinitions }: any) {
  const fieldDefinition = fieldDefinitions.find((fd: any) => {
    return fd.internalName === field.field && fd.configId === field.configId;
  });

  let label = field.field;
  if (fieldDefinition) {
    label = fieldDefinition.externalName || fieldDefinition.dataBlockField.publicName;
  } else if (field.configId) {
    label = `${field.field} (${field.configId})`;
  }

  let value = _.isArray(field.value) ? field.value.sort() : [field.value];
  if (field.operator === '~*' || field.operator === '~') {
    value = cleanUpRegex(value[0]);
  }

  return (
    <div style={divstyle}>
      <Tag color="cyan" style={{ margin: '2px 5px 2px 2px' }}>
        {label}
      </Tag>
      &nbsp;{OPERATOR_NAMES[field.operator] || field.operator}&nbsp;
      {field.operator === 'between' && (
        <>
          <Tag color="gold" style={{ maxWidth: '70%', textOverflow: 'ellipsis', overflow: 'clip', margin: '2px' }}>
            {JSON.stringify(value[0])}
          </Tag>
          and
          <Tag color="gold" style={{ maxWidth: '70%', textOverflow: 'ellipsis', overflow: 'clip', margin: '2px' }}>
            {JSON.stringify(value[1])}
          </Tag>
        </>
      )}
      {field.operator !== 'between' &&
        value.map((v: any, idx: number) => (
          <Tag
            key={`v:${idx}`}
            color="gold"
            style={{ maxWidth: '70%', textOverflow: 'ellipsis', overflow: 'clip', margin: '2px' }}
          >
            {JSON.stringify(v)}
          </Tag>
        ))}
    </div>
  );
}

export function SourcingCriteriaDisplay({ sourcingCriteria, fieldDefinitions }: SourcingCriteriaDisplayProps) {
  return (
    <>
      {sourcingCriteria && (
        <RuleGroup ruleGroup={sourcingCriteria} fieldDefinitions={fieldDefinitions} isTopLevel={true}></RuleGroup>
      )}
      {!sourcingCriteria && 'No company criteria is currently set.'}
    </>
  );
}
