import { createSlice } from "@reduxjs/toolkit";
import defaultValues from "./default.json";
import defaultSchema from "./defaultCcmSchema.json";
import { API } from "aws-amplify";

const marketEstimationsSlice = createSlice({
  name: "companies",
  initialState: {
    result: {
      companyCount: "0",
      isValid: true,
      sample: [],
      sqlFragment: {
        sql: "",
        params: []
      }
    },
    schema: defaultSchema,
    sourcingCriteria: defaultValues,
    isFetching: false
  },
  reducers: {
    requestMarketEstimations(state, action) {
      state.isFetching = true;
    },
    receiveMarketEstimations(state, action) {
      state.sourcingCriteria = action.payload.request;
      state.result = action.payload.result;
      state.isFetching = false;
    },
    receiveMarketEstimationsSchema(state, action) {
      state.schema = action.payload;
      state.isFetching = false;
    },
    setMarketEstimationsSourcingCriteria(state, action) {
      state.sourcingCriteria = action.payload;
    }
  }
});

export const fetchMarketEstimations = (sourcingCriteria) => async (dispatch, getState) => {
  await dispatch(requestMarketEstimations());
  const result = await API.post("market-estimations", "", {
    body: {
      "fieldDefinitions": [{ "internalName": "firmographics/company_id" },
        { "internalName": "firmographics/primary_domain" },
        { "internalName": "firmographics/linked_in_url" },
        { "internalName": "firmographics/country" },
        { "internalName": "firmographics/employee_count" }],
      sourcingCriteria: sourcingCriteria.sourcingCriteria
    }
  });
  await dispatch(receiveMarketEstimations({ request: sourcingCriteria, result }));
};

export const fetchMarketEstimationsSchema = () => async (dispatch, getState) => {
  await dispatch(requestMarketEstimations());
  const schema = await API.get("ccm-schema");
  await dispatch(receiveMarketEstimationsSchema(schema));
};


export const {
  requestMarketEstimations,
  receiveMarketEstimations,
  receiveMarketEstimationsSchema,
  setMarketEstimationsSourcingCriteria
} = marketEstimationsSlice.actions;

export default marketEstimationsSlice.reducer;
