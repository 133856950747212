import { Button, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { mapError } from './errorMaps';
import { stringTitleCase } from '../../libs/string';

type ErrorTableProps = {
  selectedIntegration: string;
  onButtonPress: (record: any) => void;
};

type IntegrationJobsError = {
  errorsCnt: number;
  errors: Array<{
    integrationId: string;
    integrationType: string;
    errorCode: string;
    errorCount: number;
    field: string;
  }>;
};

export function ErrorsTable({ selectedIntegration, onButtonPress }: ErrorTableProps) {
  const [jobsErrors, setJobsErrors] = useState({ errorsCnt: 0, errors: [] } as IntegrationJobsError);
  const [isLoading, setIsLoading] = useState(false);

  const getIntegrationJobsErrors = async (currentPage = 1, limit = 20) => {
    setIsLoading(true);
    try {
      const offset = (currentPage - 1) * limit;
      var integrationType = null;
      if (selectedIntegration !== 'ALL') {
        integrationType = selectedIntegration;
      }
      // @ts-ignore
      setJobsErrors(
        await API.get(
          'integrations',
          `/errors/summary?offset=${offset}&limit=${limit}${
            integrationType ? `&integrationType=${integrationType}` : ''
          }`,
          {}
        )
      );
    } finally {
      setIsLoading(false);
    }
  };

  // @ts-ignore
  const handleOnTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'paginate') getIntegrationJobsErrors(pagination.current, pagination.pageSize);
  };

  useEffect(() => {
    getIntegrationJobsErrors();
  }, [selectedIntegration]);

  return (
    <Table
      size="small"
      onChange={handleOnTableChange}
      pagination={{ defaultPageSize: 20, total: jobsErrors?.errorsCnt }}
      dataSource={jobsErrors?.errors.map(error => ({
        ...error,
        key: `${error.integrationId}-${error.errorCode}-${error.field}`
      }))}
      loading={isLoading}
      columns={[
        {
          title: 'Integration',
          dataIndex: 'integrationType',
          align: 'center',
          render: text => stringTitleCase(text)
        },
        {
          title: 'Type',
          dataIndex: 'recordType',
          align: 'center',
          render: text => stringTitleCase(text)
        },
        {
          title: 'Field',
          dataIndex: 'field',
          align: 'center',
          ellipsis: true,
          render: text => stringTitleCase(text) || 'N/A'
        },
        {
          title: 'Error',
          dataIndex: 'errorCode',
          align: 'center',
          ellipsis: true,
          render: (text, record) => mapError(text, '', record.field).shortDescription
        },
        {
          title: '#Records',
          dataIndex: 'errorCount',
          align: 'center'
        },
        {
          title: 'Actions',
          dataIndex: 'errorCode',
          align: 'center',
          render: (errorCode, record) => (
            <Button size={'small'} onClick={() => onButtonPress(record)}>
              Show details
            </Button>
          )
        }
      ]}
    />
  );
}
