import { Amplify } from 'aws-amplify';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import config from './config';
import { Hub } from 'aws-amplify';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: 'data',
        endpoint: `${config.apiGateway.URL}/data`,
        region: config.apiGateway.REGION
      },
      {
        name: 'companies',
        endpoint: `${config.apiGateway.URL}/companies`,
        region: config.apiGateway.REGION
      },
      {
        name: 'users',
        endpoint: `${config.apiGateway.URL}/users`,
        region: config.apiGateway.REGION
      },
      {
        name: 'new-user',
        endpoint: `${config.apiGateway.URL}/users`,
        region: config.apiGateway.REGION
      },
      {
        name: 'connectors',
        endpoint: `${config.apiGateway.URL}/connectors`,
        region: config.apiGateway.REGION
      },
      {
        name: 'imports',
        endpoint: `${config.apiGateway.URL}/imports`,
        region: config.apiGateway.REGION
      },
      {
        name: 'segments',
        endpoint: `${config.apiGateway.URL}/segments`,
        region: config.apiGateway.REGION
      },
      {
        name: 'alerts',
        endpoint: `${config.apiGateway.URL}/alerts`,
        region: config.apiGateway.REGION
      },
      {
        name: 'dashboard',
        endpoint: `${config.apiGateway.URL}/dashboard`,
        region: config.apiGateway.REGION
      },
      {
        name: 'market-estimations',
        endpoint: `${config.ccm.PREVIEW}`,
        region: config.apiGateway.REGION
      },
      {
        name: 'ccm-schema',
        endpoint: `${config.ccm.SCHEMA}`,
        region: config.apiGateway.REGION
      },
      {
        name: 'extension',
        endpoint: `${config.apiGateway.URL}/extension`,
        region: config.apiGateway.REGION
      },
      {
        name: 'scoring',
        endpoint: `${config.apiGateway.URL}/scoring`,
        region: config.apiGateway.REGION
      },
      {
        name: 'admin',
        endpoint: `${config.apiGateway.URL}/admin`,
        region: config.apiGateway.REGION
      },
      {
        name: 'integrations',
        endpoint: `${config.integrations}`,
        region: config.apiGateway.REGION
      }
    ]
  }
});

// Amplify auth listener. For detecting login / logout etc.
const listener = data => {
  const payload = data.payload;
  if (window.posthog) {
    if (payload.event === 'signOut') {
      console.log('RESET');
      window.posthog.reset();
    } else {
      if (payload.data && payload.data.username && payload.data.attributes) {
        console.log('IDENTITY');
        const userId = payload.data.username;
        const email = payload.data.attributes.email;
        const accountName = payload.data.attributes['custom:account_name'];
        window.goodfitAccountName = accountName;
        window.posthog.identify(userId, {
          email,
          accountName
        });
      }
    }
  }
};
Hub.listen('auth', listener);

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
