import { Menu } from "antd";
import { Link } from "react-router-dom";
import GenericNavBar from "./GenericNavBar";
import { PieChartOutlined } from "@ant-design/icons";

export default function AdminNavBar({ title }) {
  return (
    <GenericNavBar>
      <Menu mode="horizontal" selectedKeys={[title]} style={{ background: "none" }}>
        <Menu.Item key="market-estimations" icon={<PieChartOutlined />}>
          <Link to="/market-estimations">Market Estimations</Link>
        </Menu.Item>
        <Menu.Item key="admin-ccm-edit" icon={<PieChartOutlined />}>
          <Link to="/admin/ccm-edit">CCM edit</Link>
        </Menu.Item>
      </Menu>
    </GenericNavBar>
  );
}
