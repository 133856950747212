import { Button, Card, Dropdown, Menu, message, Space, Switch } from 'antd';
import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ExportSettings from '../components/Connectors/ExportSettings';
import ImportSettings from '../components/Connectors/ImportSettings';
import AppSiteWrapper from '../components/SiteBasics/AppSiteWrapper';
import config from '../config';
import { fetchConnectors, updateConnectorAPI } from '../features/connectors/connectorsSlice';

export default function SalesforceConnector() {
  const { enabled, connected, mapping, contactMapping, alertsEnabled } = useSelector(
    state => state.connectors.salesforce
  );
  const [accountFields, setAccountFields] = useState([]);
  const [contactFields, setContactFields] = useState([]);
  const [oppStages, setOppStages] = useState([]);
  const dispatch = useDispatch();
  const code = useQuery().get('code');

  useEffect(() => {
    describeFields();
    const sendAuthCode = async code => {
      if (window.location.href.includes('salesforce-sandbox')) {
        console.log('salesforce sandbox');
        await API.post('connectors', `/authenticate`, { body: { connectorName: 'salesforce-sandbox', code } });
      } else {
        console.log('salesforce');
        await API.post('connectors', `/authenticate`, { body: { connectorName: 'salesforce', code } });
      }
      dispatch(fetchConnectors());
    };
    if (code) {
      sendAuthCode(code);
    }
  }, [dispatch, code]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const describeFields = async () => {
    const { opportunityStages, accountFields, contactFields } = await API.get(
      'connectors',
      '/describe-fields?connectorName=salesforce'
    );
    setOppStages(opportunityStages);
    setAccountFields(accountFields);
    setContactFields(contactFields);
  };

  const onSwitchChange = change => {
    dispatch(updateConnectorAPI('salesforce', { enabled: change }));
    if (change) {
      message.success('Hurray! Salesforce connector has been enabled!');
    }
  };

  const onAlertsEnabledChange = change => {
    dispatch(updateConnectorAPI('salesforce', { alertsEnabled: change }));
    if (change) {
      message.success('Hurray! Salesforce alerts have been enabled!');
    }
  };

  return (
    <AppSiteWrapper>
      <Card
        title="Salesforce Connector"
        extra={
          connected ? (
            <Space>
              <Switch
                checkedChildren="Alerts Enabled"
                unCheckedChildren="Alerts Disabled"
                checked={alertsEnabled}
                onChange={onAlertsEnabledChange}
                style={alertsEnabled ? { backgroundColor: '#87d068' } : {}}
              />
              <Switch
                checkedChildren="Enabled"
                unCheckedChildren="Disabled"
                checked={enabled}
                onChange={onSwitchChange}
                style={enabled ? { backgroundColor: '#87d068' } : {}}
              />
            </Space>
          ) : (
            <Dropdown
              placement="bottomCenter"
              overlay={
                <Menu
                  onClick={({ key }) => {
                    key === 'production'
                      ? (window.location.href = config.salesforce.AUTH_URL)
                      : (window.location.href = config.salesforce.AUTH_URL.replace('login.', 'test.').replace(
                          'connectors/salesforce',
                          'connectors/salesforce-sandbox'
                        ));
                  }}
                >
                  <Menu.Item key="production">Production</Menu.Item>
                  <Menu.Item key="sandbox">Sandbox</Menu.Item>
                </Menu>
              }
            >
              <Button size="small" type="primary">
                Connect
              </Button>
            </Dropdown>
          )
        }
      >
        <ExportSettings
          connectorName="salesforce"
          mapping={mapping}
          contactMapping={contactMapping}
          accountFields={accountFields}
          contactFields={contactFields}
        />
        <ImportSettings connectorName="salesforce" oppStages={oppStages} accountFields={accountFields} />
      </Card>
    </AppSiteWrapper>
  );
}
