import { Redirect, Route, Switch } from 'react-router-dom';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import AlertsSettings from './containers/AlertsSettings';
import ConnectorLogs from './containers/ConnectorLogs';
import Connectors from './containers/Connectors';
import Dashboard from './containers/Dashboard';
import Download from './containers/Download';
import Home from './containers/Home';
import HubSpotConnector from './containers/HubSpotConnector';
import PipedriveConnector from './containers/PipedriveConnector';
import Login from './containers/Login';
import PasswordResetRequest from './containers/PasswordResetRequest';
import PasswordResetConfirm from './containers/PasswordResetConfirm';
import NotFound from './containers/NotFound';
import SalesforceConnector from './containers/SalesforceConnector';
import SegmentsAlerts from './containers/SegmentsAlerts';
import SegmentsSettings from './containers/SegmentsSettings';
import SettingsSchema from './containers/SettingsSchema';
import ScoringPage from './containers/Scoring';
import Users from './containers/Users';
import WebhookConnector from './containers/WebhookConnector';
import MarketEstimationsPage from './containers/MarketEstimationsPage';
import ExtensionLoginPage from './extension/ExtensionLoginPage';
import DataDirectory from './containers/DataDirectory';
import DataDirectory2 from './containers/DataDirectory2';
import AdminCCMEditPage from './containers/AdminCCMEditPage';

import NotAllowed from './containers/NotAllowed';
import ConnectorLogsTNG from './containers/ConnectorLogs/index';
import { ConnectorsTNG, HubspotConnectorTNG, SalesforceConnectorTNG } from './containers/ConnectorSettings';
import { FeatureFlag, hasFeatureFlag } from './featureFlags';

export default function Routes() {
  return (
    <Switch>
      {/* to delete the first one after QA*/}
      <AuthenticatedRoute
        path="/settings/connector-logs"
        component={hasFeatureFlag(FeatureFlag.v2CrmSync) ? ConnectorLogsTNG : ConnectorLogs}
      />
      <AuthenticatedRoute
        path="/connectors/hubspot"
        component={hasFeatureFlag(FeatureFlag.v2CrmSync) ? HubspotConnectorTNG : HubSpotConnector}
      />
      <AuthenticatedRoute
        path="/connectors/salesforce"
        component={hasFeatureFlag(FeatureFlag.v2CrmSync) ? SalesforceConnectorTNG : SalesforceConnector}
      />
      <AuthenticatedRoute
        path="/connectors/salesforce-sandbox"
        component={hasFeatureFlag(FeatureFlag.v2CrmSync) ? SalesforceConnectorTNG : SalesforceConnector}
      />
      <AuthenticatedRoute path="/connectors/webhook" component={WebhookConnector} />
      <AuthenticatedRoute path="/connectors/pipedrive" component={PipedriveConnector} />
      <AuthenticatedRoute path="/connectors/csv/download/:fileName" component={Download} />
      <AuthenticatedRoute path="/settings/connectors" component={hasFeatureFlag(FeatureFlag.v2CrmSync) ? ConnectorsTNG : Connectors} />
      <AuthenticatedRoute path="/settings/users" component={Users} />
      <AuthenticatedRoute path="/settings/segments" component={SegmentsSettings} />
      <AuthenticatedRoute path="/settings/alerts" component={AlertsSettings} />
      <AuthenticatedRoute path="/settings/schema" component={SettingsSchema} />
      <AuthenticatedRoute path="/scoring" component={ScoringPage} />
      <AuthenticatedRoute path="/data-directory" component={DataDirectory} />
      <AuthenticatedRoute path="/data-directory2" component={DataDirectory2} />
      <AuthenticatedRoute path="/talk-tracks" component={SegmentsAlerts} />
      <AuthenticatedRoute path="/dashboard" component={Dashboard} />
      <AuthenticatedRoute path="/companies" component={Home} />
      <AuthenticatedRoute path="/market-estimations" component={MarketEstimationsPage} onlyForGoodFit={true} />
      <AuthenticatedRoute path="/extension/login" component={ExtensionLoginPage} />
      <AuthenticatedRoute path="/admin/ccm-edit" component={AdminCCMEditPage} onlyForGoodFit={true} />
      <UnauthenticatedRoute path="/login" component={Login} />
      <UnauthenticatedRoute path="/password-reset" component={PasswordResetRequest} />
      <UnauthenticatedRoute path="/password-reset-confirm" component={PasswordResetConfirm} />
      <Route path="/not-allowed" component={NotAllowed} />
      <Route path="/not-found" component={NotFound} />
      <Redirect exact from="/settings" to="/settings/connectors" />
      <Redirect exact from="/" to="/companies" />
      <Redirect to="/not-found" />
    </Switch>
  );
}
