import React, { useState } from 'react';

import { onError } from '../libs/errorLib';
import { Auth } from 'aws-amplify';
import { useAppContext } from '../libs/contextLib';
import { Button, Form, Input, message, Row, Col } from 'antd';
import { LockOutlined, UnlockOutlined, UserOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import AuthContainer  from '../components/Auth/AuthContainer';
import { validateNewPassword } from '../libs/auth';

export default function Login() {
  const location = useLocation();
  const { setIsAuthenticated } = useAppContext();
  const [form] = Form.useForm();
  const [forceChangePassword, setForceChangePassword] = useState(false);
  const [formData, setFormData] = useState({
    cognitoUser: {},
    email: '',
    password: '',
    newPassword: '',
    confirmNewPassword: '',
    passwordChallenge: false,
    disabledSubmitBtn: true,
    isFetching: false
  });

  async function handleSubmit() {
    try {
      setFormData({ ...formData, isFetching: true });

      if (formData.cognitoUser && forceChangePassword) {
        setForceChangePassword(false);
        setFormData({
          ...formData,
          cognitoUser: await Auth.completeNewPassword(formData.cognitoUser, formData.newPassword),
          isFetching: false,
          passwordChallenge: false
        });
        setIsAuthenticated(true);
        window.location = location.state ? location.state.from.pathname : '/';
        message.success(`Logged In`);
      } else {
        const user = await Auth.signIn(formData.email, formData.password);

        if (user.challengeName && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setForceChangePassword(true);
          setFormData({
            ...formData,
            passwordChallenge: true,
            disabledSubmitBtn: true,
            cognitoUser: user,
            isFetching: false
          });
        } else {
          setFormData({
            ...formData,
            cognitoUser: user,
            isFetching: false
          });
          setIsAuthenticated(true);
          window.location = location.state ? location.state.from.pathname : '/';
          message.success(`Logged In`);
        }
      }
    } catch (e) {
      onError(e);
      setFormData({ ...formData, isFetching: false });
    }
  }

  const handleChange = ({ currentTarget: input }) => {
    const updatedFormData = { ...formData };
    updatedFormData[input.name] = input.value;
    const loginPageValidation = updatedFormData.email.length === 0 || updatedFormData.password.length === 0;
    const newPasswordPageValidation =
      updatedFormData.newPassword.length === 0 ||
      updatedFormData.confirmNewPassword.length === 0 ||
      updatedFormData.newPassword !== updatedFormData.confirmNewPassword;

    updatedFormData.disabledSubmitBtn = updatedFormData.passwordChallenge
      ? newPasswordPageValidation
      : loginPageValidation;

    setFormData(updatedFormData);
  };

  return (
    <AuthContainer>
      <Form
          form={form}
          validateTrigger={formData.passwordChallenge ? ['onChange'] : ['onBlur']}
          onFinish={handleSubmit}
        >
          {formData.passwordChallenge ? (
            <>
              <Form.Item name="newPasswordFormItem" rules={[{ validator: validateNewPassword }]}>
                <Input.Password
                  name="newPassword"
                  size="large"
                  placeholder="New Password"
                  prefix={<LockOutlined />}
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item
                name="confirmNewPasswordFormItem"
                dependencies={['newPasswordFormItem']}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPasswordFormItem') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    }
                  })
                ]}
              >
                <Input.Password
                  name="confirmNewPassword"
                  size="large"
                  placeholder="Confirm New Password"
                  prefix={<LockOutlined />}
                  onChange={handleChange}
                />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                name="emailFormItem"
                rules={[
                  { type: 'email', message: 'The input is not valid E-mail!' },
                  { required: true, message: 'Value must be valid email!' }
                ]}
              >
                <Input
                  name="email"
                  size="large"
                  placeholder="Email"
                  prefix={<UserOutlined />}
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item name="passwordFormItem" rules={[{ required: true, message: 'Please input your password!' }]}>
                <Input.Password
                  name="password"
                  size="large"
                  placeholder="Password"
                  prefix={<UnlockOutlined />}
                  onChange={handleChange}
                />
              </Form.Item>
            </>
          )}

          <Row align='middle' justify='space-between'>
          <Col>
            <Form.Item style={{ marginBottom: '0' }}>
              <Button
                disabled={formData.disabledSubmitBtn}
                type="primary"
                htmlType="submit"
                loading={formData.isFetching}
              >
                {formData.passwordChallenge ? 'Change Password' : 'Login'}
              </Button>
            </Form.Item>
          </Col>
          <Col>
            {!formData.passwordChallenge && (
              <a href="/password-reset">Forgotten password</a>
            )}
          </Col>
          </Row>
        </Form>
      </AuthContainer>
  );
}
