import React, { Fragment } from "react";
import { Layout } from "antd";
import AdminNavBar from "../NavBar/AdminNavBar";

export default function AdminSiteWrapper({ title, children }) {

  return (
    <Fragment>
      <AdminNavBar title={title} />
      <Layout.Content
        style={{
          maxWidth: "1400px",
          paddingLeft: "0.75rem",
          paddingRight: "0.75rem",
          marginLeft: "auto",
          marginRight: "auto",
          width: "100%",
          marginTop: 32
        }}
      >
        {children}
      </Layout.Content>
    </Fragment>
  );
}
