import _ from 'lodash';
import React, { useState } from 'react';
import { Table, Button, Tag } from 'antd';
import { FieldDrawer } from '../FieldDrawer';
import { TypeTag } from './TypeTag';
import { FieldMappingEditor } from './FieldMappingEditor';
import { FieldMappingDisplay } from './FieldMappingDisplay';
import { DataDirectoryEntry } from '../types';
import { stringTitleCase } from '../../../libs/string';

function cleanDisplayName(name: string) {
  return stringTitleCase(name.replaceAll('system:', 'GoodFit: ').replaceAll('_', ' '));
}

type FieldTableProps = {
  fieldDefinitions: DataDirectoryEntry[];
  isLoading: boolean;
  isEditing: boolean;
  recordType: string;
};
export function FieldTable({ fieldDefinitions, isLoading, isEditing, recordType }: FieldTableProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [fieldInfo, setFieldInfo] = useState<any>(null);

  async function viewFieldInfo(row: any) {
    setIsDrawerOpen(true);
    setFieldInfo(row);
  }

  const columns = [
    {
      key: 'title',
      title: 'Goodfit Name',
      sorter: (a: any, b: any) => a.title.localeCompare(b.title),
      width: '30%',
      render: (row: any) => {
        if (row.record.isSystem) {
          return (
            <>
              {cleanDisplayName(row.record.datasetName)} &nbsp; <Tag>System</Tag>
            </>
          );
        } else {
          return row.record.datasetName;
        }
      }
    },
    {
      dataIndex: 'type',
      title: 'Data Type',
      align: 'center' as any,
      sorter: (a: any, b: any) => a.type.localeCompare(b.type),
      render: (type: string) => <TypeTag type={type} />,
      width: '12%'
    },
    {
      key: 'mappedTo',
      title: 'CRM Field Mappings',
      render: (row: any) => {
        return isEditing ? (
          <FieldMappingEditor mappings={row.record.mappings || []} recordType={recordType} fieldName={row.key} />
        ) : (
          <FieldMappingDisplay mappings={row.record.mappings || []} recordType={recordType} fieldName={row.key} />
        );
      },
      width: '55%'
    },
    {
      key: 'actions',
      align: 'center' as any,
      width: '8%',
      render: (row: any) => {
        return (
          <>
            <Button
              disabled={recordType === 'contact' || row.record.isSystem}
              onClick={() => viewFieldInfo(row)}
              size="small"
            >
              Field Information
            </Button>
          </>
        );
      }
    }
  ];

  const tableRows = fieldDefinitions.map((f: any) => {
    return {
      key: f.externalName || f.dataBlockField.publicName,
      title: f.externalName || f.dataBlockField.publicName,
      type: f.dataBlockField.type,
      description: f.publicDescriptionOverride || f.dataBlockField.publicDescription || 'No description available',
      record: f
    };
  });

  return (
    <>
      <Table columns={columns} dataSource={tableRows} loading={isLoading} pagination={false} bordered={true}></Table>
      <FieldDrawer fieldInfo={fieldInfo} open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />
    </>
  );
}
