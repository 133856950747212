import React from 'react';
import { Modal, List, Avatar } from 'antd';
import _ from 'lodash';

export default function ContactsModal({ contacts, show, onCancel, schema }) {
  const generateDescription = contact => {
    const additionalFields = _.omit(contact, ['firstName', 'lastName', 'title', 'first_name', 'last_name']);
    return (
      <>
        {_.map(additionalFields, (val, key) => {
          return (
            <span>
              {_.startCase(key)}: {`${contact[key]}` || ''}
              <br />
            </span>
          );
        })}
      </>
    );
  };

  const renderContactsList = contacts => {
    return (
      <List
        dataSource={contacts}
        renderItem={item => (
          <List.Item key={item.email}>
            <List.Item.Meta
              avatar={<Avatar src="https://img.icons8.com/cotton/2x/person-male.png" />}
              title={`${item.first_name || item.firstName  || ''} ${item.last_name || item.lastName || ''} - ${item.title || ''}`}
              description={generateDescription(item)}
            />
          </List.Item>
        )}
      />
    );
  };

  return (
    <Modal title="Contacts" visible={show} onCancel={() => onCancel()} footer={null}>
      {renderContactsList(contacts)}
    </Modal>
  );
}
