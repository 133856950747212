import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Col, Input } from 'antd';
import { operatorOptions, subOperatorOptions } from './config';
import FilterSelector from './FilterSelector';

export default function ChangeFilter({ filter, updateFilter }) {
  const { id, statement } = filter;
  const schema = useSelector(state => state.companies.schema);
  const [fieldType, setFieldType] = useState();

  useEffect(() => {
    if (statement.fieldName) {
      setFieldType(_.find(schema, { id: statement.fieldName })?.type);
    }
  }, [statement.fieldName, schema]);
  return (
    <>
      <FilterSelector
        value={statement?.fieldName}
        setValue={val => updateFilter(id, filter => _.set(_.cloneDeep(filter), 'statement.fieldName', val))}
        options={_.map(schema, 'id')}
      />

      {statement.fieldName && (
        <FilterSelector
          value={statement.operator}
          setValue={val => updateFilter(id, filter => _.set(_.cloneDeep(filter), 'statement.operator', val))}
          options={_.map(operatorOptions['change'][fieldType], 'name')}
        />
      )}
      {statement.operator && subOperatorOptions[statement.operator] && (
        <FilterSelector
          value={statement.subOperator}
          setValue={val => updateFilter(id, filter => _.set(_.cloneDeep(filter), 'statement.subOperator', val))}
          options={_.map(subOperatorOptions[statement.operator], 'name')}
        />
      )}
      <Col flex="auto">
        {statement.subOperator && (
          <Input
            placeholder="Value"
            size="small"
            value={statement.value}
            onChange={e => updateFilter(id, filter => _.set(_.cloneDeep(filter), 'statement.value', e.target.value))}
          />
        )}
      </Col>
    </>
  );
};
