import React, { useState, useEffect } from "react";
import { API } from 'aws-amplify';

import { Button, message } from 'antd';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

import AdminSiteWrapper from "../components/SiteBasics/AdminSiteWrapper";

const AdminCCMEditPage = () => {

  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [schema, setSchema] = useState(null);
  const [config, setConfig] = useState(null);
  const [errors, setErrors] = useState(null);
  const [isErrorOpen, setIsErrorOpen] = useState(true);

  async function saveConfig() {
    setIsSaving(true);
    setErrors(null);
    try {
      const saveConfig = {...config};
      if (Object.keys(saveConfig?.crmDataPullConfig).length === 0) {  
        delete saveConfig['crmDataPullConfig'];
      }
      const result = await API.post('admin', '/ccm-config', {body:saveConfig});
      message.success('Saved ok.');
    } catch (err) {
      message.error('Validation errors.');
      console.log('Error saving config', err, err?.response?.data);
      setErrors(err?.response.data?.errors || []);
      setIsErrorOpen(true);
    }
    setIsSaving(false);
  }

  function handleChange(newConfig) {
    setConfig(newConfig);
  }

  useEffect(() => {
    async function loadConfig() {
      try {
        const result = await API.get('admin', '/ccm-config', {});
        setSchema(result.schema);
        setConfig(result.config);
        setIsLoading(false);
      } catch (err) {
        console.log('Error loading config', err);
      }
    }
    loadConfig();
  }, []);

  if (isLoading) {
    return (
      <AdminSiteWrapper title={"admin-ccm-edit"}>
        Loading....
      </AdminSiteWrapper>
    )
  }

  return (
    <AdminSiteWrapper title={"admin-ccm-edit"}>
      <p>
        <Button loading={isSaving} onClick={saveConfig} type="primary">Save config</Button>
      </p>
      {errors && (
        <details open={isErrorOpen} onClick={()=>setIsErrorOpen(!isErrorOpen)} style={{padding:4,background:'red',color:'white'}}>
          <summary><b>Error!</b></summary>
          <pre style={{background:'red',color:'white'}}>{JSON.stringify(errors, null, 2)}</pre>
        </details>
      )}
      <br />
      <Editor
            value={config}
            schema={schema}
            onChange={handleChange}
            mode="view"
            allowedModes={['view','tree','code']}
        />
        <br /><br /><br /><br /><br /><br />
    </AdminSiteWrapper>
  );
};

export default AdminCCMEditPage;