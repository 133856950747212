import { Button, Card, Drawer, Flex, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { Integration } from '../DataDirectory2/types';

type SettingDrawerProps = {
  integration: Integration;
  open: boolean;
  onClose: Function;
  onSave: Function;
};

export function PipedriveDrawer({ integration, open, onSave, onClose }: SettingDrawerProps) {
  const [apiKey, setApiKey] = useState(integration?.credentials?.apiKey);

  useEffect(() => {
    setApiKey(integration?.credentials?.apiKey);
  }, [integration]);

  return (
    <Drawer width={500} open={open} placement="right" title={`Webhook settings`} onClose={() => onClose()}>
      <Card title={`Api Key`}>
        <Input value={apiKey} onChange={e => setApiKey(e.target.value)} />
      </Card>

      <Flex justify={'flex-end'} style={{ marginTop: 16 }}>
        <Button
          type="primary"
          onClick={async () => {
            await onSave({
              connectorName: 'PIPEDRIVE',
              integrationType: 'PIPEDRIVE',
              credentials: { apiKey }
            });
            message.success('The API Key has been saved!');
          }}
        >
          Save
        </Button>
      </Flex>
    </Drawer>
  );
}
