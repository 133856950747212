import { Button, Card, Col, Drawer, Flex, Input, message, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Integration } from '../DataDirectory2/types';
import WebhookTestModal from '../../components/Connectors/Webhook/WebhookTestModal';
import _ from "lodash";

type SettingDrawerProps = {
  integration: Integration;
  open: boolean;
  onClose: Function;
  onSave: Function;
};

export function WebhooksDrawer({ integration, open, onSave, onClose }: SettingDrawerProps) {
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [accountUrl, setAccountUrl] = useState(integration?.credentials?.accountUrl);
  const [contactsUrl, setContactsUrl] = useState(integration?.credentials?.contactsUrl);

  const isValidUrl = (url: string) => {
    const urlRegex = /https?:\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/i;
    return urlRegex.test(url);
  };

  useEffect(() => {
    setAccountUrl(integration?.credentials?.accountUrl);
    setContactsUrl(integration?.credentials?.contactsUrl);
  }, [integration]);

  return (
    <Drawer width={500} open={open} placement="right" title={`Webhook settings`} onClose={() => onClose()}>
      <Card title={`Account URL`}>
        <Input value={accountUrl} onChange={e => setAccountUrl(e.target.value)} />
        <Row style={{ marginTop: 16 }}>
          <Col flex="1">
            <Button onClick={() => setShowAccountModal(true)}>Example Payload</Button>
          </Col>
        </Row>
      </Card>
      <Card title={`Contact URL`} style={{ marginTop: '16px' }}>
        <Input value={contactsUrl} onChange={e => setContactsUrl(e.target.value)} />
        <Row style={{ marginTop: 16 }}>
          <Col flex="1">
            <Button onClick={() => setShowContactModal(true)}>Example Payload</Button>
          </Col>
        </Row>
      </Card>
      <Flex justify={'flex-end'} style={{ marginTop: 16 }}>
        <Button
          type="primary"
          onClick={async () => {
            if (isValidUrl(accountUrl) && (_.isEmpty(contactsUrl) || isValidUrl(contactsUrl))) {
              await onSave({
                connectorName: 'WEBHOOK',
                integrationType: 'WEBHOOK',
                credentials: { accountUrl, contactsUrl }
              });
              message.success('The URL has been saved!');
            } else {
              message.error('It looks like one of the urls is invalid');
            }
          }}
        >
          Save
        </Button>
      </Flex>
      <WebhookTestModal
        visible={showAccountModal}
        onCancel={() => setShowAccountModal(false)}
        type={'account'}
        url={'url'}
      />
      <WebhookTestModal
        visible={showContactModal}
        onCancel={() => setShowContactModal(false)}
        type={'contact'}
        url={'url'}
      />
    </Drawer>
  );
}
