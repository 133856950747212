import AppSiteWrapper from '../components/SiteBasics/AppSiteWrapper';
import React, { useEffect, useState } from 'react';
import { Button, Card, Result, Spin } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { DownloadOutlined, FileZipOutlined } from '@ant-design/icons';
import { API } from 'aws-amplify';

export default function Download() {
  const match = useRouteMatch('/connectors/csv/download/:fileName');
  const [url, setUrl] = useState('');

  useEffect(() => {
    (async () => {
      const res = await API.get('connectors', `/csv/download/${match.params.fileName}`);
      setUrl(res.url);
    })();
    // There is a bug in useRouteMatch that means it retriggers this each render, so using something 'stable'
  }, [window.location.href]);

  if (!url) {
    return <AppSiteWrapper title="Download">
    <Spin tip="Loading" size="large" style={{ marginTop: 200 }}>
      <div className="content" />
    </Spin>
  </AppSiteWrapper>
  }

  return (
    <AppSiteWrapper title="Download">
      <Card>
        <Result
          icon={<FileZipOutlined style={{ fontSize: '500%' }} />}
          title={`Download File: ${match.params.fileName}`}
          extra={[
            <Link key={1} to="/">
              <Button type="default">Back Home</Button>
            </Link>,
            <Button key={2} href={url} type="primary" icon={<DownloadOutlined />}>
              Download
            </Button>
          ]}
        />
      </Card>
    </AppSiteWrapper>
  );
}
