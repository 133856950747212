import { Dropdown, Flex, Space, Typography, Button, Tooltip } from 'antd';

import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import SettingsWrapper from '../../components/SettingsWrapper';
import { DownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { JobsTable } from './JobsTable';
import { ErrorsTable } from './ErrorsTable';
import { ErrorDetailsDrawer } from './ErrorDetailsDrawer';
import { mapError } from './errorMaps';
import { stringTitleCase } from '../../libs/string';

type IntegrationJob = {
  id: string;
  integrationType: string;
  autosync: boolean;
  started: Date;
  status: string;
  progress: {
    progress: number;
    numAccountsToProcess: number;
    numAccountsCreated: number;
    numAccountsUpdated: number;
    numAccountsFailed: number;
    numContactsCreated: number;
    numContactsUpdated: number;
    numContactsFailed: number;
  };
};

export type ErrorRecord = {
  recordType: string;
  integrationType: string;
  originalMessage: string;
  field: string;
  errorCode: string;
  errorCount: number;
};

export function ConnectorLogsTNG() {
  const [integrations, setIntegrations] = useState([] as IntegrationJob[]);
  const [selectedIntegrationType, setSelectedIntegrationType] = useState('ALL');
  const [selectedErrorRecord, setSelectedErrorRecord] = useState({} as ErrorRecord);
  const [isLoading, setIsLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    // we just need to run it once on initial load
    getIntegrations();
  }, []);

  const getIntegrations = async () => {
    setIsLoading(true);
    // @ts-ignore
    setIntegrations((await API.get('integrations', ``)).integrations);
    setIsLoading(false);
  };

  const items = [
    {
      key: 'ALL',
      label: 'All Integrations'
    },
    ...integrations?.map(integration => {
      return {
        key: integration.integrationType,
        label: stringTitleCase(integration.integrationType)
      };
    })
  ];

  return (
    <SettingsWrapper title="connector logs">
      <>
        <Flex justify={'space-between'}>
          <Typography.Title level={5}>Sync Jobs</Typography.Title>
          <div style={{ marginTop: -4 }}>
            <Dropdown
              menu={{
                items,
                onClick: item => setSelectedIntegrationType(item.key)
              }}
            >
              <Space>
                <Button type="text">
                  {stringTitleCase(selectedIntegrationType)}
                  <DownOutlined />
                </Button>
              </Space>
            </Dropdown>
          </div>
        </Flex>
        <Space />
        <JobsTable selectedIntegration={selectedIntegrationType} />
        <Space />
        <Typography.Title level={5}>
          Current Record Sync Errors &nbsp;
          <Tooltip title="A list of reasons records are currently not able to sync to your CRM. This represents only records that are currently stuck: once the error is resolved, entries will be removed from this table.">
            <InfoCircleOutlined style={{ fontSize: 14, color: '#999' }} />
          </Tooltip>
        </Typography.Title>
        <ErrorsTable
          selectedIntegration={selectedIntegrationType}
          onButtonPress={record => {
            setSelectedErrorRecord(record);
            setIsDrawerOpen(true);
          }}
        />
      </>
      {selectedErrorRecord && (
        <ErrorDetailsDrawer
          selectedErrorRecord={selectedErrorRecord}
          selectedIntegration={selectedIntegrationType}
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        />
      )}
    </SettingsWrapper>
  );
}
