import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Flex, Input, message, Row, Space, Spin, Table } from 'antd';
import { DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import SettingsWrapper from '../components/SettingsWrapper';
import { addUser, removeUser } from '../features/users/usersSlice';
import Joi from 'joi';
import _ from 'lodash';
import { Auth } from 'aws-amplify';

export default function Users() {
  const dispatch = useDispatch();
  const users = useSelector(state => state.users);
  const [loggedInUser, setLoggedInUser] = useState('');

  const [pageData, setPageData] = useState({
    userEmail: '',
    filteredUsers: [],
    disabledInviteBtn: true
  });

  useEffect(() => {
    setPageData({
      userEmail: '',
      filteredUsers: users.orgUsers,
      disabledInviteBtn: true
    });
  }, [users.orgUsers]);

  useEffect(() => {
    const getLoggedUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setLoggedInUser(user.attributes.email);
      } catch (err) {
        console.log("Error: can't resolve logged user");
      }
    };

    getLoggedUser();
  }, [loggedInUser]);

  const handleInvite = async () => {
    try {
      await dispatch(addUser(pageData.userEmail));
      message.success(`Invitation send to ${pageData.userEmail}`);
    } catch (e) {
      console.log(e.message);
      message.error(`Something went wrong`);
    }
  };

  const handleInviteChange = ({ currentTarget: input }) => {
    const userEmail = input.value;
    const emailValidationSchema = Joi.string().email({ tlds: { allow: false } });
    const { error } = emailValidationSchema.validate(userEmail);

    const filteredUsers = _.filter(users.orgUsers, user => user.email.includes(userEmail));
    const disabledInviteBtn = filteredUsers.length > 0 || !!error;

    setPageData({ ...pageData, userEmail, disabledInviteBtn, filteredUsers });
  };

  const handleRemove = async emailToRemove => {
    try {
      await dispatch(removeUser(emailToRemove));
      message.success(`User removed: ${emailToRemove}`);
    } catch (e) {
      console.log(e.message);
      message.error(`Something went wrong`);
    }
  };

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (_, record) => {
        return (
          <Flex justify="space-between">
            {`${record.email}`}
            <Button
              icon={<DeleteOutlined />}
              danger
              title="Remove User"
              onClick={() => handleRemove(record.email)}
              disabled={loggedInUser === record.email}
            />
          </Flex>
        );
      }
    }
  ];

  return (
    <SettingsWrapper title="users">
      <Space style={{ display: 'flex' }} direction="vertical">
        <Card bodyStyle={{ padding: '5px' }}>
          <Row>
            <Col flex="auto">
              <Input
                bordered={false}
                style={{ padding: '4px 20px 4px 4px', margin: '0px', width: '100%' }}
                prefix={<UserOutlined />}
                allowClear
                onChange={handleInviteChange}
                placeholder="User email"
              />
            </Col>
            <Col>
              <Button disabled={pageData.disabledInviteBtn} type="primary" onClick={handleInvite}>
                Invite
              </Button>
            </Col>
          </Row>
        </Card>
        <Card bodyStyle={{ padding: '0px' }} bordered={false}>
          {users.isFetching ? (
            <Spin style={{ display: 'flex', padding: 20 }} />
          ) : (
            <Table pagination={false} columns={columns} dataSource={pageData.filteredUsers} />
          )}
        </Card>
      </Space>
    </SettingsWrapper>
  );
}
