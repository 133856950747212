import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Col, DatePicker, Input } from 'antd';
import { operatorOptions } from './config';
import FilterSelector from './FilterSelector';
import moment from 'dayjs';
import OperatorSelector from './OperatorSelector';

export default function PropertyFilter({ filter, updateFilter }) {
  const { id, statement } = filter;
  const schema = useSelector(state => state.companies.schema);
  const [fieldType, setFieldType] = useState();

  useEffect(() => {
    if (statement.fieldName) {
      setFieldType(_.find(schema, { id: statement.fieldName })?.type);
    }
  }, [statement.fieldName, schema]);
  return (
    <>
      <FilterSelector
        value={statement?.fieldName}
        setValue={val => updateFilter(id, filter => _.set(_.cloneDeep(filter), 'statement.fieldName', val))}
        options={_.map(schema.filter((f) => f.id !== 'goodfit_score'), 'id')}
        width={300}
      />

      {statement.fieldName && (
        <FilterSelector
          value={statement.operator}
          setValue={val => updateFilter(id, filter => _.set(_.cloneDeep(filter), 'statement.operator', val))}
          options={_.map(operatorOptions['property'][fieldType], 'name')}
          width={200}
        />
      )}
      <Col flex="auto">
        {statement.operator &&
          (statement.operator === 'between' ? (
            <DatePicker.RangePicker
              size="small"
              value={statement.value && [moment(statement.value?.gte), moment(statement.value?.lte)]}
              onChange={([from, to]) =>
                updateFilter(id, filter =>
                  _.set(_.cloneDeep(filter), 'statement.value', {
                    gte: from.valueOf(),
                    lte: to.valueOf()
                  })
                )
              }
            />
          ) : (
            <Input
              placeholder="Value"
              size="small"
              value={statement.value}
              onChange={e => updateFilter(id, filter => _.set(_.cloneDeep(filter), 'statement.value', e.target.value))}
            />
          ))}
      </Col>
    </>
  );
};
