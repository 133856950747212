import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import OperatorSelector from './OperatorSelector';
import { Col, Input } from 'antd';
import { operatorOptions } from './config';
import FilterSelector from './FilterSelector';

export default function ContactFilter({ filter, updateFilter }) {
  const { id, statement } = filter;
  const schema = useSelector(state => state.companies.schema);

  return (
    <>
      <FilterSelector
        value={statement.fieldName}
        setValue={val => updateFilter(id, filter => _.set(_.cloneDeep(filter), 'statement.fieldName', val))}
        options={_.keys(schema.contacts)}
        placeholder="Select Field"
      />
      {statement.fieldName && (
        <OperatorSelector
          value={statement.operator}
          setValue={val => updateFilter(id, filter => _.set(_.cloneDeep(filter), 'statement.operator', val))}
          options={operatorOptions['property']['string']}
        />
      )}
      <Col flex="auto">
        {statement.operator && (
          <Input
            placeholder="Value"
            size="small"
            value={statement.value}
            onChange={e => updateFilter(id, filter => _.set(_.cloneDeep(filter), 'statement.value', e.target.value))}
          />
        )}
      </Col>
    </>
  );
}
