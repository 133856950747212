import { Button, Row, Space, Typography } from 'antd';
import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SettingsWrapper from '../../components/SettingsWrapper';

export function HubspotConnector() {
  const code = useQuery().get('code');
  const [isSuccess, setIsSuccess] = useState(null as boolean | null);

  useEffect(() => {
    const sendAuthCode = async (code: string) => {
      try {
        await API.post('integrations', ``, { body: { connectorName: 'HUBSPOT', integrationType: 'HUBSPOT', code } });
        setIsSuccess(true);
      } catch (e) {
        console.log(e);
        setIsSuccess(false);
      }
    };
    if (code) {
      sendAuthCode(code);
    }
  }, [code]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  return (
    <SettingsWrapper title="connectors">
      <Row justify="center" align="middle" style={{ height: '100%' }}>
        <Space direction={'vertical'} align={'center'}>
          <Typography.Text style={{ fontSize: '18px' }}>
            {isSuccess === null && 'Connecting your Hubspot account...'}
            {isSuccess === false &&
              `Failed to connect your Hubspot account.
            Please contact GoodFit team`}
            {isSuccess === true && 'Thank you for connecting your Hubspot account!'}
          </Typography.Text>
          <Button href="/settings/connectors">Go back to settings</Button>
        </Space>
      </Row>
    </SettingsWrapper>
  );
}
