import { combineReducers } from "@reduxjs/toolkit";
import companiesReducer from "./companies/companiesSlice";
import connectorsReducer from "./connectors/connectorsSlice";
import segmentReducer from "./segments/segmentsSlice";
import usersReducer from "./users/usersSlice";
import marketEstimationsReducer from "./admin/marketEstimations/marketEstimationsSlice";

export default combineReducers({
  companies: companiesReducer,
  connectors: connectorsReducer,
  segments: segmentReducer,
  users: usersReducer,
  marketEstimations: marketEstimationsReducer
});
