
import { Button, Tag } from "antd";
import { API } from 'aws-amplify';
import { UserOutlined,BankOutlined } from '@ant-design/icons'
import { Auth } from "aws-amplify";

async function refreshSessionPromise(refreshToken) {
  return new Promise(async (resolve, reject) => {
    const user = await Auth.currentAuthenticatedUser();
    return user.refreshSession(refreshToken, async (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data); // THIS IS YOUR REFRESHED ATTRIBUTES/GROUPS
      }
    });
  });
}

export function GoodfitUserAccountBar({isGoodFit, userEmail}) {

  const onChangeAccountClicked = async (ev) => {
    const newAccount = prompt('Which account');

    if (newAccount) {
      try {
        await API.post('users', '/change-user-account', {
          body: {
            userEmail,
            newAccount
          }
        });
        const session = await Auth.currentSession();
        await refreshSessionPromise(session.getRefreshToken());
        window.location.reload();
      } catch (err) {
        console.log('ERROR', err);
        let message = 'Error changing account.';
        if (err?.response?.data) message = `ERROR ${err.response.data}`;
        alert(message);
      }
    }
  };

  if (!isGoodFit) {
    return <></>
  }

  return (
    <div style={{fontSize:12, position:'fixed',zIndex:999999,bottom:0,left:0,padding:'2px 6px',background:'rgb(180, 148, 250)'}}>
    <Tag><UserOutlined /> {userEmail}</Tag> <Tag><BankOutlined /> {window.goodfitAccountName}</Tag>
    <Button size="small" onClick={onChangeAccountClicked}>Change account</Button>
  </div>
  );
}