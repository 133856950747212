import { useState } from 'react';
import { ScoringRuleSlider } from './ScoringRuleSlider';
import { Row, Col, Input, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ScoringRulePredicate } from './ScoringRulePredicate';

const MAX_RANGE = 10;

export function ScoringRuleItem({ rule, mutateRules, removeRule, onFieldStatsClicked }: any) {
  const updateScore = (value: number) => {
    mutateRules(rule.id, {
      ...rule,
      score: value
    });
  };
  const [value, setValue] = useState(rule.score);

  return (
    <Row style={{ marginBottom: 8 }}>
      <ScoringRulePredicate rule={rule} mutateRules={mutateRules} onFieldStatsClicked={onFieldStatsClicked} />

      <Col style={{ width: '30%', marginRight: 6, paddingLeft: 0, marginLeft: 6, top: 0 }}>
        <ScoringRuleSlider value={value} maxRange={MAX_RANGE} updateScore={updateScore} onValueDrag={setValue} />
      </Col>
      <Col style={{ width: '6%', marginRight: 4, paddingLeft: 0, marginLeft: 0, top: 0 }}>
        <Input
          value={value}
          type="number"
          onChange={(ev: any) => {
            let value = ev.target.value;
            if (value > MAX_RANGE) value = MAX_RANGE;
            if (value < -MAX_RANGE) value = -MAX_RANGE;
            setValue(value);
            updateScore(value);
          }}
        />
      </Col>
      <Col style={{ paddingLeft: 0 }}>
        <Button onClick={() => removeRule(rule.id)} style={{ paddingLeft: 8, paddingRight: 8 }}>
          <CloseOutlined />
        </Button>
      </Col>
    </Row>
  );
}
