import { Button, Card, Col, Dropdown, Menu, Row, Spin } from "antd";

import ConnectorCard from './ConnectorCard';
import SettingsWrapper from '../../components/SettingsWrapper';
import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import hubspotLogo from '../../images/hubspot_logo.svg';
import webhooksLogo from '../../images/webhooks_logo.svg';
import salesforceLogo from '../../images/salesforce_logo.svg';
import config from '../../config';
import pipedriveLogo from '../../images/pipedrive_logo.svg';
import { WebhooksDrawer } from './WebhooksDrawer';
import { PipedriveDrawer } from './PipedriveDrawer';

export function Connectors() {
  const [integrations, setIntegrations] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isOpenWebhookDrawer, setIsOpenWebhookDrawer] = useState(false);
  const [isOpenPipedriveDrawer, setIsOpenPipedriveDrawer] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState({});
  const getIntegrations = async (updateIsFetching = true) => {
    updateIsFetching && setIsFetching(true);
    // @ts-ignore
    setIntegrations(
      (await API.get('integrations', `?getCredentials=true`)).integrations
    );
    updateIsFetching && setIsFetching(false);
  };

  useEffect(() => {
    // we just need to run it once on initial load
    getIntegrations();
  }, []);

  const upsertIntegration = async ({
    connectorName,
    integrationType,
    credentials
  }) => {
    setIsFetching(true);
    try {
      await API.post('integrations', ``, {
        body: { connectorName, integrationType, credentials }
      });
      getIntegrations(false);
    } finally {
      setIsFetching(false);
    }
    setIsOpenWebhookDrawer(false);
    setIsOpenPipedriveDrawer(false);
  };

  // @ts-ignore
  const findIntegration = type =>
    integrations?.find(integration => integration.integrationType === type) || {
      integrationType: type
    };

  return (
    <SettingsWrapper title="connectors">
      <Card bordered={false}>
        <Row gutter={[32, 32]}>
          {isFetching ? (
            <Spin />
          ) : (
            <>
              <Col span={12}>
                <ConnectorCard
                  integration={findIntegration('SALESFORCE')}
                  refreshIntegrations={() => getIntegrations(false)}
                  connectorSetting={{
                    imgUrl: salesforceLogo,
                    connectElement: connected => (
                      <Dropdown
                        placement="bottomCenter"
                        overlay={
                          <Menu
                            onClick={({ key }) => {
                              key === "production"
                                ? (window.location.href = config.salesforce.AUTH_URL)
                                : (window.location.href = config.salesforce.AUTH_URL.replace("login.", "test.").replace(
                                  "connectors/salesforce",
                                  "connectors/salesforce-sandbox"
                                ));
                            }}
                          >
                            <Menu.Item key="production">Production</Menu.Item>
                            <Menu.Item key="sandbox">Sandbox</Menu.Item>
                          </Menu>
                        }
                      >
                        <Button size="small">
                          {connected ? "Reconnect" : "Connect"}
                        </Button>
                      </Dropdown>
                    )
                  }}
                />
              </Col>
              <Col span={12}>
                <ConnectorCard
                  integration={findIntegration('HUBSPOT')}
                  refreshIntegrations={() => getIntegrations(false)}
                  connectorSetting={{
                    imgUrl: hubspotLogo,
                    connectElement: connected => (
                      <Button
                        size={'small'}
                        onClick={() =>
                          (window.location.href = `https://app.hubspot.com/oauth/authorize?client_id=6d170897-421e-436e-8e7b-18d333d35a6b&redirect_uri=${window.location.origin}/connectors/hubspot&scope=oauth%20integration-sync%20crm.objects.contacts.read%20crm.import%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.schemas.contacts.read%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.deals.write%20crm.schemas.companies.read%20crm.schemas.companies.write%20crm.schemas.contacts.write%20crm.schemas.deals.read%20crm.schemas.deals.write`)
                        }
                      >
                        {connected ? 'Reconnect' : 'Connect'}
                      </Button>
                    )
                  }}
                />
              </Col>
              <Col span={12}>
                <ConnectorCard
                  integration={findIntegration('WEBHOOK')}
                  refreshIntegrations={() => getIntegrations(false)}
                  connectorSetting={{
                    imgUrl: webhooksLogo,
                    connectElement: connected => (
                      <Button
                        size={'small'}
                        onClick={() => {
                          setSelectedIntegration(findIntegration('WEBHOOK'));
                          setIsOpenWebhookDrawer(true);
                        }}
                      >
                        {connected ? 'Reconnect' : 'Connect'}
                      </Button>
                    )
                  }}
                />
              </Col>
              <Col span={12}>
                <ConnectorCard
                  integration={findIntegration('PIPEDRIVE')}
                  refreshIntegrations={() => getIntegrations(false)}
                  connectorSetting={{
                    imgUrl: pipedriveLogo,
                    connectElement: connected => (
                      <Button
                        size={'small'}
                        onClick={() => {
                          setSelectedIntegration(findIntegration('PIPEDRIVE'));
                          setIsOpenPipedriveDrawer(true);
                        }}
                      >
                        {connected ? 'Reconnect' : 'Connect'}
                      </Button>
                    )
                  }}
                />
              </Col>
            </>
          )}
        </Row>
      </Card>
      <WebhooksDrawer
        integration={selectedIntegration}
        open={isOpenWebhookDrawer}
        onSave={upsertIntegration}
        onClose={() => setIsOpenWebhookDrawer(false)}
      />
      <PipedriveDrawer
        integration={selectedIntegration}
        open={isOpenPipedriveDrawer}
        onSave={upsertIntegration}
        onClose={() => setIsOpenPipedriveDrawer(false)}
      />
    </SettingsWrapper>
  );
}
