import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Table, Button, Drawer, Row, Col, Statistic, Typography, Divider, message, Progress } from 'antd';
import { API } from 'aws-amplify';
import { CopyOutlined } from '@ant-design/icons';

function formatRange(from: number, to: number) {
  if (!from && to === 1) {
    return '0';
  }
  if (from === to) {
    return formatNumber(from);
  }
  if (from === to - 1) {
    return formatNumber(from);
  }
  if (from && !to) {
    return `${formatNumber(from)}+`;
  }
  return `${formatNumber(from)} → ${formatNumber(to)}`;
}

function formatNumber(value: any): string {
  return !_.isNil(value) ? value.toLocaleString('en-US') : '-';
}

function safeDivide(a: number, b: number) {
  if (!b) return NaN;
  return a / b;
}

function formatPercent(value: any): string {
  if (_.isNil(value)) return '-';
  return formatNumber(Math.round(value * 10000) / 100) + '%';
}

function formatPercent3(value: any): string {
  if (_.isNil(value)) return '-';
  return formatNumber(Math.round(value * 100000) / 1000) + '%';
}

function copyValuesToClipboard(fieldStats: any) {
  const termCounts = fieldStats?.terms || [];
  const terms = termCounts.map((tc: any) => tc.term);
  const data = terms.join('\n');
  navigator.clipboard.writeText(data);

  if (terms.length === 1000) {
    message.success('First 1000 values copied to clipboard');
  } else {
    message.success('Values copied to clipboard');
  }
}

type FieldDrawerProps = {
  fieldInfo: any;
  open: boolean;
  onClose: Function;
};

export function FieldDrawer({ fieldInfo, open, onClose }: FieldDrawerProps) {
  const [fieldStats, setFieldStats] = useState<any>(null);

  async function loadFieldStats(fieldInfo: any) {
    setFieldStats(null);
    const result = await API.post('data', '/field-stats', {
      body: {
        fieldName: fieldInfo.title,
        fieldType: fieldInfo.type
      }
    });
    setFieldStats(result);
  }

  useEffect(() => {
    if (fieldInfo !== null) loadFieldStats(fieldInfo);
  }, [fieldInfo]);

  if (!fieldInfo) return <></>;

  const showStats = fieldInfo.title !== 'goodfit_score'; // We dont show stats for goodfit_score as its calculated on the fly

  const coveragePercent = fieldStats ? 1 - fieldStats?.missingCount / fieldStats?.totalCount : undefined;

  return (
    <Drawer width={700} open={open} placement="right" title={`Field: ${fieldInfo?.title}`} onClose={() => onClose()}>
      <Typography.Title level={5}>Description</Typography.Title>
      <p>{fieldInfo?.description || 'No description available'}</p>
      <p>{fieldInfo?.record?.publicDescriptionAdditionalNotes}</p>
      <Divider />
      {showStats && (
        <>
          <Typography.Title level={5}>Statistics</Typography.Title>
          <Row gutter={16}>
            <Col span={8}>
              <Statistic title="Coverage" loading={!fieldStats} value={coveragePercent} formatter={formatPercent} />
            </Col>
            <Col span={8}>
              <Statistic
                title="Missing values"
                loading={!fieldStats}
                value={fieldStats?.missingCount}
                formatter={formatNumber}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title="Unique values"
                loading={!fieldStats}
                value={fieldStats?.uniqueCount}
                formatter={formatNumber}
              />
            </Col>
            {fieldStats?.numericStats && (
              <>
                <Col span={8}>
                  <Statistic
                    title="Minimum"
                    loading={!fieldStats}
                    value={fieldStats?.numericStats?.min}
                    formatter={formatNumber}
                  />
                </Col>
                <Col span={8}>
                  <Statistic
                    title="Maximum"
                    loading={!fieldStats}
                    value={fieldStats?.numericStats?.max}
                    formatter={formatNumber}
                  />
                </Col>
                <Col span={8}>
                  <Statistic
                    title="Average"
                    loading={!fieldStats}
                    value={fieldStats?.numericStats?.avg}
                    formatter={formatNumber}
                  />
                </Col>
              </>
            )}
          </Row>
          {fieldStats?.terms && fieldStats?.uniqueCount > 0 && (
            <>
              <Divider />
              <Typography.Title level={5}>Unique values</Typography.Title>
              <Table
                bordered={true}
                columns={[
                  { dataIndex: 'term', title: 'Value', ellipsis: true, width: '50%' },
                  { dataIndex: 'count', title: 'Count', ellipsis: true, width: '20%' },
                  {
                    key: 'percent',
                    render: (v: any) => {
                      const r = Math.round(10000 * safeDivide(v.count, fieldStats?.totalCount)) / 100;
                      return <Progress style={{ width: '80%' }} percent={r} />;
                    },
                    title: 'Percent',
                    ellipsis: true,
                    width: '30%'
                  }
                ]}
                dataSource={fieldStats?.terms || []}
                loading={!fieldStats}
                pagination={{
                  position: ['bottomCenter'],
                  pageSize: 50,
                  showSizeChanger: false,
                  hideOnSinglePage: true
                }}
                size="small"
                style={{ maxWidth: '100%' }}
              />
              <div style={{ marginTop: 8 }}>
                <Button
                  icon={<CopyOutlined />}
                  onClick={() => {
                    copyValuesToClipboard(fieldStats);
                  }}
                >
                  Copy values to clipboard
                </Button>
              </div>
            </>
          )}
          {fieldStats?.numericHistogram && fieldStats?.numericHistogram?.buckets.length > 0 && (
            <>
              <Divider />
              <Typography.Title level={5}>Numeric ranges</Typography.Title>
              <Table
                bordered={true}
                columns={[
                  {
                    dataIndex: 'key',
                    title: 'Range',
                    ellipsis: true,
                    width: '50%',
                    render: (text, row) => formatRange(row.from, row.to)
                  },
                  { dataIndex: 'doc_count', title: 'Count', ellipsis: true, width: '20%' },
                  {
                    key: 'percent',
                    render: (v: any) => {
                      const r = Math.round(10000 * safeDivide(v.doc_count, fieldStats?.totalCount)) / 100;
                      return <Progress style={{ width: '80%' }} percent={r} />;
                    },
                    title: 'Percent',
                    ellipsis: false,
                    width: '35%'
                  }
                ]}
                dataSource={fieldStats?.numericHistogram.buckets || []}
                loading={!fieldStats}
                pagination={{
                  position: ['bottomCenter'],
                  pageSize: 50,
                  showSizeChanger: false,
                  hideOnSinglePage: true
                }}
                size="small"
                style={{ maxWidth: '100%' }}
              />
            </>
          )}
          {fieldInfo?.record?.dynamicConfig && (
            <>
              <Divider />
              <Typography.Title level={5}>Configuration</Typography.Title>
              <pre style={{ height: 300, fontSize: 12 }}>
                {JSON.stringify(fieldInfo?.record?.dynamicConfig, null, 2)}
              </pre>
            </>
          )}
        </>
      )}
    </Drawer>
  );
}
