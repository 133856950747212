import _ from 'lodash';
import { create } from 'zustand';
import { API } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import {
  DataDirectoryEntry,
  Integration,
  IntegrationExternalFieldDescription,
  IntegrationFieldUpdateStrategy,
  IntegrationFieldMappingInput
} from './types';
import { DEFAULT_CONTACTS_FIELDS } from './contacts';
import { METADATA_FIELDS } from './metadata';
import { getFieldType, getPicklistValues } from './createFieldUtils';

export interface DataDirectoryStore {
  isDataDirectoryLoading: boolean;
  dataDirectoryCompanyFields: DataDirectoryEntry[];
  dataDirectoryContactFields: DataDirectoryEntry[];
  integrations: Integration[];
  sourcingCriteria: any;
  isContactsEnabled: boolean;
  integrationContactFieldDescriptions: Record<string, IntegrationExternalFieldDescription[]>;
  integrationCompanyFieldDescriptions: Record<string, IntegrationExternalFieldDescription[]>;
  loadDataDirectory: () => Promise<void>;
  addFieldMapping: (integrationId: string, recordType: string, fieldName: string) => void;
  removeFieldMapping: (integrationId: string, recordType: string, fieldMappingId: string) => void;
  modifyFieldMapping: (
    integrationId: string,
    recordType: string,
    fieldMappingId: string,
    props: any,
    skipSave?: boolean
  ) => Promise<void>;
  createExternalField: (
    integration: Integration,
    recordType: string,
    mapping: IntegrationFieldMappingInput,
    externalFieldName: string
  ) => Promise<{ success?: boolean; error?: string }>;
}

export function checkMapping(mapping: IntegrationFieldMappingInput) {
  if (!mapping.externalName) return 'Please select CRM field';
  return null;
}

function stateKeyName(recordType: string) {
  return recordType === 'contact' ? 'dataDirectoryContactFields' : 'dataDirectoryCompanyFields';
}

export const useDataDirectoryStore = create<DataDirectoryStore>()(set => ({
  dataDirectoryCompanyFields: [],
  dataDirectoryContactFields: [],
  isDataDirectoryLoading: true,
  isContactsEnabled: false,
  integrations: [],
  sourcingCriteria: null,
  integrationContactFieldDescriptions: {},
  integrationCompanyFieldDescriptions: {},
  addFieldMapping: async (integrationId: string, recordType: string, fieldName: string) =>
    set((state: DataDirectoryStore) => {
      const newState = {
        ...state,
        [stateKeyName(recordType)]: state[stateKeyName(recordType)].map(fd => {
          const newMapping = {
            id: uuidv4(),
            integrationId,
            internalName: fieldName,
            externalName: null,
            updateStrategy: IntegrationFieldUpdateStrategy.OVERWRITE_ALWAYS,
            isSaving: false // Only save on first edit
          };
          if (fd.datasetName === fieldName) {
            return {
              ...fd,
              mappings: [...fd.mappings, newMapping]
            };
          }
          return fd;
        })
      };
      collectAndSaveMappings(newState, integrationId);
      return newState;
    }),
  removeFieldMapping: async (integrationId: string, recordType: string, fieldMappingId: string) =>
    set((state: DataDirectoryStore) => {
      const newState = {
        ...state,
        [stateKeyName(recordType)]: state[stateKeyName(recordType)].map(fd => {
          return {
            ...fd,
            mappings: fd.mappings.filter(mapping => mapping.id !== fieldMappingId)
          };
        })
      };
      collectAndSaveMappings(newState, integrationId);
      return newState;
    }),
  modifyFieldMapping: async (
    integrationId: string,
    recordType: string,
    fieldMappingId: string,
    updates: any,
    skipSave?: boolean
  ) =>
    set((state: DataDirectoryStore) => {
      const newState = {
        ...state,
        [stateKeyName(recordType)]: state[stateKeyName(recordType)].map(fd => {
          return {
            ...fd,
            mappings: fd.mappings.map(mapping => {
              if (mapping.id === fieldMappingId) {
                return {
                  ...mapping,
                  isSaving: true,
                  ...updates
                };
              }
              return mapping;
            })
          };
        })
      };
      if (!skipSave) collectAndSaveMappings(newState, integrationId, recordType, fieldMappingId);
      return newState;
    }),
  loadDataDirectory: async () => {
    const results = await Promise.all([loadDataDirectory(), loadIntegrations()]);
    const integrations = results[1] as Integration[];
    const config = results[0];

    // Now we need to map these two sets of entities into dataDirectoryCompanyFields and dataDirectoryContactFields

    // Group mappings by dataset field name
    const mappingLookupCompanies = {} as Record<string, any[]>;
    const mappingLookupContacts = {} as Record<string, any[]>;
    for (const integration of integrations) {
      for (const mapping of (integration.mappingSettings?.companies || []) as any[]) {
        const fieldName = mapping.internalName;
        if (!(fieldName in mappingLookupCompanies)) mappingLookupCompanies[fieldName] = [];
        mappingLookupCompanies[fieldName].push({
          ...mapping,
          integrationId: integration.id,
          id: mapping.id || uuidv4(), // Some mappings dont have ids yet
          updateStrategy: mapping.updateStrategy || IntegrationFieldUpdateStrategy.OVERWRITE_ALWAYS,
          isSaving: false
        });
      }
      for (const mapping of (integration.mappingSettings?.contacts || []) as any[]) {
        const fieldName = mapping.internalName;
        if (!(fieldName in mappingLookupContacts)) mappingLookupContacts[fieldName] = [];
        mappingLookupContacts[fieldName].push({
          ...mapping,
          integrationId: integration.id,
          id: mapping.id || uuidv4(), // Some mappings dont have ids yet
          updateStrategy: mapping.updateStrategy || IntegrationFieldUpdateStrategy.OVERWRITE_ALWAYS,
          isSaving: false
        });
      }
    }

    // Map field mappings options for CCM companies and default contacts
    const dataDirectoryCompanyFields = [...config.fieldDefinitions, ...METADATA_FIELDS].map(fd => ({
      ...fd,
      datasetName: fd.externalName || fd.dataBlockField.publicName,
      ccmName: fd.internalName,
      mappings: mappingLookupCompanies[fd.externalName] || []
    }));
    const dataDirectoryContactFields = [...DEFAULT_CONTACTS_FIELDS, ...METADATA_FIELDS].map(fd => ({
      ...fd,
      datasetName: fd.externalName,
      ccmName: fd.internalName,
      mappings: mappingLookupContacts[fd.externalName] || []
    }));

    // Trigger loading the integration field descriptions
    for (const integration of integrations) {
      loadIntegrationFieldNames(integration.id).then(result => {
        set((state: any) => ({
          ...state,
          integrationCompanyFieldDescriptions: {
            ...state.integrationCompanyFieldDescriptions,
            [integration.id]: result.accountFields
          },
          integrationContactFieldDescriptions: {
            ...state.integrationContactFieldDescriptions,
            [integration.id]: result.contactFields
          }
        }));
      });
    }

    set((state: any) => ({
      ...state,
      isDataDirectoryLoading: false,
      integrations,
      sourcingCriteria: config.sourcingCriteria,
      dataDirectoryCompanyFields,
      dataDirectoryContactFields,
      isContactsEnabled: config.isContactsEnabled
    }));
  },
  createExternalField: async (
    integration: Integration,
    recordType: string,
    mapping: IntegrationFieldMappingInput,
    externalFieldName: string
  ) => {
    const state = useDataDirectoryStore.getState();
    const externalFieldType = getFieldType(recordType, mapping.internalName, integration, state);
    const picklistValues = await getPicklistValues(mapping.internalName, externalFieldType);

    const { jobId } = await API.post('integrations', `/${integration.id}/create-external-fields`, {
      body: {
        fields: [
          {
            recordType,
            internalFieldName: mapping.internalName,
            externalFieldName,
            externalFieldType,
            picklistValues
          }
        ]
      }
    });

    let response;
    do {
      await new Promise(resolve => setTimeout(resolve, 1000));
      response = await API.get('integrations', `/${integration.id}/create-external-fields/${jobId}`, {});
    } while (response?.status !== 'SUCCEEDED' && response?.status !== 'FAILED');

    if (response?.status !== 'FAILED' && response.results[0]?.externalName) {
      state.modifyFieldMapping(
        integration.id,
        recordType,
        mapping.id,
        { externalName: response.results[0]?.externalName },
        false
      );

      loadIntegrationFieldNames(integration.id).then(result => {
        set((state: any) => ({
          ...state,
          integrationCompanyFieldDescriptions: {
            ...state.integrationCompanyFieldDescriptions,
            [integration.id]: result.accountFields
          },
          integrationContactFieldDescriptions: {
            ...state.integrationContactFieldDescriptions,
            [integration.id]: result.contactFields
          }
        }));
      });
      return { success: true };
    }

    return { success: false, error: response.results[0]?.errorMessage || 'Something went wrong' };
  }
}));

async function loadIntegrationFieldNames(integrationId: string) {
  return await API.get('integrations', `/${integrationId}/describe-external-fields`, {});
}

async function loadIntegrations() {
  // We filter out webhook as this page does not need to know about them
  return (await API.get('integrations', '/', {})).integrations.filter((r: any) => r.integrationType !== 'WEBHOOK');
}

async function loadDataDirectory() {
  return await API.get('data', '/directory', {});
}

function collectMappings(entries: DataDirectoryEntry[], integrationId: string) {
  const filteredMappings: any[] = [];
  for (const entry of entries) {
    for (const mapping of entry.mappings) {
      if (mapping.integrationId === integrationId && mapping.externalName) {
        filteredMappings.push(_.omit(mapping, ['integrationId']));
      }
    }
  }
  return filteredMappings;
}

async function collectAndSaveMappings(
  state: DataDirectoryStore,
  integrationId: string,
  triggeringRecordType?: string,
  triggeringFieldMappingId?: string
) {
  // Collect all the mappings for this integrationId from all entries
  const mappingSettings = {
    companies: collectMappings(state.dataDirectoryCompanyFields, integrationId),
    contacts: collectMappings(state.dataDirectoryContactFields, integrationId)
  };
  await API.post('integrations', `/${integrationId}`, {
    body: {
      mappingSettings
    }
  });
  if (triggeringRecordType && triggeringFieldMappingId) {
    state.modifyFieldMapping(integrationId, triggeringRecordType, triggeringFieldMappingId, { isSaving: false }, true);
  }
}
