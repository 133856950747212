export const METADATA_FIELDS = [
  {
    externalName: 'system:created_at',
    isSystem: true,
    internalName: 'system:created_at',
    dataBlockField: {
      publicDescription: 'Date this record was added to your Goodfit dataset',
      type: 'Date',
      salesforceType: 'date',
      hubspotType: 'date'
    },
    presentInCCM: true
  },
  {
    externalName: 'system:updated_at',
    isSystem: true,
    internalName: 'system:updated_at',
    dataBlockField: {
      publicDescription: 'Date this record was last updated in your Goodfit dataset',
      type: 'Date',
      salesforceType: 'date',
      hubspotType: 'date'
    },
    presentInCCM: true
  },
  {
    externalName: 'system:last_synced_at',
    isSystem: true,
    internalName: 'system:last_synced_at',
    dataBlockField: {
      publicDescription: '',
      type: 'Date',
      salesforceType: 'date',
      hubspotType: 'date'
    },
    presentInCCM: true
  },
  {
    externalName: 'system:created_by_goodfit',
    isSystem: true,
    internalName: 'system:created_by_goodfit',
    dataBlockField: {
      publicDescription: 'Was this record originally created by Goodfit in your CRM?',
      type: 'Boolean',
      salesforceType: 'boolean',
      hubspotType: 'boolean'
    },
    presentInCCM: true
  },
  {
    externalName: 'system:enriched_by_goodfit',
    isSystem: true,
    internalName: 'system:enriched_by_goodfit',
    dataBlockField: {
      publicDescription: 'Was this record enriched by Goodfit in your CRM?',
      type: 'Boolean',
      salesforceType: 'boolean',
      hubspotType: 'boolean'
    },
    presentInCCM: true
  },
  {
    externalName: 'system:source',
    isSystem: true,
    internalName: 'system:source',
    dataBlockField: {
      publicDescription: 'Source of this record (set to Goodfit if this record was created by Goodfit',
      type: 'String',
      salesforceType: 'string',
      hubspotType: 'string'
    },
    presentInCCM: true
  }
];
