import React, { useState } from 'react';
import _ from 'lodash';
import { Row, Col, Button } from 'antd';
import Filters from './Filters';
import { fetchCompanies, updateFilters } from '../../features/companies/companiesSlice';
import { useDispatch, useSelector } from 'react-redux';
import SegmentModal from '../Segments/SegmentModal';
import AlertModal from '../Alerts/AlertModal';
import { hasFeatureFlag, FeatureFlag } from '../../featureFlags';

export default function FiltersPanel() {
  const globalFilters = useSelector(state => state.companies.filters);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(globalFilters);

  const [showSegmentModal, setShowSegmentModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);

  const onSearch = filters => {
    dispatch(updateFilters(filters));
    dispatch(fetchCompanies());
  };

  const doSetFilters = (filters) => {
    setFilters(filters);
    // As we now hide the search button need to refresh
    if (filters.length===0) {
      dispatch(updateFilters(filters));
      dispatch(fetchCompanies());
    }
  }

  const shouldShowAlerts = hasFeatureFlag(FeatureFlag.showAlerts);

  return (
    <>
      <Filters filters={filters} setFilters={doSetFilters} />
      {!_.isEmpty(filters) && (
        <Row style={{ marginTop: '16px' }} gutter={16}>
          <Col flex="1 1 200px">
            <Button type="primary" size="small" onClick={() => onSearch(filters)}>
              Search
            </Button>
          </Col>

          <Col flex="0 1">
            <Button type="primary" size="small" onClick={() => setShowSegmentModal(true)}>
              Save As Talk Track
            </Button>
          </Col>
          {(shouldShowAlerts && (
          <Col flex="0 1">
            <Button type="primary" size="small" onClick={() => setShowAlertModal(true)}>
              Save As Alert
            </Button>
          </Col>
          ))}
          <SegmentModal visible={showSegmentModal} onCancel={() => setShowSegmentModal(false)} segment={{ filters }} />
          <AlertModal visible={showAlertModal} onCancel={() => setShowAlertModal(false)} alert={{ filters }} />
        </Row>
      )}
    </>
  );
}
