import { Col, Row, Card, Button, Typography, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import React, { useState } from 'react';
import SegmentModal from './SegmentModal';
import { useSelector } from 'react-redux';

export default function SegmentPage() {
  const [showSegmentModal, setShowSegmentModal] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState({});
  const { segments, isFetching } = useSelector(state => state.segments);

  return (
    <Row gutter={16}>
      {isFetching ? (
        <Spin
          style={{
            margin: 0,
            top: '70%',
            left: '50%',
            position: 'absolute'
          }}
        />
      ) : (
        <>
          {segments.map((segment, index) => (
            <Col key={index} span={6} style={{ marginTop: 16 }}>
              <Card
                hoverable
                onClick={() => {
                  setSelectedSegment(segment);
                  setShowSegmentModal(true);
                }}
                style={{
                  height: 100
                }}
                bodyStyle={{ padding: 0, height: '100%' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '75%'
                  }}
                >
                  <Typography.Paragraph strong>{segment.name}</Typography.Paragraph>
                </div>
                <div
                  style={{
                    marginLeft: 8
                  }}
                >
                  {/* <ClockCircleTwoTone /> */}
                  <Typography.Text disabled style={{ fontSize: 11 }}>{`Modified At: ${new Date(
                    segment.updatedAt
                  ).toLocaleString()}`}</Typography.Text>
                </div>
              </Card>
            </Col>
          ))}
          <Col span={6} style={{ marginTop: 16 }}>
            <Button
              type="dashed"
              block
              style={{ height: 100 }}
              onClick={() => {
                setSelectedSegment({});
                setShowSegmentModal(true);
              }}
              icon={<PlusOutlined />}
            >
              Create Talk Track
            </Button>
          </Col>
        </>
      )}
      <SegmentModal visible={showSegmentModal} segment={selectedSegment} onCancel={() => setShowSegmentModal(false)} />
    </Row>
  );
}
