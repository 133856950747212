import React, { useEffect, useState } from 'react';
import AppSiteWrapper from '../components/SiteBasics/AppSiteWrapper';
import { Row, Card, Col, Spin, Progress } from 'antd';
import { API } from 'aws-amplify';

const correlationData = [
  { propertyName: 'Top Traffic Country: UK', correlation: 0.65 },
  { propertyName: 'Top Traffic Country: US', correlation: 0.47 },
  { propertyName: 'Country: US', correlation: 0.24 },
  { propertyName: 'Traffic', correlation: 0.12 },
  { propertyName: 'Employee Count', correlation: 0.1 }
];

export default function Dashboard() {
  const [data, setData] = useState({});
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const getDashboardStats = async () => {
      const basicStats = await API.get('dashboard', '/basic-stats', {});
      const funnelStats = await API.get('dashboard', '/funnel-stats', {});
      setData({
        basicStats,
        funnelStats
      });
      setIsFetching(false);
    };

    getDashboardStats();
  }, []);

  return (
    <AppSiteWrapper>
      <div
        style={{
          marginTop: '60px',
          fontSize: 20,
          color: '#1e4059',
          fontWeight: 600,
          marginLeft: 5
          //   display: "flex",
          //   justifyContent: "center",
        }}
      >
        Overview
      </div>
      <Card
        // style={{ marginTop: "80px" }}
        style={{ minHeight: 100 }}
        bodyStyle={{
          paddingTop: 16,
          paddingBotom: 16,
          paddingLeft: 0,
          paddingRight: 0
        }}
      >
        {isFetching ? (
          <Spin
            style={{
              margin: 0,
              top: '40%',
              left: '50%',
              position: 'absolute'
            }}
          />
        ) : (
          <Row style={{ justifyContent: 'center' }}>
            <BasicDashBoardTile metric={data?.basicStats?.accountsCount} title="Accounts Count" />
            <BasicDashBoardTile
              metric={data?.basicStats?.netNewAccountsCount}
              title="New Accounts"
              subtitle="Last 30 Days"
            />
            <BasicDashBoardTile
              metric={data?.basicStats?.changesCount}
              title="Updated Data Points"
              subtitle="Last 30 Days"
              isLast
            />
          </Row>
        )}
      </Card>
      <Row style={{ marginTop: '40px' }}>
        <Col span={8}>
          <div
            style={{
              fontSize: 20,
              color: '#1e4059',
              fontWeight: 600,
              marginLeft: 5
            }}
          >
            Funnel Analysis
          </div>
          {/* <span
            style={{
              zIndex: 1,
              position: "absolute",
              top: "40%",
              left: "32%",
              fontWeight: 600,
              color: "#1e4059",
              fontSize: 18,
            }}
          >
            <span style={{ marginRight: 8 }} role="img" aria-label="">
              🚧
            </span>
            <span>Coming Soon!</span>
          </span> */}

          <Card style={{ height: 400 }} bodyStyle={{ alignItems: 'center', height: '100%' }}>
            {isFetching ? (
              <Spin
                style={{
                  margin: 0,
                  top: '40%',
                  left: '50%',
                  position: 'absolute'
                }}
              />
            ) : (
              <>
                <Row style={{ marginBottom: 36 }}>
                  <BasicDashboardMetric>{data.funnelStats?.opportunityCount || 0}</BasicDashboardMetric>
                  <BasicDashboardTitle>Opportunities</BasicDashboardTitle>
                </Row>

                <Row style={{ marginBottom: 36 }}>
                  <BasicDashboardMetric>{data.funnelStats?.wonCount || 0}</BasicDashboardMetric>
                  <BasicDashboardTitle>Won</BasicDashboardTitle>
                </Row>
                <Row>
                  <BasicDashboardMetric>{data.funnelStats?.lostCount || 0}</BasicDashboardMetric>
                  <BasicDashboardTitle>Lost</BasicDashboardTitle>
                </Row>
              </>
            )}
          </Card>
        </Col>
        <Col flex="auto" style={{ marginLeft: 32 }}>
          <div
            style={{
              //   marginTop: "60px",
              fontSize: 20,
              color: '#1e4059',
              fontWeight: 600,
              marginLeft: 5
              //   display: "flex",
              //   justifyContent: "center",
            }}
          >
            Correlation Analysis
          </div>
          <span
            style={{
              zIndex: 1,
              position: 'absolute',
              top: '40%',
              left: '43%',
              fontWeight: 600,
              color: '#1e4059',
              fontSize: 18
            }}
          >
            <span style={{ marginRight: 8 }} role="img" aria-label="">
              🚧
            </span>
            <span>Coming Soon!</span>
          </span>
          <Card style={{ height: 400 }} bodyStyle={{ filter: 'blur(12px)', zIndex: 0 }}>
            {correlationData.map(item => (
              <CorrelationRow {...item} />
            ))}
          </Card>
        </Col>
      </Row>
    </AppSiteWrapper>
  );
}

const CorrelationRow = ({ propertyName, correlation }) => {
  return (
    <Row style={{ marginBottom: 32 }} gutter={8}>
      <Col span={6}>
        <div style={{ display: 'flex', fontWeight: 500, fontSize: 15 }}>{propertyName}</div>
      </Col>
      <Col span={16}>
        <Progress percent={correlation * 100} strokeColor="#4BB543" />
      </Col>
      <Col span={2}>
        <div
          style={{
            fontSize: 15,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          476
        </div>
      </Col>
    </Row>
  );
};

const BasicDashBoardTile = ({ metric, title, subtitle, isLast }) => {
  return (
    <Col span={8} style={{ borderRight: !isLast && '1px solid #f0f0f0' }}>
      <BasicDashboardMetric>{metric || '–'}</BasicDashboardMetric>
      <BasicDashboardTitle>{title}</BasicDashboardTitle>
      <BasicDashboardSubTitle>{subtitle}</BasicDashboardSubTitle>
    </Col>
  );
};

const BasicDashboardMetric = ({ children, color }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        fontSize: '45px',
        color: color ? color : '#78c1f5', // "#1e4059",
        fontWeight: 500,
        lineHeight: 1.4
      }}
    >
      {children}
    </div>
  );
};

const BasicDashboardTitle = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        fontSize: '22px',
        color: '#1e4059',
        fontWeight: 400,
        lineHeight: 1
      }}
    >
      {children}
    </div>
  );
};

const BasicDashboardSubTitle = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        color: 'grey',
        fontSize: '12px'
      }}
    >
      {children}
    </div>
  );
};
