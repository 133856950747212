import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import uuid from 'uuid';
import { convertFiltersToQuery } from '../companies/companiesSlice';

const segmentsSlice = createSlice({
  name: 'segments',
  initialState: {
    segments: [],
    alerts: [],
    isFetching: false,
    isFetched: false
  },
  reducers: {
    requestStart(state, action) {
      state.isFetching = true;
    },
    receiveSegments(state, action) {
      state.segments = action.payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    addSegment(state, action) {
      state.segments.push(action.payload);
    },
    modifySegment(state, action) {
      const updatedSegment = action.payload;
      state.segments = state.segments.map(segment => (segment.id === updatedSegment.id ? updatedSegment : segment));
    },
    removeSegment(state, action) {
      state.segments = state.segments.filter(segment => segment.id !== action.payload.id);
    },
    receiveAlerts(state, action) {
      state.alerts = action.payload;
      state.isFetching = false;
    },
    addAlert(state, action) {
      state.alerts.push(action.payload);
    },
    removeAlert(state, action) {
      state.alerts.pop(action.payload);
    },
    modifyAlert(state, action) {
      const updatedAlert = action.payload;
      state.alerts = state.alerts.map(alert => (alert.id === updatedAlert.id ? updatedAlert : alert));
    }
  }
});

export const {
  requestStart,
  receiveSegments,
  addSegment,
  modifySegment,
  removeSegment,
  receiveAlerts,
  addAlert,
  removeAlert,
  modifyAlert
} = segmentsSlice.actions;

export const createSegment = segment => async dispatch => {
  const query = convertFiltersToQuery(segment.filters);

  const newSegment = {
    id: uuid.v4(),
    ...segment,
    query: JSON.stringify(query),
    filters: JSON.stringify(segment.filters)
  };

  await API.post('segments', '/create', {
    body: newSegment
  });
  dispatch(fetchSegments(segment));
};

export const updateSegment = segment => async dispatch => {
  const query = convertFiltersToQuery(segment.filters);

  await API.post('segments', '/update', {
    body: {
      ...segment,
      filters: JSON.stringify(segment.filters),
      query: JSON.stringify(query)
    }
  });

  dispatch(modifySegment(segment));
};

export const deleteSegment = segment => async dispatch => {
  await API.del('segments', `/${segment.id}`);

  dispatch(removeSegment(segment));
};

export const fetchSegments = () => async dispatch => {
  dispatch(requestStart());
  const segments = await API.get('segments', '/list');
  dispatch(receiveSegments(segments));
};

export const fetchAlerts = () => async dispatch => {
  dispatch(requestStart());
  const alerts = await API.get('alerts', '/');
  dispatch(receiveAlerts(alerts));
};

export const createAlert = alert => async dispatch => {
  const query = convertFiltersToQuery(alert.filters);

  const newAlert = {
    id: uuid.v4(),
    ...alert,
    query
  };

  await API.post('alerts', '/', {
    body: {
      ...newAlert,
      filters: JSON.stringify(newAlert.filters),
      query: JSON.stringify(newAlert.query)
    }
  });

  dispatch(addAlert(newAlert));
};

export const deleteAlert = alert => async dispatch => {
  await API.del('alerts', `/?id=${alert.id}`);

  dispatch(removeAlert(alert));
};

export const updateAlert = alert => async dispatch => {
  const query = convertFiltersToQuery(alert.filters);

  const updatedAlert = {
    ...alert,
    filters: JSON.stringify(alert.filters),
    query: JSON.stringify(query)
  };

  await API.patch('alerts', '', {
    body: updatedAlert
  });
  dispatch(modifyAlert({ ...alert, query }));
};

export default segmentsSlice.reducer;
