import Form from "@rjsf/bootstrap-4";
import { customizeValidator } from "@rjsf/validator-ajv8";
import me from "./me.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMarketEstimations,
  fetchMarketEstimationsSchema,
  setMarketEstimationsSourcingCriteria
} from "../../../features/admin/marketEstimations/marketEstimationsSlice";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import FieldValueWidget from "./FieldValueWidget";
import SearchableSelectWidget from "./SearchableSelectWidget";

// to avoid accidental CSS removal on import cleanup
const mecss = me;

// this is required for all the components up to the nested level have correct css classes.
// otherwise they will fall to generics. This combined with css "rules nesting layout" allows for a correct display
// until the nested level. At this stage we display well down to 7 levels of nesting
const rulesUiSchema = (deep) => {
  if (deep === 0) return {};
  return {
    "ui:title": " ",
    "ui:classNames": "rules",

    "items": {
      "ui:classNames": "sourcing",
      "ui:order": [
        "combinator", "not", "rules","field", "operator", "value", "configId"
      ],
      "field": {
        "ui:widget": "SearchableSelectWidget"
      },
      "operator": {
        "ui:widget": "SearchableSelectWidget"
      },
      "value": {
        "ui:widget": "FieldValueWidget"
      },
      "configId": {},
      "combinator": {
        "ui:title": " ",
        "ui:classNames": "combinator"
      },
      "not": {
        "ui:title": "not",
        "ui:classNames": "not"
      },
      "rules": rulesUiSchema(deep - 1)
    }
  };
};

const uiSchema = {
  "sourcingCriteria": {
    "ui:classNames": "market-estimations sourcing",
    "combinator": {
      "ui:classNames": "child combinator"
    },
    "not": {
      "ui:classNames": "not"
    },
    "rules": rulesUiSchema(8)
  }
};


const widgets = {
  SearchableSelectWidget: SearchableSelectWidget,
  FieldValueWidget: FieldValueWidget
};

const ajvOptionsOverrides = {
  allErrors: false,
  removeAdditional: "failing"
};
const validator = customizeValidator({ ajvOptionsOverrides: ajvOptionsOverrides });

const MarketEstimationsForm = () => {
  const { marketEstimations } = useSelector(state => state);
  const dispatch = useDispatch();

  const { search } = useLocation(); // get the current URL search string
  const history = useHistory();

  const [marketEstimationsSchema, setMarketEstimationsSchema] = useState({});

  useEffect(() => {
    dispatch(fetchMarketEstimationsSchema());
  }, [dispatch]);


  useEffect(() => {
    const marketEstimationsSchema = Object.assign({}, marketEstimations?.schema?.jsonSchema);
    if (marketEstimationsSchema) {
      marketEstimationsSchema.properties = {
        sourcingCriteria: {
          "$ref": "#\/definitions\/CompanyCriteriaGroup"
        }
      };
      marketEstimationsSchema.required = [
        "sourcingCriteria"
      ];
      setMarketEstimationsSchema(marketEstimationsSchema);
    }
  }, [marketEstimations?.schema]);

  const onSubmit = ({ formData }, e) => {
    history.push(`?sourcingCriteria=${encodeURIComponent(JSON.stringify(formData))}`);
    dispatch(fetchMarketEstimations(formData));
  };

  useEffect(() => {
    // when the component mounts or the URL changes, update the state from the URL
    const parsed = queryString.parse(search);
    if (parsed.sourcingCriteria) {
      dispatch(setMarketEstimationsSourcingCriteria(JSON.parse(decodeURIComponent(parsed.sourcingCriteria))));
    }
  }, [search, dispatch]);

  return (
    <Form
      schema={marketEstimationsSchema}
      validator={validator}
      uiSchema={uiSchema}
      formData={marketEstimations.sourcingCriteria}
      onSubmit={onSubmit}
      idPrefix={"me_form"}
      widgets={widgets}
      disabled={marketEstimations.isFetching}
    />
  );
};


export default MarketEstimationsForm;