import { DataDirectoryStore } from './state';
import { IntegrationFieldMappingInput, Integration } from './types';
import { API } from 'aws-amplify';

export const getCreateFieldName = (store: DataDirectoryStore, mapping: IntegrationFieldMappingInput) => {
  const dataBlockField = [...store.dataDirectoryCompanyFields, ...store.dataDirectoryContactFields].find(
    field => field.datasetName === mapping.internalName
  );
  const isSystemField = dataBlockField?.isSystem;
  const isCCMField = dataBlockField?.presentInCCM;

  // Do not parse the field name if it's a system field or if it's not in CCM.
  if (!isSystemField && isCCMField) {
    const cleanedName = mapping.internalName
      .replaceAll('_', ' ')
      .replaceAll(/(^|[/.\s])(\w)/g, function (_, prefix, letter) {
        return prefix + letter.toUpperCase();
      });
    return `${cleanedName} (GoodFit)`;
  } else {
    return mapping.internalName;
  }
};

export const getFieldType = (
  recordType: string,
  internalFieldName: string,
  integration: Integration,
  state: DataDirectoryStore
) => {
  const dataDirectoryFields =
    recordType === 'company' ? state.dataDirectoryCompanyFields : state.dataDirectoryContactFields;
  const field = dataDirectoryFields.find(field => field.datasetName === internalFieldName);
  if (integration.integrationType === 'HUBSPOT') return field?.dataBlockField?.hubspotType;
  if (integration.integrationType === 'SALESFORCE') return field?.dataBlockField?.salesforceType;
};

export const getPicklistValues = async (internalFieldName: string, fieldType: string | undefined) => {
  if (fieldType === 'picklist' || fieldType === 'multipicklist') {
    const fieldStats = await API.post('data', '/field-stats', {
      body: {
        fieldName: internalFieldName,
        // This is hardcode because we don't have access to the field type in mapping
        fieldType: 'Picklist'
      }
    });

    return fieldStats?.terms.map((term: any) => term.term);
  }
};
