import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Col, Tag } from 'antd';

export default function EnumTag({ value, setValue, options }) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    setSelectedIndex(_.findIndex(options, ['name', value]));
  }, [value, options]);

  const setOptionOnClick = () => {
    const nextIndex = (selectedIndex + 1) % options.length;
    setValue(options[nextIndex].name);
  };

  return (
    <Col>
      <Tag
        style={{
          cursor: 'pointer',
          textAlign: 'center',
          height: 'min-content'
        }}
        onClick={() => setOptionOnClick()}
        color={options[selectedIndex]?.color}
        icon={options[selectedIndex]?.icon}
      >
        {_.upperFirst(options[selectedIndex]?.name)}
      </Tag>
    </Col>
  );
}
