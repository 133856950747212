import { Tag, Tooltip } from 'antd';
import React from 'react';
import _ from 'lodash';

export default function ArrayCell({ values }) {
  const maxItemsVisible = _.reduce(
    values,
    (result, value, index) => {
      if (_.join(_.map(values.slice(0, result), _.toString), '').length > 25 && result > 1) {
        return result - 1;
      } else {
        return result;
      }
    },
    values.length
  );
  return (
    <Tooltip
      color="white"
      title={values.map(value => (
        <Tag key={value} color="#c4b4fa">
          {value}
        </Tag>
      ))}
    >
      {values.slice(0, maxItemsVisible > 5 ? 5 : maxItemsVisible).map(value => (
        <Tag key={value} color="#c4b4fa">
          {value}
        </Tag>
      ))}
      {values.length > (maxItemsVisible > 5 ? 5 : maxItemsVisible) && '...'}
    </Tooltip>
  );
}
