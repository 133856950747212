import { Modal, message } from 'antd';
import { API } from 'aws-amplify';
import React from 'react';
import AceEditor from 'react-ace';
import { useSelector } from 'react-redux';
import _ from 'lodash';

export default function WebhookTestModal({ type, url, visible, onCancel }) {
  const entities = useSelector(state => state.companies.entities);

  const webhookPayloads = {
    account: {
      type: 'account',
      source: 'goodfit',
      body: _.omit(entities[0], ['join', 'type'])
    },
    contact: {
      type: 'contact',
      source: 'goodfit',
      body: {
        ...(_.find(entities, 'contacts')?.contacts?.[0] ?? {
          firstName: 'Test',
          lastName: 'Contact',
          email: 'test@test.com',
          title: 'CEO',
          linkedin: 'https://linkedin.com/test-contact'
        }),
        account: _.omit(_.find(entities, 'contacts'), ['join', 'type'])
      }
    },
    alerts: {
      type: 'alert',
      source: 'goodfit',
      body: {
        name: 'Test Alert',
        fieldName: 'employeeCount',
        oldValue: 100,
        newValue: 150,
        changeType: 'increased',
        change: 0.5,
        domain: 'test.com'
      },
      record: _.omit(entities[0], ['join', 'type'])
    }
  };

  // This endpoint doesn't work anymore so commenting it out
  // const onSendTestWebhook = async () => {
  //   try {
  //     message.success("Test payload successfully sent!");
  //     await API.post("connectors", "/test-webhook", {
  //       body: {
  //         url,
  //         payload: webhookPayloads[type],
  //       },
  //     });
  //   } catch (err) {
  //     message.error("Something went wrong. Check whether your url is correct.");
  //   }
  // };


  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title="Test Webhook"
      onOk={onCancel}
    >
      <AceEditor value={JSON.stringify(webhookPayloads[type], null, '\t')} width="auto" theme="kuroir" />
    </Modal>
  );
}
