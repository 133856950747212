import {
  BookOutlined,
  CommentOutlined,
  ControlOutlined,
  HomeOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { Button, Dropdown, Flex, Menu, Tag } from 'antd';
import { Link } from 'react-router-dom';
import GenericNavBar from './GenericNavBar';
import { FeatureFlag, hasFeatureFlag } from '../../featureFlags';
import React from 'react';

export default function AppNavBar({ title }) {
  const shouldShowAlerts = hasFeatureFlag(FeatureFlag.showAlerts);
  const talkTrackAlertsTabText = shouldShowAlerts ? 'Talk Tracks & Alerts' : 'Talk Tracks';
  const useV2CrmUI = hasFeatureFlag(FeatureFlag.v2CrmSync);

  const helpOnClick = ({ key }) => {
    switch (key) {
      case '1':
        window.open('https://docs.goodfit.io/', '_blank');
        break;
    }
  };

  const helpItems = [
    {
      key: '1',
      icon: <BookOutlined />,
      label: 'Documentation'
    }
  ];

  return (
    <GenericNavBar
      title={title}
      settings={
        <Flex gap="small" justify="vertical">
          {/* This is code for dropdown menu if we decide to create help section with more items */}
          {/*<Dropdown*/}
          {/*  type="text"*/}
          {/*  placement="bottomRight"*/}
          {/*  onClick={e => e.preventDefault()}*/}
          {/*  menu={{ items: helpItems, onClick: helpOnClick }}*/}
          {/*>*/}
          {/*  <Button*/}
          {/*    shape="circle"*/}
          {/*    style={{ paddingTop: 5, background: 'none', outline: 'none', border: 'none', cursor: 'default' }}*/}
          {/*    icon={<QuestionCircleOutlined />}*/}
          {/*    onClick={e => e.preventDefault()}*/}
          {/*  ></Button>*/}
          {/*</Dropdown>*/}
          <Button
            shape="round"
            type="text"
            style={{ paddingTop: 5 }}
            icon={<BookOutlined />}
            href="https://docs.goodfit.io/"
            target="_blank"
          >Docs</Button>
          <Link to="/settings/">
            <Button shape="circle" title="Settings" style={{ paddingTop: 5 }} icon={<SettingOutlined />} />
          </Link>
        </Flex>
      }
    >
      <Menu
        mode="horizontal"
        selectedKeys={[window.location.pathname]}
        style={{ background: 'none' }}
        disabledOverflow={true}
      >
        <Menu.Item key="/companies" icon={<HomeOutlined />} style={{ textAlign: 'center' }}>
          <Link to="/companies">Companies</Link>
        </Menu.Item>
        <Menu.Item key="/talk-tracks" icon={<CommentOutlined />} style={{ textAlign: 'center' }}>
          <Link to="/talk-tracks">{talkTrackAlertsTabText}</Link>
        </Menu.Item>
        <Menu.Item key="/scoring" icon={<ControlOutlined />} style={{ textAlign: 'center' }}>
          <Link to="/scoring">Scoring</Link>{' '}
        </Menu.Item>
        {!useV2CrmUI && (
        <Menu.Item key="/data-directory" icon={<ProfileOutlined />} style={{ textAlign: 'center' }}>
          <Link to="/data-directory">Data Directory</Link>{' '}
        </Menu.Item>
        )}
        {useV2CrmUI && (
        <Menu.Item key="/data-directory2" icon={<ProfileOutlined />} style={{ textAlign: 'center' }}>
          <Link to="/data-directory2">Data Directory</Link>{' '}
        </Menu.Item>
        )}
      </Menu>
    </GenericNavBar>
  );
}
